import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RingLoader } from 'react-spinners';
import Category12 from "../../../assets/images/Category3.png";
import { useNavigate } from 'react-router-dom';
import LotLoader from '../../pages/Category/Common/LotLoader';
import './AuctionCategories.css'

const CategoryList = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const itemsPerPage = 6;

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/categories`,
                    {
                        headers: {
                            'ngrok-skip-browser-warning': '69420',
                        }
                    }
                );
                const processCategories = (cats) => {
                    return cats.map(cat => {
                        const media = cat.media.length > 0
                            ? `${process.env.REACT_APP_BASE_IMAGE_URL}/storage/${cat.media[0].file_path}`
                            : Category12;
                        return {
                            ...cat,
                            image: media,
                            descendants: processCategories(cat.descendants)
                        };
                    });
                };
                const processedCategories = processCategories(response.data.data);
                setCategories(processedCategories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCategories();
    }, []);

    const navigate = useNavigate();
    const handleViewAll = () => {
        navigate("/auction-categories");
    };
    const displayedCategories = categories.slice(0, itemsPerPage);
    return (
        <div className='category pt-4'>
            <div className='container'>
                {loading ? (
                    <LotLoader />
                ) : ( 
                    <div className='d-flex justify-content-center  '>
                        <div className='row flex-nowrap flex items-start justify-start overflow-x-scroll w-full category-scroll' >
                            {displayedCategories.map((category, index) => (
                                <div className='col-md-3 col-6 mb-2 auctions-card-images' style={{ width: "22%" }} key={index}>
                                    <div className="card category-card">
                                        <div className="card-body category-cards">
                                            <div className='category-images w-full flex items-center justify-center'>
                                                <img
                                                    src={category.image || Category12}
                                                    alt={category.name}
                                                    className="img"
                                                    width={80}
                                                    height={80}
                                                    className="max-w-[80px] max-h-[80px] min-w-[79px] min-h-[79px] "
                                                />
                                            </div>
                                            <h6 className='text-center category-text-heading mt-3 w-full'>{category.name}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default CategoryList;
