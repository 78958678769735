import React from 'react';
import { Modal, Button, } from 'react-bootstrap';
import StateTable from './StateTable';

const StatesModal = ({ showModal, handleClose, states, handleToggleState, loading, stateSearch, setStateSearch, selectedCountry }) => (
    <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>States of {selectedCountry?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='overflow-y-scroll'>
            <StateTable
                states={states}
                onToggleState={handleToggleState}
                loading={loading}
                stateSearch={stateSearch}
                setStateSearch={setStateSearch}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
);

export default StatesModal;
