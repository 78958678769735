// import { useState, useEffect } from "react";
// import axios from "axios";
// import "./AuctionSignup.css";
// import {
//   Card,
//   MenuItem,
//   Select,
//   InputLabel,
//   FormControl,
//   TextField,
//   Button,
//   Checkbox,
//   FormControlLabel,
//   Typography,
//   Box,
// } from "@mui/material";

// const faqData = [
//   {
//     question: 'For Sole Proprietors',
//     answer: `  
//             <ul>
//                 <li class="faq-list-item"> Copy of a driver’s license or valid photo ID</li>
//                 <li class="faq-list-item"> Copies of any business registrations or licensing required by your state, city or county</li>
//             </ul>`,
//     type: "auctioner"
//   },
//   {
//     question: 'For All Other Business Structures',
//     answer: `  
//             <ul>
//                 <li class="faq-list-item">A copy of your business registration (e.g. - Articles of Incorporation, Certificate of Organization, etc.)</li>
//                 <li class="faq-list-item">If applicable, a copy of any professional licensing documents as required to do business in your state, city or county
//    </ul>`,
//     type: "answer"
//   },
//   {
//     question: 'Companies Planning to Operate Under a DBA (Doing Business As) or Fictitious Business Name on Proxibid',
//     answer: `  
//             <ul>
//                 <li class="faq-list-item">A copy of your Doing Business As (DBA) or Fictitious Business Name filing as required by your state, city or county</li>            
//    </ul>`,
//     type: "documents"
//   },
//   {
//     question: 'Auction Companies and Auctioneers',
//     answer: `  
//             <ul>
//                 <li class="faq-list-item">A copy of Auctioneers license or certificate of registration with an Auctioneers Association</li>          
//                  <li class="faq-list-item">If you are planning on selling Firearms, a copy of your Federal Firearms License (“FFL”), or an attestation stating you are
// exempt because you exclusively sell antique firearms as defined by 18 U.S. Code § 921(16)</li>       
//    </ul>`,
//     type: "lastquestion"
//   },

// ];

// function Cover() {
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [states, setStates] = useState([]);
//   const [statesProvienceList, setStatesProvienceList] = useState([]);
//   const [countries, setCountries] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [business, setBusiness] = useState([]);
//   const [faq, setFaq] = useState("");
//   const [faqFile, setFaqFile] = useState(null);
//   const [errors, setErrors] = useState({});
//   const [submitting, setSubmitting] = useState(false);
//   const [formValues, setFormValues] = useState({
//     company: '',
//     under_dba: false,
//     parent_company_name: '',
//     phone_number: '',
//     customer_service_phone_number: '',
//     email_address: '',
//     fax_number: '',
//     street: '',
//     company_city: '',
//     state_province: '',
//     country: '',
//     business_structure_id: "",
//     zip_postal_code: '',
//     website_address: '',
//     other_marketplaces: '',
//     representative_first_name: '',
//     representative_middle_initial: '',
//     representative_last_name: '',
//     representative_business_title: '',
//     representative_preferred_phone_number: '',
//     representative_alternate_phone_number: '',
//     representative_preferred_email_address: '',
//     first_name: '',
//     last_name: '',
//     country_id: '',
//     city: '',
//     state_id: '',
//     postal_code: '',
//     phone1: '',
//     phone2: '',
//     fax: '',
//     username: '',
//     password: '',
//     password_confirmation: '',
//     email: '',
//     confirmEmail: '',
//     hide_username: false,
//     allow_magnifying_glass: false,
//     send_email_notifications: false,
//     address: '',
//     newsletter: false,
//     role: '',
//   });

//   const handleToggle = (index) => {
//     setActiveIndex(activeIndex === index ? null : index);
//     setFaq(activeIndex === index ? "" : faqData[index]?.type)
//     setFaqFile(null)
//   };
//   const fetchRoles = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/roles`, {
//         headers: {
//           "ngrok-skip-browser-warning": "69420"
//         }
//       });
//       const rolesData = response.data.roles;
//       setRoles(rolesData || []);
//     } catch (error) {
//       console.error('Error fetching roles:', error);
//     }
//   };

//   const fetchCountries = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`, {
//         headers: {
//           "ngrok-skip-browser-warning": "69420"
//         }
//       });
//       const { data } = response.data;
//       setCountries(data);
//     } catch (error) {
//       console.error('Error fetching countries:', error);
//     }
//   };

//   const fetchStates = async (countryId, type) => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/states/${countryId}`, {
//         headers: {
//           "ngrok-skip-browser-warning": "69420"
//         }
//       });
//       const { data } = response.data;
//       if (type === "stateprovience") {
//         setStatesProvienceList(data)
//       }
//       if (type === "states") {
//         setStates(data);
//       }
//     } catch (error) {
//       console.error('Error fetching states:', error);
//     }
//   };

//   const handleFileChange = (e) => {
//     const blobURL = new Blob(e.target.files);
//     setFaqFile(blobURL);
//   };

//   const businessStructure = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/business-structures`, {
//         headers: {
//           "ngrok-skip-browser-warning": "69420"
//         }
//       });
//       const { data } = response.data;
//       setBusiness(data);
//     } catch (error) {
//       console.error('Error fetching countries:', error);
//     }
//   };
//   useEffect(() => {
//     fetchRoles();
//     fetchCountries();
//     businessStructure();
//   }, []);


//   useEffect(() => {
//     if (formValues.country) {
//       fetchStates(formValues.country, "stateprovience");
//     } else {
//       setStates([]);
//     }
//   }, [formValues.country]);

//   useEffect(() => {
//     if (formValues.country_id) {
//       fetchStates(formValues.country_id, "states");
//     } else {
//       setStates([]);
//     }
//   }, [formValues.country_id]);

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;

//     setFormValues(prevValues => ({
//       ...prevValues,
//       [name]: type === 'checkbox' ? checked : value
//     }));
//   };

//   const validate = () => {
//     const newErrors = {};
//     if (formValues.role === '2' && !formValues.company) {
//       newErrors.company = 'Company Name is required';
//     }
//     if (formValues.role === '2' && !formValues.under_dba) {
//       newErrors.under_dba = 'under_dba  is required';
//     }
//     // if (formValues.role === '2' && !formValues.parent_company_name) {
//     //   newErrors.parent_company_name = 'parent_company_name  is required';
//     // }
//     if (formValues.role === '2' && !formValues.phone_number) {
//       newErrors.phone_number = 'phone_number  is required';
//     }
//     if (formValues.role === '2' && !formValues.under_dba) {
//       newErrors.customer_service_phone_number = 'customer_service_phone_number  is required';
//     }
//     if (formValues.role === '2' && !formValues.email_address) {
//       newErrors.email_address = 'email_address  is required';
//     }
//     if (formValues.role === '2' && !formValues.fax_number) {
//       newErrors.fax_number = 'fax_number  is required';
//     }
//     if (formValues.role === '2' && !formValues.street) {
//       newErrors.street = 'street  is required';
//     }
//     if (formValues.role === '2' && !formValues.company_city) {
//       newErrors.company_city = 'company_city  is required';
//     }
//     if (formValues.role === '2' && !formValues.state_province) {
//       newErrors.state_province = 'state_province  is required';
//     }
//     if (formValues.role === '2' && !formValues.country) {
//       newErrors.country = 'country  is required';
//     }
//     if (formValues.role === '2' && !formValues.zip_postal_code) {
//       newErrors.zip_postal_code = 'zip_postal_code  is required';
//     }
//     if (formValues.role === '2' && !formValues.website_address) {
//       newErrors.website_address = 'website_address  is required';
//     }
//     if (formValues.role === '2' && !formValues.website_address) {
//       newErrors.website_address = 'website_address  is required';
//     }
//     if (formValues.role === '2' && !formValues.other_marketplaces) {
//       newErrors.other_marketplaces = 'other_marketplaces  is required';
//     } if (formValues.role === '2' && !formValues.representative_first_name) {
//       newErrors.representative_first_name = 'representative_first_name  is required';
//     } if (formValues.role === '2' && !formValues.representative_middle_initial) {
//       newErrors.representative_middle_initial = 'representative_middle_initial  is required';
//     } if (formValues.role === '2' && !formValues.representative_last_name) {
//       newErrors.representative_last_name = 'representative_last_name  is required';
//     } if (formValues.role === '2' && !formValues.representative_business_title) {
//       newErrors.representative_business_title = 'representative_business_title  is required';
//     }
//     if (formValues.role === '2' && !formValues.representative_preferred_phone_number) {
//       newErrors.representative_preferred_phone_number = 'representative_preferred_phone_number  is required';
//     }
//     if (formValues.role === '2' && !formValues.representative_alternate_phone_number) {
//       newErrors.representative_alternate_phone_number = 'representative_alternate_phone_number  is required';
//     }
//     if (formValues.role === '2' && !formValues.representative_business_title) {
//       newErrors.representative_business_title = 'representative_business_title  is required';
//     }
//     if (formValues.role === '2' && !formValues.representative_preferred_email_address) {
//       newErrors.representative_preferred_email_address = 'representative_preferred_email_address  is required';
//     }

//     if (formValues.role === '2' && !formValues.representative_preferred_phone_number) {
//       newErrors.representative_preferred_phone_number = 'representative_preferred_phone_number  is required';
//     }


//     if (!formValues.first_name) newErrors.first_name = 'First Name is required';
//     if (!formValues.last_name) newErrors.last_name = 'Last Name is required';
//     if (!formValues.country_id) newErrors.country_id = 'Country is required';
//     if (!formValues.state_id) newErrors.state_id = 'State is required';
//     if (!formValues.city) newErrors.city = 'City is required';
//     if (!formValues.postal_code) newErrors.postal_code = 'Postal Code is required';
//     if (!formValues.phone1) newErrors.phone1 = 'Phone 1 is required';
//     if (!formValues.username) newErrors.username = 'Username is required';
//     if (!formValues.password) newErrors.password = 'Password is required';
//     if (formValues.password !== formValues.password_confirmation) newErrors.password_confirmation = 'Passwords must match';
//     if (!formValues.email) newErrors.email = 'Email is required';
//     if (formValues.email !== formValues.confirmEmail) newErrors.confirmEmail = 'Emails must match';
//     if (!formValues.address) newErrors.address = 'Address is required';
//     return newErrors;
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationErrors = validate();
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return;
//     }
//     setSubmitting(true);
//     try {

//       const formData = new FormData();
//       Object.keys(formValues).forEach(key => {
//         formData.append(key, formValues[key]);
//       });

//       formData.append(faq, faqFile);




//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, formData, {
//         headers: {
//           "ngrok-skip-browser-warning": "69420",
//           'Content-Type': 'multipart/form-data',

//         }
//       });
//       console.log('Registration successful:', response.data);
//     } catch (error) {
//       console.error('API Error:', error);
//     } finally {
//       setSubmitting(false);
//     }
//   };


//   return (
//     <Card sx={{ maxWidth: 600, mx: "auto", mt: 5, mb: 5, p: 3 }}>
//       <Box sx={{ mb: 3, textAlign: "center", backgroundColor: "#3f51b5", color: "white", borderRadius: 1 }}>
//         <Typography variant="h4" gutterBottom>
//           Register
//         </Typography>
//       </Box>

//       <Box sx={{ mb: 2 }}>
//         <FormControl fullWidth variant="outlined">
//           <InputLabel id="role-label">Role</InputLabel>
//           <Select
//             labelId="role-label"
//             id="role"
//             name="role"
//             value={formValues.role}
//             onChange={handleChange}
//             label="Role"
//             error={Boolean(errors.role)}
//           >
//             {roles && roles.length > 0 ? (
//               roles.map(role => (
//                 <MenuItem key={role.id} value={role.id}>
//                   {role.name}
//                 </MenuItem>
//               ))
//             ) : (
//               <MenuItem disabled>No roles available</MenuItem>
//             )}
//           </Select>
//           {errors.role && (
//             <Typography variant="caption" color="error">
//               {errors.role}
//             </Typography>
//           )}
//         </FormControl>
//       </Box>
//       <Box component="form" onSubmit={handleSubmit}>
//         <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }} style={{ display: "none" }}>
//           Current role: {formValues.role}
//         </Typography>

//         {formValues.role === 2 && (
//           <>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="Company Name"
//                 variant="outlined"
//                 name="company"
//                 value={formValues.company}
//                 onChange={handleChange}
//                 error={Boolean(errors.company)}
//                 helperText={errors.company}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <FormControl fullWidth variant="outlined">
//                 <InputLabel id="business-label">Business Structure</InputLabel>
//                 <Select
//                   labelId="business-label"
//                   id="business_structure_id"
//                   name="business_structure_id"
//                   value={formValues.business_structure_id}
//                   onChange={handleChange}
//                   label="Business Structure"
//                   error={Boolean(errors.business_structure_id)}
//                 >
//                   {business.length > 0 ? (
//                     business.map(business => (
//                       <MenuItem key={business.id} value={business.id}>
//                         {business.name}
//                       </MenuItem>
//                     ))
//                   ) : (
//                     <MenuItem disabled>No business structures available</MenuItem>
//                   )}
//                 </Select>
//                 {errors.business_structure_id && (
//                   <Typography variant="caption" color="error">
//                     {errors.business_structure_id}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <FormControlLabel
//                 control={<Checkbox name="under_dba" checked={formValues.under_dba} onChange={handleChange} />}
//                 label="under_dba"
//               />
//             </Box>
//             {formValues.under_dba && (
//               <Box sx={{ mb: 2 }}>
//                 <TextField
//                   fullWidth
//                   label="Parent Company Name"
//                   variant="outlined"
//                   name="parent_company_name"
//                   value={formValues.parent_company_name}
//                   onChange={handleChange}
//                   error={Boolean(errors.parent_company_name)}
//                   helperText={errors.parent_company_name}
//                 />
//               </Box>
//             )}
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="phone_number"
//                 variant="outlined"
//                 name="phone_number"
//                 value={formValues.phone_number}
//                 onChange={handleChange}
//                 error={Boolean(errors.phone_number)}
//                 helperText={errors.phone_number}
//               />
//             </Box>

//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="customer_service_phone_number"
//                 variant="outlined"
//                 name="customer_service_phone_number"
//                 value={formValues.customer_service_phone_number}
//                 onChange={handleChange}
//                 error={Boolean(errors.customer_service_phone_number)}
//                 helperText={errors.customer_service_phone_number}
//               />
//             </Box>

//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="email_address"
//                 variant="outlined"
//                 name="email_address"
//                 value={formValues.email_address}
//                 onChange={handleChange}
//                 error={Boolean(errors.email_address)}
//                 helperText={errors.email_address}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="fax_number"
//                 variant="outlined"
//                 name="fax_number"
//                 value={formValues.fax_number}
//                 onChange={handleChange}
//                 error={Boolean(errors.fax_number)}
//                 helperText={errors.fax_number}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="street"
//                 variant="outlined"
//                 name="street"
//                 value={formValues.street}
//                 onChange={handleChange}
//                 error={Boolean(errors.street)}
//                 helperText={errors.street}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="company_city "
//                 variant="outlined"
//                 name="company_city"
//                 value={formValues.company_city}
//                 onChange={handleChange}
//                 error={Boolean(errors.company_city)}
//                 helperText={errors.company_city}
//               />
//             </Box>

//             <Box sx={{ mb: 2 }}>
//               <FormControl fullWidth variant="outlined">
//                 <InputLabel id="country-label">Country</InputLabel>
//                 <Select
//                   labelId="country-label"
//                   id="country"
//                   name="country"
//                   value={formValues.country}
//                   onChange={handleChange}
//                   label="Country"
//                   error={Boolean(errors.country)}
//                 >
//                   <MenuItem value="">--Select a country--</MenuItem>
//                   {countries.map((country) => (
//                     <MenuItem key={country.hash_id} value={country.hash_id}>
//                       {country.name}
//                     </MenuItem>
//                   ))}
//                 </Select>
//                 {errors.country && (
//                   <Typography variant="caption" color="error">
//                     {errors.country}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Box>

//             <Box sx={{ mb: 2 }}>
//               <FormControl fullWidth variant="outlined">
//                 <InputLabel id="state-label">State</InputLabel>
//                 <Select
//                   labelId="state-label"
//                   id="state_province"
//                   name="state_province"
//                   value={formValues.state_province}
//                   onChange={handleChange}
//                   label="State"
//                   error={Boolean(errors.state_province)}
//                 >
//                   <MenuItem value="">--Select a state--</MenuItem>
//                   {statesProvienceList.length > 0 ? (
//                     statesProvienceList.map(state => (
//                       <MenuItem key={state.hash_id} value={state.hash_id}>
//                         {state.name}
//                       </MenuItem>
//                     ))
//                   ) : (
//                     <MenuItem disabled>No states available</MenuItem>
//                   )}
//                 </Select>
//                 {errors.state_province && (
//                   <Typography variant="caption" color="error">
//                     {errors.state_province}
//                   </Typography>
//                 )}
//               </FormControl>
//             </Box>

//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="zip_postal_code"
//                 variant="outlined"
//                 name="zip_postal_code"
//                 value={formValues.zip_postal_code}
//                 onChange={handleChange}
//                 error={Boolean(errors.zip_postal_code)}
//                 helperText={errors.zip_postal_code}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="website_address"
//                 variant="outlined"
//                 name="website_address"
//                 value={formValues.website_address}
//                 onChange={handleChange}
//                 error={Boolean(errors.website_address)}
//                 helperText={errors.website_address}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="other_marketplaces"
//                 variant="outlined"
//                 name="other_marketplaces"
//                 value={formValues.other_marketplaces}
//                 onChange={handleChange}
//                 error={Boolean(errors.other_marketplaces)}
//                 helperText={errors.other_marketplaces}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="representative_first_name"
//                 variant="outlined"
//                 name="representative_first_name"
//                 value={formValues.representative_first_name}
//                 onChange={handleChange}
//                 error={Boolean(errors.representative_first_name)}
//                 helperText={errors.representative_first_name}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="representative_middle_initial"
//                 variant="outlined"
//                 name="representative_middle_initial"
//                 value={formValues.representative_middle_initial}
//                 onChange={handleChange}
//                 error={Boolean(errors.representative_middle_initial)}
//                 helperText={errors.representative_middle_initial}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="representative_last_name "
//                 variant="outlined"
//                 name="representative_last_name"
//                 value={formValues.representative_last_name}
//                 onChange={handleChange}
//                 error={Boolean(errors.representative_last_name)}
//                 helperText={errors.representative_last_name}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="representative_business_title"
//                 variant="outlined"
//                 name="representative_business_title"
//                 value={formValues.representative_business_title}
//                 onChange={handleChange}
//                 error={Boolean(errors.representative_business_title)}
//                 helperText={errors.representative_business_title}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="representative_preferred_phone_number"
//                 variant="outlined"
//                 name="representative_preferred_phone_number"
//                 value={formValues.representative_preferred_phone_number}
//                 onChange={handleChange}
//                 error={Boolean(errors.representative_preferred_phone_number)}
//                 helperText={errors.representative_preferred_phone_number}
//               />
//             </Box>

//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="representative_alternate_phone_number"
//                 variant="outlined"
//                 name="representative_alternate_phone_number"
//                 value={formValues.representative_alternate_phone_number}
//                 onChange={handleChange}
//                 error={Boolean(errors.representative_alternate_phone_number)}
//                 helperText={errors.representative_alternate_phone_number}
//               />
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               <TextField
//                 fullWidth
//                 label="representative_preferred_email_address"
//                 variant="outlined"
//                 name="representative_preferred_email_address"
//                 value={formValues.representative_preferred_email_address}
//                 onChange={handleChange}
//                 error={Boolean(errors.representative_preferred_email_address)}
//                 helperText={errors.representative_preferred_email_address}
//               />
//             </Box>

//             <Box sx={{ mb: 2 }}>
//               <p className="text-start">
//                 <b>Please refer to the section below to understand what documents you need to submit with your application</b>
//               </p>
//             </Box>
//             <Box sx={{ mb: 2 }}>
//               {faqData.map((faq, index) => (
//                 <div key={index} style={{ border: '1px solid #ddd', marginBottom: '10px', borderRadius: '5px', overflow: 'hidden', }}>
//                   <div
//                     style={{
//                       padding: '15px',
//                       cursor: 'pointer',
//                       fontWeight: 'bold',
//                       display: 'flex',
//                       justifyContent: 'space-between',
//                     }}
//                     onClick={() => handleToggle(index)}
//                     className='m-0 '
//                   >
//                     {faq.question}
//                     <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
//                       {activeIndex === index ? '-' : '+'}
//                     </span>
//                   </div>
//                   {activeIndex === index && (
//                     <>
//                       <div
//                         style={{ padding: '15px', backgroundColor: '#fff' }}
//                         dangerouslySetInnerHTML={{ __html: faq.answer }}
//                       />
//                       <div className="mx-4 mb-3">
//                         <input
//                           type="file"
//                           label="faqFile"
//                           name="faqFile"
//                           onChange={handleFileChange}
//                         />
//                       </div>
//                     </>
//                   )}
//                 </div>
//               ))}
//             </Box>
//           </>
//         )}

//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             label="First Name"
//             variant="outlined"
//             name="first_name"
//             value={formValues.first_name}
//             onChange={handleChange}
//             error={Boolean(errors.first_name)}
//             helperText={errors.first_name}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             label="Last Name"
//             variant="outlined"
//             name="last_name"
//             value={formValues.last_name}
//             onChange={handleChange}
//             error={Boolean(errors.last_name)}
//             helperText={errors.last_name}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             label="Address"
//             variant="outlined"
//             name="address"
//             value={formValues.address}
//             onChange={handleChange}
//             error={Boolean(errors.address)}
//             helperText={errors.address}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <FormControl fullWidth variant="outlined">
//             <InputLabel id="country-label">Country</InputLabel>
//             <Select
//               labelId="country-label"
//               id="country"
//               name="country_id"
//               value={formValues.country_id}
//               onChange={handleChange}
//               label="Country"
//               error={Boolean(errors.country_id)}
//             >
//               {countries.map(country => (
//                 <MenuItem key={country.hash_id} value={country.hash_id}>
//                   {country.name}
//                 </MenuItem>
//               ))}
//             </Select>
//             {errors.country_id && (
//               <Typography variant="caption" color="error">
//                 {errors.country_id}
//               </Typography>
//             )}
//           </FormControl>
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <FormControl fullWidth variant="outlined">
//             <InputLabel id="state-label">State</InputLabel>
//             <Select
//               labelId="state-label"
//               id="state"
//               name="state_id"
//               value={formValues.state_id}
//               onChange={handleChange}
//               label="State"
//               error={Boolean(errors.state_id)}
//             >
//               {states.map(state => (
//                 <MenuItem key={state.hash_id} value={state.hash_id}>
//                   {state.name}
//                 </MenuItem>
//               ))}
//             </Select>
//             {errors.state_id && (
//               <Typography variant="caption" color="error">
//                 {errors.state_id}
//               </Typography>
//             )}
//           </FormControl>
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             label="City"
//             variant="outlined"
//             name="city"
//             value={formValues.city}
//             onChange={handleChange}
//             error={Boolean(errors.city)}
//             helperText={errors.city}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             label="Postal Code"
//             variant="outlined"
//             name="postal_code"
//             value={formValues.postal_code}
//             onChange={handleChange}
//             error={Boolean(errors.postal_code)}
//             helperText={errors.postal_code}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             label="Phone 1"
//             variant="outlined"
//             name="phone1"
//             value={formValues.phone1}
//             onChange={handleChange}
//             error={Boolean(errors.phone1)}
//             helperText={errors.phone1}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             label="Phone 2"
//             variant="outlined"
//             name="phone2"
//             value={formValues.phone2}
//             onChange={handleChange}
//             error={Boolean(errors.phone2)}
//             helperText={errors.phone2}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             label="Fax"
//             variant="outlined"
//             name="fax"
//             value={formValues.fax}
//             onChange={handleChange}
//             error={Boolean(errors.fax)}
//             helperText={errors.fax}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             label="Username"
//             variant="outlined"
//             name="username"
//             value={formValues.username}
//             onChange={handleChange}
//             error={Boolean(errors.username)}
//             helperText={errors.username}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             type="password"
//             label="Password"
//             variant="outlined"
//             name="password"
//             value={formValues.password}
//             onChange={handleChange}
//             error={Boolean(errors.password)}
//             helperText={errors.password}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             type="password"
//             label="Confirm Password"
//             variant="outlined"
//             name="password_confirmation"
//             value={formValues.password_confirmation}
//             onChange={handleChange}
//             error={Boolean(errors.password_confirmation)}
//             helperText={errors.password_confirmation}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             type="email"
//             label="Email"
//             variant="outlined"
//             name="email"
//             value={formValues.email}
//             onChange={handleChange}
//             error={Boolean(errors.email)}
//             helperText={errors.email}
//           />
//         </Box>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             fullWidth
//             type="email"
//             label="Confirm Email"
//             variant="outlined"
//             name="confirmEmail"
//             value={formValues.confirmEmail}
//             onChange={handleChange}
//             error={Boolean(errors.confirmEmail)}
//             helperText={errors.confirmEmail}
//           />
//         </Box>
//         <Box>
//           <FormControlLabel
//             control={<Checkbox name="hide_username" checked={formValues.hide_username} onChange={handleChange} />}
//             label="Hide Username"
//           />
//         </Box>
//         <Box >
//           <FormControlLabel
//             control={<Checkbox name="allow_magnifying_glass" checked={formValues.allow_magnifying_glass} onChange={handleChange} />}
//             label="Allow Magnifying Glass"
//           />
//         </Box>
//         <Box >
//           <FormControlLabel
//             control={<Checkbox name="send_email_notifications" checked={formValues.send_email_notifications} onChange={handleChange} />}
//             label="Send Notifications"
//           />
//         </Box>
//         <Box>
//           <FormControlLabel
//             control={<Checkbox name="newsletter" checked={formValues.newsletter} onChange={handleChange} />}
//             label="Subscribe to Newsletter"
//           />
//         </Box>

//         <Box sx={{ mt: 4, }}>
//           <Button
//             variant="contained"
//             color="primary"
//             fullWidth
//             type="submit"
//             disabled={submitting}
//           >
//             Sign Up
//           </Button>
//         </Box>
//         <Box sx={{ mt: 3, mb: 1, textAlign: "center" }}>
//           <Typography variant="body2" color="textSecondary">
//             Already have an account?
//             <Typography
//               component="a"
//               href="/authentication/sign-in"
//               variant="body2"
//               color="primary"
//             >
//               Sign In
//             </Typography>
//           </Typography>
//         </Box>
//       </Box>
//     </Card>
//   );
// }

// export default Cover;
import { useState, useEffect } from "react";
import axios from "axios";
import "./AuctionSignup.css";
import {
  Card,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const faqData = [
  {
    question: 'For Sole Proprietors',
    answer: `  
            <ul>
                <li class="faq-list-item"> Copy of a driver’s license or valid photo ID</li>
                <li class="faq-list-item"> Copies of any business registrations or licensing required by your state, city or county</li>
            </ul>`,
    type: "auctioner"
  },
  {
    question: 'For All Other Business Structures',
    answer: `  
            <ul>
                <li class="faq-list-item">A copy of your business registration (e.g. - Articles of Incorporation, Certificate of Organization, etc.)</li>
                <li class="faq-list-item">If applicable, a copy of any professional licensing documents as required to do business in your state, city or county
   </ul>`,
    type: "answer"
  },
  {
    question: 'Companies Planning to Operate Under a DBA (Doing Business As) or Fictitious Business Name on Proxibid',
    answer: `  
            <ul>
                <li class="faq-list-item">A copy of your Doing Business As (DBA) or Fictitious Business Name filing as required by your state, city or county</li>            
   </ul>`,
    type: "documents"
  },
  {
    question: 'Auction Companies and Auctioneers',
    answer: `  
            <ul>
                <li class="faq-list-item">A copy of Auctioneers license or certificate of registration with an Auctioneers Association</li>          
                 <li class="faq-list-item">If you are planning on selling Firearms, a copy of your Federal Firearms License (“FFL”), or an attestation stating you are
exempt because you exclusively sell antique firearms as defined by 18 U.S. Code § 921(16)</li>       
   </ul>`,
    type: "lastquestion"
  },

];

function Cover() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [states, setStates] = useState([]);
  const [statesProvienceList, setStatesProvienceList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);
  const [business, setBusiness] = useState([]);
  const [faq, setFaq] = useState("");
  const [faqFile, setFaqFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    company: '',
    under_dba: false,
    parent_company_name: '',
    phone_number: '',
    customer_service_phone_number: '',
    email_address: '',
    fax_number: '',
    street: '',
    company_city: '',
    state_province: '',
    country: '',
    business_structure_id: "",
    zip_postal_code: '',
    website_address: '',
    other_marketplaces: '',
    representative_first_name: '',
    representative_middle_initial: '',
    representative_last_name: '',
    representative_business_title: '',
    representative_preferred_phone_number: '',
    representative_alternate_phone_number: '',
    representative_preferred_email_address: '',
    first_name: '',
    last_name: '',
    country_id: '',
    city: '',
    state_id: '',
    postal_code: '',
    phone1: '',
    phone2: '',
    fax: '',
    username: '',
    password: '',
    password_confirmation: '',
    email: '',
    confirmEmail: '',
    hide_username: false,
    allow_magnifying_glass: false,
    send_email_notifications: false,
    address: '',
    newsletter: false,
    role: '',
  });
  const navigate = useNavigate();
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    setFaq(activeIndex === index ? "" : faqData[index]?.type)
    setFaqFile(null)
  };
  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/roles`, {
        headers: {
          "ngrok-skip-browser-warning": "69420"
        }
      });
      const rolesData = response.data.roles;
      setRoles(rolesData || []);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`, {
        headers: {
          "ngrok-skip-browser-warning": "69420"
        }
      });
      const { data } = response.data;
      setCountries(data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const fetchStates = async (countryId, type) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/states/${countryId}`, {
        headers: {
          "ngrok-skip-browser-warning": "69420"
        }
      });
      const { data } = response.data;
      if (type === "stateprovience") {
        setStatesProvienceList(data)
      }
      if (type === "states") {
        setStates(data);
      }
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  const handleFileChange = (e) => {
    const blobURL = new Blob(e.target.files);
    setFaqFile(blobURL);
  };

  const businessStructure = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/business-structures`, {
        headers: {
          "ngrok-skip-browser-warning": "69420"
        }
      });
      const { data } = response.data;
      setBusiness(data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
  useEffect(() => {
    fetchRoles();
    fetchCountries();
    businessStructure();
  }, []);


  useEffect(() => {
    if (formValues.country) {
      fetchStates(formValues.country, "stateprovience");
    } else {
      setStates([]);
    }
  }, [formValues.country]);

  useEffect(() => {
    if (formValues.country_id) {
      fetchStates(formValues.country_id, "states");
    } else {
      setStates([]);
    }
  }, [formValues.country_id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormValues(prevValues => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (formValues.role === '2' && !formValues.company) {
      newErrors.company = 'Company Name is required';
    }
    if (formValues.role === '2' && !formValues.under_dba) {
      newErrors.under_dba = 'under_dba  is required';
    }
    // if (formValues.role === '2' && !formValues.parent_company_name) {
    //   newErrors.parent_company_name = 'parent_company_name  is required';
    // }
    if (formValues.role === '2' && !formValues.phone_number) {
      newErrors.phone_number = 'phone_number  is required';
    }
    if (formValues.role === '2' && !formValues.under_dba) {
      newErrors.customer_service_phone_number = 'customer_service_phone_number  is required';
    }
    if (formValues.role === '2' && !formValues.email_address) {
      newErrors.email_address = 'email_address  is required';
    }
    if (formValues.role === '2' && !formValues.fax_number) {
      newErrors.fax_number = 'fax_number  is required';
    }
    if (formValues.role === '2' && !formValues.street) {
      newErrors.street = 'street  is required';
    }
    if (formValues.role === '2' && !formValues.company_city) {
      newErrors.company_city = 'company_city  is required';
    }
    if (formValues.role === '2' && !formValues.state_province) {
      newErrors.state_province = 'state_province  is required';
    }
    if (formValues.role === '2' && !formValues.country) {
      newErrors.country = 'country  is required';
    }
    if (formValues.role === '2' && !formValues.zip_postal_code) {
      newErrors.zip_postal_code = 'zip_postal_code  is required';
    }
    if (formValues.role === '2' && !formValues.website_address) {
      newErrors.website_address = 'website_address  is required';
    }
    if (formValues.role === '2' && !formValues.website_address) {
      newErrors.website_address = 'website_address  is required';
    }
    if (formValues.role === '2' && !formValues.other_marketplaces) {
      newErrors.other_marketplaces = 'other_marketplaces  is required';
    } if (formValues.role === '2' && !formValues.representative_first_name) {
      newErrors.representative_first_name = 'representative_first_name  is required';
    } if (formValues.role === '2' && !formValues.representative_middle_initial) {
      newErrors.representative_middle_initial = 'representative_middle_initial  is required';
    } if (formValues.role === '2' && !formValues.representative_last_name) {
      newErrors.representative_last_name = 'representative_last_name  is required';
    } if (formValues.role === '2' && !formValues.representative_business_title) {
      newErrors.representative_business_title = 'representative_business_title  is required';
    }
    if (formValues.role === '2' && !formValues.representative_preferred_phone_number) {
      newErrors.representative_preferred_phone_number = 'representative_preferred_phone_number  is required';
    }
    if (formValues.role === '2' && !formValues.representative_alternate_phone_number) {
      newErrors.representative_alternate_phone_number = 'representative_alternate_phone_number  is required';
    }
    if (formValues.role === '2' && !formValues.representative_business_title) {
      newErrors.representative_business_title = 'representative_business_title  is required';
    }
    if (formValues.role === '2' && !formValues.representative_preferred_email_address) {
      newErrors.representative_preferred_email_address = 'representative_preferred_email_address  is required';
    }

    if (formValues.role === '2' && !formValues.representative_preferred_phone_number) {
      newErrors.representative_preferred_phone_number = 'representative_preferred_phone_number  is required';
    }


    if (!formValues.first_name) newErrors.first_name = 'First Name is required';
    if (!formValues.last_name) newErrors.last_name = 'Last Name is required';
    if (!formValues.country_id) newErrors.country_id = 'Country is required';
    if (!formValues.state_id) newErrors.state_id = 'State is required';
    if (!formValues.city) newErrors.city = 'City is required';
    if (!formValues.postal_code) newErrors.postal_code = 'Postal Code is required';
    if (!formValues.phone1) newErrors.phone1 = 'Phone 1 is required';
    if (!formValues.username) newErrors.username = 'Username is required';
    if (!formValues.password) newErrors.password = 'Password is required';
    if (formValues.password !== formValues.password_confirmation) newErrors.password_confirmation = 'Passwords must match';
    if (!formValues.email) newErrors.email = 'Email is required';
    if (formValues.email !== formValues.confirmEmail) newErrors.confirmEmail = 'Emails must match';
    if (!formValues.address) newErrors.address = 'Address is required';
    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setSubmitting(true);
    try {
      const formData = new FormData();
      const updatedFormValues = {
        ...formValues,
        hide_username: formValues.hide_username ? 1 : 0,
        allow_magnifying_glass: formValues.allow_magnifying_glass ? 1 : 0,
        send_email_notifications: formValues.send_email_notifications ? 1 : 0,
        newsletter: formValues.newsletter ? 1 : 0,
        under_dba: formValues.under_dba ? 1 : 0,
      };
      Object.keys(updatedFormValues).forEach(key => {
        formData.append(key, updatedFormValues[key]);
      });
      formData.append('faq', faqFile);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, formData, {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          'Content-Type': 'multipart/form-data',
        }
      });
      console.log('Registration successful:', response.data.message);
      toast.success(response.data.message);
      window.location.reload();
      navigate("/")
    } catch (error) {
      console.error('API Error:', error);
    } finally {
      setSubmitting(false);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const validationErrors = validate();
  //   if (Object.keys(validationErrors).length > 0) {
  //     setErrors(validationErrors);
  //     return; 
  //   }
  //   setSubmitting(true);
  //   try {

  //     const formData = new FormData();
  //     Object.keys(formValues).forEach(key => {
  //       formData.append(key, formValues[key]);
  //     });

  //     formData.append(faq, faqFile);




  //     const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, formData, {
  //       headers: {
  //         "ngrok-skip-browser-warning": "69420",
  //         'Content-Type': 'multipart/form-data',

  //       }
  //     });
  //     console.log('Registration successful:', response.data);
  //   } catch (error) {
  //     console.error('API Error:', error);
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };


  return (
    <>
      <ToastContainer />
      <Card sx={{ maxWidth: 600, mx: "auto", mt: 5, mb: 5, p: 3 }}>
        <Box sx={{ mb: 3, textAlign: "center", backgroundColor: "white", color: "black", borderRadius: 1 }}>
          <Typography variant="h4" gutterBottom>
            Register
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="role"
              name="role"
              value={formValues.role}
              onChange={handleChange}
              label="Role"
              error={Boolean(errors.role)}
            >
              {roles && roles.length > 0 ? (
                roles.map(role => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No roles available</MenuItem>
              )}
            </Select>
            {errors.role && (
              <Typography variant="caption" color="error">
                {errors.role}
              </Typography>
            )}
          </FormControl>
        </Box>
        <Box component="form" onSubmit={handleSubmit}>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }} style={{ display: "none" }}>
            Current role: {formValues.role}
          </Typography>

          {formValues.role === 2 && (
            <>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="Company Name"
                  variant="outlined"
                  name="company"
                  value={formValues.company}
                  onChange={handleChange}
                  error={Boolean(errors.company)}
                  helperText={errors.company}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="business-label">Business Structure</InputLabel>
                  <Select
                    labelId="business-label"
                    id="business_structure_id"
                    name="business_structure_id"
                    value={formValues.business_structure_id}
                    onChange={handleChange}
                    label="Business Structure"
                    error={Boolean(errors.business_structure_id)}
                  >
                    {business.length > 0 ? (
                      business.map(business => (
                        <MenuItem key={business.id} value={business.id}>
                          {business.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No business structures available</MenuItem>
                    )}
                  </Select>
                  {errors.business_structure_id && (
                    <Typography variant="caption" color="error">
                      {errors.business_structure_id}
                    </Typography>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ mb: 2 }}>
                <FormControlLabel
                  control={<Checkbox name="under_dba" checked={formValues.under_dba} onChange={handleChange} />}
                  label="under_dba"
                />
              </Box>
              {formValues.under_dba && (
                <Box sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    label="Parent Company Name"
                    variant="outlined"
                    name="parent_company_name"
                    value={formValues.parent_company_name}
                    onChange={handleChange}
                    error={Boolean(errors.parent_company_name)}
                    helperText={errors.parent_company_name}
                  />
                </Box>
              )}
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="phone_number"
                  variant="outlined"
                  name="phone_number"
                  value={formValues.phone_number}
                  onChange={handleChange}
                  error={Boolean(errors.phone_number)}
                  helperText={errors.phone_number}
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="customer_service_phone_number"
                  variant="outlined"
                  name="customer_service_phone_number"
                  value={formValues.customer_service_phone_number}
                  onChange={handleChange}
                  error={Boolean(errors.customer_service_phone_number)}
                  helperText={errors.customer_service_phone_number}
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="email_address"
                  variant="outlined"
                  name="email_address"
                  value={formValues.email_address}
                  onChange={handleChange}
                  error={Boolean(errors.email_address)}
                  helperText={errors.email_address}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="fax_number"
                  variant="outlined"
                  name="fax_number"
                  value={formValues.fax_number}
                  onChange={handleChange}
                  error={Boolean(errors.fax_number)}
                  helperText={errors.fax_number}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="street"
                  variant="outlined"
                  name="street"
                  value={formValues.street}
                  onChange={handleChange}
                  error={Boolean(errors.street)}
                  helperText={errors.street}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="company_city "
                  variant="outlined"
                  name="company_city"
                  value={formValues.company_city}
                  onChange={handleChange}
                  error={Boolean(errors.company_city)}
                  helperText={errors.company_city}
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    name="country"
                    value={formValues.country}
                    onChange={handleChange}
                    label="Country"
                    error={Boolean(errors.country)}
                  >
                    <MenuItem value="">--Select a country--</MenuItem>
                    {countries.map((country) => (
                      <MenuItem key={country.hash_id} value={country.hash_id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.country && (
                    <Typography variant="caption" color="error">
                      {errors.country}
                    </Typography>
                  )}
                </FormControl>
              </Box>

              <Box sx={{ mb: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    labelId="state-label"
                    id="state_province"
                    name="state_province"
                    value={formValues.state_province}
                    onChange={handleChange}
                    label="State"
                    error={Boolean(errors.state_province)}
                  >
                    <MenuItem value="">--Select a state--</MenuItem>
                    {statesProvienceList.length > 0 ? (
                      statesProvienceList.map(state => (
                        <MenuItem key={state.hash_id} value={state.hash_id}>
                          {state.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No states available</MenuItem>
                    )}
                  </Select>
                  {errors.state_province && (
                    <Typography variant="caption" color="error">
                      {errors.state_province}
                    </Typography>
                  )}
                </FormControl>
              </Box>

              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="zip_postal_code"
                  variant="outlined"
                  name="zip_postal_code"
                  value={formValues.zip_postal_code}
                  onChange={handleChange}
                  error={Boolean(errors.zip_postal_code)}
                  helperText={errors.zip_postal_code}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="website_address"
                  variant="outlined"
                  name="website_address"
                  value={formValues.website_address}
                  onChange={handleChange}
                  error={Boolean(errors.website_address)}
                  helperText={errors.website_address}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="other_marketplaces"
                  variant="outlined"
                  name="other_marketplaces"
                  value={formValues.other_marketplaces}
                  onChange={handleChange}
                  error={Boolean(errors.other_marketplaces)}
                  helperText={errors.other_marketplaces}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="representative_first_name"
                  variant="outlined"
                  name="representative_first_name"
                  value={formValues.representative_first_name}
                  onChange={handleChange}
                  error={Boolean(errors.representative_first_name)}
                  helperText={errors.representative_first_name}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="representative_middle_initial"
                  variant="outlined"
                  name="representative_middle_initial"
                  value={formValues.representative_middle_initial}
                  onChange={handleChange}
                  error={Boolean(errors.representative_middle_initial)}
                  helperText={errors.representative_middle_initial}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="representative_last_name "
                  variant="outlined"
                  name="representative_last_name"
                  value={formValues.representative_last_name}
                  onChange={handleChange}
                  error={Boolean(errors.representative_last_name)}
                  helperText={errors.representative_last_name}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="representative_business_title"
                  variant="outlined"
                  name="representative_business_title"
                  value={formValues.representative_business_title}
                  onChange={handleChange}
                  error={Boolean(errors.representative_business_title)}
                  helperText={errors.representative_business_title}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="representative_preferred_phone_number"
                  variant="outlined"
                  name="representative_preferred_phone_number"
                  value={formValues.representative_preferred_phone_number}
                  onChange={handleChange}
                  error={Boolean(errors.representative_preferred_phone_number)}
                  helperText={errors.representative_preferred_phone_number}
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="representative_alternate_phone_number"
                  variant="outlined"
                  name="representative_alternate_phone_number"
                  value={formValues.representative_alternate_phone_number}
                  onChange={handleChange}
                  error={Boolean(errors.representative_alternate_phone_number)}
                  helperText={errors.representative_alternate_phone_number}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label="representative_preferred_email_address"
                  variant="outlined"
                  name="representative_preferred_email_address"
                  value={formValues.representative_preferred_email_address}
                  onChange={handleChange}
                  error={Boolean(errors.representative_preferred_email_address)}
                  helperText={errors.representative_preferred_email_address}
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <p className="text-start">
                  <b>Please refer to the section below to understand what documents you need to submit with your application</b>
                </p>
              </Box>
              <Box sx={{ mb: 2 }}>
                {faqData.map((faq, index) => (
                  <div key={index} style={{ border: '1px solid #ddd', marginBottom: '10px', borderRadius: '5px', overflow: 'hidden', }}>
                    <div
                      style={{
                        padding: '15px',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      onClick={() => handleToggle(index)}
                      className='m-0 '
                    >
                      {faq.question}
                      <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        {activeIndex === index ? '-' : '+'}
                      </span>
                    </div>
                    {activeIndex === index && (
                      <>
                        <div
                          style={{ padding: '15px', backgroundColor: '#fff' }}
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        />
                        <div className="mx-4 mb-3">
                          <input
                            type="file"
                            label="faqFile"
                            name="faqFile"
                            onChange={handleFileChange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </Box>
            </>
          )}

          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              name="first_name"
              value={formValues.first_name}
              onChange={handleChange}
              error={Boolean(errors.first_name)}
              helperText={errors.first_name}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              name="last_name"
              value={formValues.last_name}
              onChange={handleChange}
              error={Boolean(errors.last_name)}
              helperText={errors.last_name}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Address"
              variant="outlined"
              name="address"
              value={formValues.address}
              onChange={handleChange}
              error={Boolean(errors.address)}
              helperText={errors.address}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                id="country"
                name="country_id"
                value={formValues.country_id}
                onChange={handleChange}
                label="Country"
                error={Boolean(errors.country_id)}
              >
                {countries.map(country => (
                  <MenuItem key={country.hash_id} value={country.hash_id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.country_id && (
                <Typography variant="caption" color="error">
                  {errors.country_id}
                </Typography>
              )}
            </FormControl>
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="state-label">State</InputLabel>
              <Select
                labelId="state-label"
                id="state"
                name="state_id"
                value={formValues.state_id}
                onChange={handleChange}
                label="State"
                error={Boolean(errors.state_id)}
              >
                {states.map(state => (
                  <MenuItem key={state.hash_id} value={state.hash_id}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.state_id && (
                <Typography variant="caption" color="error">
                  {errors.state_id}
                </Typography>
              )}
            </FormControl>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="City"
              variant="outlined"
              name="city"
              value={formValues.city}
              onChange={handleChange}
              error={Boolean(errors.city)}
              helperText={errors.city}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Postal Code"
              variant="outlined"
              name="postal_code"
              value={formValues.postal_code}
              onChange={handleChange}
              error={Boolean(errors.postal_code)}
              helperText={errors.postal_code}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Phone 1"
              variant="outlined"
              name="phone1"
              value={formValues.phone1}
              onChange={handleChange}
              error={Boolean(errors.phone1)}
              helperText={errors.phone1}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Phone 2"
              variant="outlined"
              name="phone2"
              value={formValues.phone2}
              onChange={handleChange}
              error={Boolean(errors.phone2)}
              helperText={errors.phone2}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Fax"
              variant="outlined"
              name="fax"
              value={formValues.fax}
              onChange={handleChange}
              error={Boolean(errors.fax)}
              helperText={errors.fax}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              name="username"
              value={formValues.username}
              onChange={handleChange}
              error={Boolean(errors.username)}
              helperText={errors.username}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              type="password"
              label="Password"
              variant="outlined"
              name="password"
              value={formValues.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              type="password"
              label="Confirm Password"
              variant="outlined"
              name="password_confirmation"
              value={formValues.password_confirmation}
              onChange={handleChange}
              error={Boolean(errors.password_confirmation)}
              helperText={errors.password_confirmation}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              type="email"
              label="Email"
              variant="outlined"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              type="email"
              label="Confirm Email"
              variant="outlined"
              name="confirmEmail"
              value={formValues.confirmEmail}
              onChange={handleChange}
              error={Boolean(errors.confirmEmail)}
              helperText={errors.confirmEmail}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox name="hide_username" checked={formValues.hide_username} onChange={handleChange} />}
              label="Hide Username"
            />
          </Box>
          <Box >
            <FormControlLabel
              control={<Checkbox name="allow_magnifying_glass" checked={formValues.allow_magnifying_glass} onChange={handleChange} />}
              label="Allow Magnifying Glass"
            />
          </Box>
          <Box >
            <FormControlLabel
              control={<Checkbox name="send_email_notifications" checked={formValues.send_email_notifications} onChange={handleChange} />}
              label="Send Notifications"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox name="newsletter" checked={formValues.newsletter} onChange={handleChange} />}
              label="Subscribe to Newsletter"
            />
          </Box>

          <Box sx={{ mt: 4, }} className="flex items-center justify-center">
            <Button
              variant="contained"
              className="sign-up-auctioner"
              fullWidth
              type="submit"
              disabled={submitting}
            >
              Sign Up
            </Button>
          </Box>
          <Box sx={{ mt: 3, mb: 1, textAlign: "center" }}>
            <Typography variant="body2" color="textSecondary">
              Already have an account?
              <Typography
                component="a"
                href="/authentication/sign-in"
                variant="body2"
                color="primary"
              >
                Sign In
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default Cover;
