import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Form, Modal, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryType, setNewCategoryType] = useState('main');
    const [imageFile, setImageFile] = useState(null);
    const [selectedImageName, setSelectedImageName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const navigate = useNavigate();

    const fetchCategories = async () => {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            setLoading(false);
            return;
        }
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'ngrok-skip-browser-warning': '69420',
            }
        };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, apiHeaders);
            setCategories(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setLoading(false);
        }
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageFile(file);
        }
    };
    useEffect(() => {
        fetchCategories();
    }, []);
    const handleCreateCategory = async () => {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            return;
        }
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'ngrok-skip-browser-warning': '69420',
                'Content-Type': 'multipart/form-data',
            }
        };
        const formData = new FormData();
        formData.append('name', newCategoryName);
        formData.append('category_type', newCategoryType);
        formData.append('parent_id', "");
        if (imageFile) {
            formData.append('image', imageFile);
        }
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/admin/categories/create`, formData, apiHeaders);
            toast.success("Category added successfully!");
            fetchCategories();
            setShowModal(false);
            setNewCategoryName('');
            setNewCategoryType('main');
            setImageFile(null);
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error("Error adding category. Please try again.");
        }
    };
    const handleViewCategory = (hash_id) => {
        navigate(`/super/admin/categories/${hash_id}`);
    };
    const handleShowDeleteConfirm = (hash_id) => {
        setCategoryToDelete(hash_id);
        setDeleteConfirmModal(true);
    };
    const handleDeleteCategory = async () => {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            return;
        }
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'ngrok-skip-browser-warning': '69420',
            }
        };
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/admin/categories/delete/${categoryToDelete}`, apiHeaders);
            toast.success("Category deleted successfully!");
            fetchCategories();
            setDeleteConfirmModal(false);
            setCategoryToDelete(null);
        } catch (error) {
            console.error('Error deleting category:', error);
            toast.error("Error deleting category. Please try again.");
        }
    };
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCategories = categories.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(categories.length / itemsPerPage);
    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
    const renderPagination = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
        return (
            <Pagination>
                <Pagination.Prev
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                />
                {items}
                <Pagination.Next
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                />
            </Pagination>
        );
    };
    if (loading) {
        return <div className="loader-overlay">
            <div className="spinner-border-custom"></div>
        </div>;
    }
    return (
        <div className='container p-3'>
            <div className='row pt-3'>
                <div className='col-md-6'>
                    <h1 className='px-3 pb-3'>Categories</h1>
                </div>
                <div className='col-md-6 '>
                    <div className='text-right'>
                        <Button variant="primary" onClick={() => setShowModal(true)}>Create Category</Button>
                    </div>
                </div>
            </div>
            <Table striped bordered hover responsive className="country-table">
                <thead>
                    <tr>
                        <th>Category Name</th>
                        <th>Type</th>
                        <th>Slug</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentCategories.length > 0 ? (
                        currentCategories.map(category => (
                            <tr key={category.hash_id}>
                                <td>{category.name}</td>
                                <td>{category.category_type}</td>
                                <td>{category.slug}</td>
                                <td>
                                    <Button
                                        variant={category.is_active === 1 ? 'success' : 'danger'}
                                    >
                                        {category.is_active === 1 ? 'Enabled' : 'Disabled'}
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        variant="info"
                                        onClick={() => handleViewCategory(category.hash_id)}
                                    >
                                        View
                                    </Button>
                                    <Button
                                        variant="danger"
                                        className="ml-2"
                                        onClick={() => handleShowDeleteConfirm(category.hash_id)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center">No categories available</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {renderPagination()}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ width: "100%" }}>
                        <Form.Group controlId="formCategoryName">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter category name"
                                value={newCategoryName}
                                onChange={(e) => setNewCategoryName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formCategoryType">
                            <Form.Label>Category Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={newCategoryType}
                                onChange={(e) => setNewCategoryType(e.target.value)}
                            >
                                <option value="main">Main</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formImage">
                            <Form.Label>Image</Form.Label>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCreateCategory}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={deleteConfirmModal} onHide={() => setDeleteConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>Are you sure you want to delete this category?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteCategory}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div>
    );
};
export default Categories;
