// import React, { useState, useCallback } from 'react';
// import axios from 'axios';
// import { RingLoader } from 'react-spinners';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

// const LotTypeSelector = ({ selectedLotStatus, handleLotStatusChange }) => {
//     const [showLotStatus, setShowLotStatus] = useState(false);
//     const [lotTypes, setLotTypes] = useState([]);
//     const [loadingLotTypes, setLoadingLotTypes] = useState(false);
//     const [dataLoaded, setDataLoaded] = useState(false);

//     const fetchLotTypes = async () => {
//         setLoadingLotTypes(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}/bidding-types`, {
//                 headers: {
//                     "ngrok-skip-browser-warning": "69420"
//                 }
//             });
//             setLotTypes(response.data.data);
//             setDataLoaded(true);
//         } catch (error) {
//             console.error('Error fetching lot types:', error);
//         } finally {
//             setLoadingLotTypes(false);
//         }
//     };

//     const toggleLotStatus = useCallback(() => {
//         if (!showLotStatus && !dataLoaded) {
//             fetchLotTypes();
//         }
//         setShowLotStatus(prevState => !prevState);
//     }, [showLotStatus, dataLoaded]);

//     return (
//         <>
//             <div className='sidebar-item d-flex lots-status font-bold' onClick={toggleLotStatus}>
//                 LOT TYPE
//                 <div className='mx-2'>
//                     <FontAwesomeIcon icon={showLotStatus ? faAngleUp : faAngleDown} className='toggle-icon quick-search' />
//                 </div>
//             </div>
//             {showLotStatus && (
//                 <div className='sidebar-sub-item' onClick={(e) => e.stopPropagation()}>
//                     {loadingLotTypes ? (
//                         <div className='d-flex justify-content-center align-items-center'>
//                             <RingLoader size={60} color={'#123abc'} loading={loadingLotTypes} />
//                         </div>
//                     ) : (
//                         <div className='form-group'>
//                             {lotTypes.map(lotType => (
//                                 <label key={lotType.hash_id} className='mb-3'>
//                                     <input
//                                         type='radio'
//                                         className='font-medium'
//                                         checked={selectedLotStatus === lotType.name}
//                                         onChange={() => handleLotStatusChange(lotType.name)}
//                                     /> <span className='px-2 font-[500]'>{lotType.name}</span>
//                                 </label>
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             )}
//         </>
//     );
// };

// export default LotTypeSelector;


import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { RingLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const LotTypeSelector = ({ selectedLotStatus, handleLotStatusChange }) => {
    const [showLotStatus, setShowLotStatus] = useState(false);
    const [lotTypes, setLotTypes] = useState([]);
    const [loadingLotTypes, setLoadingLotTypes] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const fetchLotTypes = async () => {
        setLoadingLotTypes(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/bidding-types`, {
                headers: {
                    "ngrok-skip-browser-warning": "69420"
                }
            });
            setLotTypes(response.data.data);
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching lot types:', error);
        } finally {
            setLoadingLotTypes(false);
        }
    };

    const toggleLotStatus = useCallback(() => {
        // If dropdown is being closed (showLotStatus is true), reset selected lot type
        if (showLotStatus) {
            handleLotStatusChange(null);  // or handleLotStatusChange('') to clear selection
        } else if (!dataLoaded) {
            fetchLotTypes();
        }
        setShowLotStatus(prevState => !prevState);
    }, [showLotStatus, dataLoaded, handleLotStatusChange]);

    return (
        <>
            <div className='sidebar-item d-flex lots-status font-bold' onClick={toggleLotStatus}>
                LOT TYPE
                <div className='mx-2'>
                    <FontAwesomeIcon icon={showLotStatus ? faAngleUp : faAngleDown} className='toggle-icon quick-search' />
                </div>
            </div>
            {showLotStatus && (
                <div className='sidebar-sub-item' onClick={(e) => e.stopPropagation()}>
                    {loadingLotTypes ? (
                        <div className='d-flex justify-content-center align-items-center'>
                            <RingLoader size={60} color={'#123abc'} loading={loadingLotTypes} />
                        </div>
                    ) : (
                        <div className='form-group'>
                            {lotTypes.map(lotType => (
                                <label key={lotType.hash_id} className='mb-3'>
                                    <input
                                        type='radio'
                                        className='font-medium'
                                        checked={selectedLotStatus === lotType.name}
                                        onChange={() => handleLotStatusChange(lotType.name)}
                                    /> <span className='px-2 font-[500]'>{lotType.name}</span>
                                </label>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default LotTypeSelector;
