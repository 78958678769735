import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { RingLoader } from 'react-spinners';
import Category12 from "../../../assets/images/Category3.png";
import "./AuctionCategories.css";
import CatogiryList from "./CatogiryList"

const AuctionCategories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const sectionRefs = useRef({});

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/categories`,
                    {
                        headers: {
                            'ngrok-skip-browser-warning': '69420',
                        }
                    }
                );
                setCategories(response.data.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCategories();
    }, []);

    const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

    // Group categories by the starting letter
    const groupedCategories = categories.reduce((acc, category) => {
        const firstLetter = category.name[0].toUpperCase();
        if (!acc[firstLetter]) {
            acc[firstLetter] = [];
        }
        acc[firstLetter].push(category);
        return acc;
    }, {});


    const availableLetters = Object.keys(groupedCategories).sort();


    const handleLetterClick = (letter) => {
        if (sectionRefs.current[letter]) {
            sectionRefs.current[letter].scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='state-auction'>
            <div className='container-fluid'>
                <div className='row auction-home'>
                    <p className='text-start pt-2 px-2'>
                        HOME
                        <span> &gt; </span>
                        <span>All Categories</span>
                    </p>
                </div>
            </div>
            <CatogiryList />
            <div className='container pt-4 pb-2 text-center'>
                <h3>All Categories</h3>
                <div className='row pt-4 pb-3' style={{ justifyContent: "center" }}>
                    {Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)).map((letter, index) => (
                        availableLetters.includes(letter) ? (
                            <div key={index} className='col-auto px-2'>
                                <span
                                    className='letter'
                                    onClick={() => handleLetterClick(letter)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {letter}
                                </span>
                            </div>
                        ) : (
                            <div key={index} className='col-auto px-2 disabled-letter'>
                                <span className='letter disabled'>{letter}</span>
                            </div>
                        )
                    ))}
                </div>
            </div>
            <div className='category'>
                <div className='container'>
                    {loading ? (
                        <div className="loader-container d-flex justify-content-center mt-5">
                            <RingLoader color={"#123abc"} loading={loading} size={150} />
                        </div>
                    ) : (
                        <div>
                            {Object.keys(groupedCategories).map(letter => (
                                <div
                                    key={letter}
                                    className='full-width-div'
                                    ref={el => sectionRefs.current[letter] = el}
                                >
                                    <div className='container'>
                                        <div className='row text-start' style={{ background: "#b7e1e4" }}>
                                            <h4 className='px-4'>{letter}</h4>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        {groupedCategories[letter].slice(0, 6).map((category, index) => (
                                            <div className='col-md-2 col-4 mb-2' key={index}>
                                                <div className="">
                                                    <h6 className='text-center  mt-3'>{category.name}</h6>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default AuctionCategories;
