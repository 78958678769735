import React, { useState } from 'react';
import './MainData.css';
import { RingLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faThLarge, faTh } from '@fortawesome/free-solid-svg-icons';
import JifImg from '../../../../assets/images/img.webp';

const MainData = ({ data, isLoading }) => {
    const [sortOption, setSortOption] = useState('Sale Order');
    const [sortDirection, setSortDirection] = useState('ASC');
    const [view, setView] = useState('list');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;

    const handleSortOptionChange = (e) => {
        setSortOption(e.target.value);
    };

    const handleSortDirectionChange = (e) => {
        setSortDirection(e.target.value);
    };

    const handleViewChange = (viewType) => {
        setView(viewType);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderItem = (item) => {
        const hasImage = item?.lot?.images && item.lot.images.length > 0;
        const imageSrc = hasImage ? item.lot.images[0].image_path : JifImg;
        const lotNumberTitle = `${item.lot.lot_number} | ${item.lot.title}`;
        const bidAmount = item.amount ? `$${item.amount}` : 'Outbid';
        const winningAmount = item.status === 'Winning' ? `$${item.amount}` : '';
        const shippingStatus = item.lot.shipping_available ? 'Shipping Available' : 'No Shipping';
        return (
            <div className="data-item">
                <div className="image-column">
                    <img src={imageSrc} alt="Lot Image" />
                </div>
                <div className="description-column px-3">
                    <p>{lotNumberTitle}</p>
                </div>
                <div className="date-column">
                    <p>{new Date(item.created_at).toLocaleDateString()}</p>
                </div>
                <div className="amount-column">
                    <p>Amount: <span>{winningAmount}  {bidAmount}</span></p>
                </div>
                <div className="shipping-column">
                    <p>  {shippingStatus}</p>
                </div>
            </div>
        );
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = data.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(data.length / itemsPerPage);

    return (
        <div className="main-data">
            <div className="row">
                <div className="col-md-12">
                    <div className="sort-container">
                        <div className="sort-options">
                            <label style={{ paddingRight: '50px' }}>
                                Sort:
                                &nbsp;
                                <select
                                    value={sortOption}
                                    onChange={handleSortOptionChange}
                                    className="sort-dropdown"
                                >
                                    <option value="Sale Order">Sale Order</option>
                                    <option value="Lot#">Lot</option>
                                    <option value="Bid History">Bid History</option>
                                    <option value="Time Remaining">Time Remaining</option>
                                    <option value="High Bid">High Bid</option>
                                    <option value="Max Bid">Max Bid</option>
                                    <option value="Bid Status">Bid Status</option>
                                </select>
                            </label>
                            <label style={{ paddingRight: '30px' }}>
                                <select
                                    value={sortDirection}
                                    onChange={handleSortDirectionChange}
                                    className="direction-dropdown"
                                >
                                    <option value="ASC">ASC</option>
                                    <option value="DESC">DESC</option>
                                </select>
                            </label>
                            <button
                                className={`view-button ${view === 'list' ? 'active' : ''}`}
                                onClick={() => handleViewChange('list')}
                            >
                                <FontAwesomeIcon icon={faList} />
                            </button>
                            <button
                                className={`view-button ${view === 'grid-2' ? 'active' : ''}`}
                                onClick={() => handleViewChange('grid-2')}
                            >
                                <FontAwesomeIcon icon={faThLarge} />
                            </button>
                            <button
                                className={`view-button ${view === 'grid-12' ? 'active' : ''}`}
                                onClick={() => handleViewChange('grid-12')}
                            >
                                <FontAwesomeIcon icon={faTh} />
                            </button>
                        </div>
                    </div>
                    {isLoading ? (
                        <div className="loader-container">
                            <RingLoader color={"#36D7B7"} size={60} />
                        </div>
                    ) : (
                        <>
                            <div className={`row pt-3 ${view}`}>
                                {currentData && currentData.length > 0 ? (
                                    currentData.map((item, index) => (
                                        <div key={index} className={`col-md-${view === 'grid-2' ? '6' : view === 'grid-12' ? '4' : '12'}`}>
                                            {view === 'list' && (
                                                <div className="data-box-list">
                                                    {renderItem(item)}
                                                </div>
                                            )}
                                            {view === 'grid-2' && (
                                                <div className="data-box-grid-2 mb-3">
                                                    <div className="item-header d-flex">
                                                        <p>{item.lot.lot_number} | &nbsp;</p>
                                                        <p>{item.lot.title}</p>
                                                    </div>
                                                    <div className='watchbids-image text-center'>
                                                        <img src={item.lot.images[0]?.image_path || JifImg} alt="Lot Image" />
                                                    </div>
                                                    <div className="item-details text-center pt-3">
                                                        <p>{item.status === 'Winning' ?
                                                            `Winning Amount: $${item.amount}` :
                                                            `Outbid: $${item.amount}`}</p>
                                                        <p>{item.lot.shipping_available ? 'Shipping Available' : 'No Shipping'}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {view === 'grid-12' && (
                                                <div className="data-box-grid-12 mb-3">
                                                    <div className="item-header">
                                                        <p>{item.lot.lot_number} | {item.lot.title}</p>
                                                    </div>
                                                    <div className="detail-column">
                                                        <img src={item.lot.images[0]?.image_path || JifImg} alt="Lot Image" />
                                                    </div>
                                                    <div className="detail-column">
                                                        <p className='pt-3 text-center'>{item.status === 'Winning' ? 'Winning' : 'Not Acceptable'}</p>
                                                        <p className='ellipsis'>{item.lot.description}</p>
                                                    </div>
                                                    <div className="detail-column">
                                                        <p className='text-center'><b>{item.amount}</b></p>
                                                    </div>
                                                    <div className="detail-column">
                                                        <p className='text-center'>{item.lot.shipping_available ? 'Shipping Available' : 'No Shipping'}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-md-12">
                                        <p>No data found</p>
                                    </div>
                                )}
                            </div>
                            <div className="pagination-controls">
                                <button
                                    className="pagination-button"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <button
                                        key={i + 1}
                                        className={`pagination-number ${currentPage === i + 1 ? 'active' : ''}`}
                                        onClick={() => handlePageChange(i + 1)}
                                    >
                                        {i + 1}
                                    </button>
                                ))}
                                <button
                                    className="pagination-button"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default MainData;
