import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
// import './ContactusForm.css';

const ContactusForm = () => {
    const [formData, setFormData] = useState({
        isAuctioneer: '',
        name: '',
        phone: '',
        email: '',
        zip: '',
        description: ''
    });
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePhoneChange = (value) => {
        setFormData({ ...formData, phone: value });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.isAuctioneer) newErrors.isAuctioneer = 'This field is required';
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.phone) newErrors.phone = 'Phone is required';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Valid email is required';
        if (!formData.zip) newErrors.zip = 'Zip code is required';
        if (!formData.description) newErrors.description = 'Description is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setFormData({
                isAuctioneer: '',
                name: '',
                phone: '',
                email: '',
                zip: '',
                description: ''
            });
            setErrors({});
        }
    };

    return (
        <div className='form-contact container pt-3'>
            <div className='row'>
                <div className='text-center pt-3'>
                    <h3>Sell on Auctions</h3>
                    <p className='pt-3'>Items to consign? Let us get you in touch with a Auctions auctioneer. Please fill out the form below.</p>
                </div>
                <div className='col-md-2'></div>
                <div className='col-md-8 p-4 rounded shadow-sm mt-5 mb-5' style={{ background: '#f5f5f5' }}>
                    <form onSubmit={handleSubmit} className='text-start'>
                        <div className='mb-3'>
                            <label className='form-label'>Are You An Auctioneer? </label>
                            <div>
                                <label>
                                    <input
                                        type='radio'
                                        name='isAuctioneer'
                                        value='yes'
                                        checked={formData.isAuctioneer === 'yes'}
                                        onChange={handleChange}
                                    />
                                    Yes
                                </label>
                                <label className='ms-3'>
                                    <input
                                        type='radio'
                                        name='isAuctioneer'
                                        value='no'
                                        checked={formData.isAuctioneer === 'no'}
                                        onChange={handleChange}
                                    />
                                    No
                                </label>
                                {errors.isAuctioneer && <div className='text-danger'>{errors.isAuctioneer}</div>}
                            </div>
                        </div>
                        <div className='mb-3'>
                            <label className='form-label'>
                                <FontAwesomeIcon icon={faUser} className='me-2' />
                                Name
                            </label>
                            <input
                                type='text'
                                name='name'
                                className='form-control'
                                value={formData.name}
                                onChange={handleChange}
                            />
                            {errors.name && <div className='text-danger'>{errors.name}</div>}
                        </div>
                        <div className='mb-3'>
                            <label className='form-label'>
                                <FontAwesomeIcon icon={faPhone} className='me-2' />
                                Phone
                            </label>
                            <PhoneInput
                                country={'us'}
                                value={formData.phone}
                                onChange={handlePhoneChange}
                                inputClass='form-control'
                            />
                            {errors.phone && <div className='text-danger'>{errors.phone}</div>}
                        </div>
                        <div className='mb-3'>
                            <label className='form-label'>
                                <FontAwesomeIcon icon={faEnvelope} className='me-2' />
                                Email
                            </label>
                            <input
                                type='email'
                                name='email'
                                className='form-control'
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <div className='text-danger'>{errors.email}</div>}
                        </div>
                        <div className='mb-3'>
                            <label className='form-label'>
                                <FontAwesomeIcon icon={faLocationDot} className='me-2' />
                                Zip Code
                            </label>
                            <input
                                type='text'
                                name='zip'
                                className='form-control'
                                value={formData.zip}
                                onChange={handleChange}
                            />
                            {errors.zip && <div className='text-danger'>{errors.zip}</div>}
                        </div>
                        <div className='mb-3'>
                            <label className='form-label'>Description</label>
                            <textarea
                                name='description'
                                className='form-control'
                                rows='4'
                                value={formData.description}
                                onChange={handleChange}
                            />
                            {errors.description && <div className='text-danger'>{errors.description}</div>}
                        </div>
                        <div className='text-center'>
                            <button type='submit' className='btn btn-primary'>Submit</button>
                        </div>
                    </form>
                </div>
                <div className='col-md-2'></div>
            </div>
        </div>
    );
};

export default ContactusForm;
