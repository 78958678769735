
import React from 'react';
import { RingLoader } from 'react-spinners';

const LotLoader = () => (
    <div className="loader-container d-flex justify-content-center mt-5">
        <RingLoader color={"#123abc"} size={150} />
    </div>
);

export default LotLoader;
