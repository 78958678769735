import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Table, Button, Form, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./Categories.css";

const Subcategory = () => {
    const { hash_id } = useParams();
    const [category, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [newCategoryType, setNewCategoryType] = useState("subcategory");
    const [categoryImage, setCategoryImage] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const navigate = useNavigate();

    const fetchCategoryDetails = async () => {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
            console.error("Access token not found in localStorage.");
            setLoading(false);
            return;
        }
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "ngrok-skip-browser-warning": "69420",
            },
        };
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/categories/${hash_id}`,
                apiHeaders
            );
            setCategory(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching category details:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCategoryDetails();
    }, [hash_id]);

    const handleViewSubcategory = (subHashId) => {
        navigate(`/super/admin/categories/${subHashId}`);
    };

    const handleAddSubcategory = async () => {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
            console.error("Access token not found in localStorage.");
            return;
        }
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "ngrok-skip-browser-warning": "69420",
            },
        };
        const formData = new FormData();
        formData.append("name", newCategoryName);
        formData.append("category_type", newCategoryType);
        formData.append("parent_id", hash_id || "");
        if (categoryImage) {
            formData.append("image", categoryImage);
        }
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/admin/categories/create`,
                formData,
                {
                    headers: {
                        ...apiHeaders.headers,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            toast.success("Subcategory added successfully!");
            fetchCategoryDetails();
            setShowModal(false);
            setNewCategoryName("");
            setNewCategoryType("subcategory");
            setCategoryImage(null);
        } catch (error) {
            console.error("Error creating new subcategory:", error);
            toast.error("Error adding subcategory. Please try again.");
        }
    };
    const handleDeleteSubcategory = async () => {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
            console.error("Access token not found in localStorage.");
            return;
        }
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "ngrok-skip-browser-warning": "69420",
            },
        };
        try {
            await axios.delete(
                `${process.env.REACT_APP_API_URL}/admin/categories/delete/${categoryToDelete}`,
                apiHeaders
            );
            toast.success("Subcategory deleted successfully!");
            fetchCategoryDetails();
            setDeleteConfirmModal(false);
        } catch (error) {
            console.error("Error deleting subcategory:", error);
            toast.error("Error deleting subcategory. Please try again.");
        }
    };
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const paginate = (array, page_size, page_number) => {
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    };
    if (loading) {
        return (
            <div className="loader-overlay">
                <div className="spinner-border-custom"></div>
            </div>
        );
    }

    const paginatedCategories = paginate(category, itemsPerPage, currentPage);
    const totalPages = Math.ceil(category.length / itemsPerPage);

    const renderPaginationButtons = () => {
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            if (
                i === 1 ||
                i === totalPages ||
                (i >= currentPage - 2 && i <= currentPage + 2)
            ) {
                pages.push(i);
            }
        }
        return pages.map((page, index) => (
            <Button
                key={index}
                onClick={() => handlePageChange(page)}
                active={currentPage === page}
            >
                {page}
            </Button>
        ));
    };

    return (
        <div className="container p-3">
            <div className='row pt-3'>
                <div className='col-md-6'>
                    <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(-1)} style={{ fontSize: "20px" }} />
                    <h1 className='px-3 pb-3'>Category Details</h1>
                </div>
                <div className='col-md-6'>
                    <div className="add-subcategory">
                        <Button variant="primary" onClick={() => setShowModal(true)}>
                            Add Subcategory
                        </Button>
                    </div>
                </div>
            </div>
            <Table striped bordered hover responsive className="country-table">
                <thead>
                    <tr>
                        <th>Category Name</th>
                        <th>Type</th>
                        <th>Slug</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedCategories.length > 0 ? (
                        paginatedCategories.map((subcategory) => (
                            <tr key={subcategory.hash_id}>
                                <td>{subcategory.name}</td>
                                <td>{subcategory.category_type}</td>
                                <td>{subcategory.slug}</td>
                                <td>
                                    <Button
                                        variant={subcategory.is_active === 1 ? "success" : "danger"}
                                    >
                                        {subcategory.is_active === 1 ? "Enabled" : "Disabled"}
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        variant="info"
                                        onClick={() => handleViewSubcategory(subcategory.hash_id)}
                                    >
                                        View
                                    </Button> &nbsp;
                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            setCategoryToDelete(subcategory.hash_id);
                                            setDeleteConfirmModal(true);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center">
                                No categories available
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>

            <div className="pagination-controls">
                <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                {currentPage > 3 && (
                    <>
                        <Button onClick={() => handlePageChange(1)}>1</Button>
                        {currentPage > 4 && <span>...</span>}
                    </>
                )}
                {renderPaginationButtons()}
                {currentPage < totalPages - 2 && (
                    <>
                        {currentPage < totalPages - 3 && <span>...</span>}
                        <Button onClick={() => handlePageChange(totalPages)}>
                            {totalPages}
                        </Button>
                    </>
                )}
                <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Subcategory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ width: "100%" }}>
                        <Form.Group controlId="formSubcategoryName">
                            <Form.Label>Subcategory Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter subcategory name"
                                value={newCategoryName}
                                onChange={(e) => setNewCategoryName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="formSubcategoryImage">
                            <Form.Label>Subcategory Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => setCategoryImage(e.target.files[0])}
                            />
                        </Form.Group>
                        <Form.Group controlId="formCategoryType">
                            <Form.Label>Category Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={newCategoryType}
                                onChange={(e) => setNewCategoryType(e.target.value)}
                            >
                                <option value="subcategory">Subcategory</option>
                                <option value="category">Category</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddSubcategory}>
                        Add Subcategory
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteConfirmModal} onHide={() => setDeleteConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-black">Are you sure you want to delete this subcategory?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDeleteSubcategory}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer />
        </div>
    );
};

export default Subcategory;
