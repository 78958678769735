import React from 'react';
import "./AboutHibid.css";

const AboutHibid = () => {
    window.scroll(0, 0);
    return (
        <div className='container-fluid abouthibibis'>
            <div className='row '>
                <div className="col-md-12">
                    <h3 className='text-start px-3 pt-4' style={{ color: '#2695ff' }}><b>ABOUT Auctions</b>  </h3>
                    <p className='about-hibis-text px-3 pt-3' pt-3>
                        AuctionsLive is the premier auction platform for selling a huge variety of items. Everything from real estate, cars, and fine jewelry to collectibles, sports memorabilia, and personal property is open for bidding on auctionslive.com at any given time. Thousands of auction companies worldwide use Auctions to conduct live webcast, online-only, and absentee bidding auctions. With a wide range of items always available at auction, Auctions has something for everyone.
                    </p>
                    <h5 className='pt-3 text-start px-3'>  <b>Ideal For Local & Online Auctions</b>  </h5>
                    <p className='about-hibis-text px-3'>AuctionsLive supports multiple types of online bidding, giving auctioneers the options they need to host auctions large and small either in-person or online. This gives bidders from around the world immediate access to huge estate sales and liquidations they might not otherwise be able to attend, and the ability to place bids at their convenience from any location.</p>
                    <h5 className='pt-3 text-start px-3'>  <b>Auctions For Everyone</b>  </h5>
                    <p className='about-hibis-text px-3'>Bidders can find auctions and lots quickly by simply using the auctionslive.com search bar or browsing the hundreds of categories from the menu on the homepage. Featured and Hot auctions below the search bar show auctions currently trending on Auctions, and there’s a Top Picks For You section featuring items bidders might be especially interested in based on previous visits to the site. Visit our FAQ Page for more information
                    </p>
                    <div className="about-hibis-text px-3 pt-2 pb-4">
                        <button className='about-hibis-text-btn' >Start selling today</button>
                    </div>
                    <h5 className='pt-3 text-start px-3 pt-3'>  <b>Simplicity & Support For Auctioneers</b>  </h5>
                    <p className='about-hibis-text px-3'>To streamline the online auction process, auctionslive.com was built from the ground up to work alongside Auction Flex 360, a full-service auction management solution. Auction Flex 360 provides auctioneers with clerking, cashiering, accounting, mailing list management, inventory management, multi-parcel bidding, and other features that help effectively run auctions. Auctioneers can specify auction details such as commission, seller tax, buyer premiums, and buyer tax. Auction Flex 360 staff also provide support before and after the sale to make the auction experience as smooth as possible for buyers and sellers alike.
                    </p>
                    <h5 className='pt-3 text-start px-3 '>  <b>Auctioneers, Get Started Today!</b>  </h5>
                    <p className='about-hibis-text px-3'>With the ability to sell just about anything and the flexibility to select the online auction format best for particular auction assets, auctionslive.com is the most reliable way to reach more bidders around the world, generate more bids, and realize higher sold prices. Auctioneers can visit Auction Flex 360 to get started with a free trial.
                    </p>
                    <div className="about-hibis-text px-3 pt-2 pb-5">
                        <button className='about-hibis-text-btn'  >Get A Free Trail</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutHibid;