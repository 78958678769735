import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CountryTable from './CountryTable';
import StateTable from './StateTable';
import PaginationComponent from './PaginationComponent';
import ConfirmationModal from './ConfirmationModal';
import StatesModal from './StatesModal';
import './AdminCountryList.css';
import { Form } from 'react-bootstrap';

const AdminCountryList = () => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [countriesPerPage] = useState(20);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [actionType, setActionType] = useState(null);
    const [actionId, setActionId] = useState(null);
    const [countrySearch, setCountrySearch] = useState('');
    const [stateSearch, setStateSearch] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCountries();
    }, []);

    const fetchCountries = async () => {
        setLoading(true);
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            setLoading(false);
            return;
        }
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'ngrok-skip-browser-warning': '69420',
            }
        };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`, apiHeaders);
            setCountries(response.data.data || []);
            setCurrentPage(1);
        } catch (error) {
            console.error('Error fetching countries:', error);
            toast.error('An error occurred while fetching countries.');
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmAction = async () => {
        setLoading(true);
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            setLoading(false);
            return;
        }
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'ngrok-skip-browser-warning': '69420',
            }
        };
        try {
            if (actionType === 'country') {
                const country = countries.find(country => country.hash_id === actionId);
                const isActive = country.is_active;
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/admin/countries/${isActive === 1 ? 'disable' : 'enable'}/${actionId}`,
                    apiHeaders
                );
                if (response.data.status === 'error') {
                    toast.error(response.data.message);
                } else {
                    toast.success(`Country ${isActive === 1 ? 'disabled' : 'enabled'} successfully.`);
                    setCountries(prevCountries =>
                        prevCountries.map(country =>
                            country.hash_id === actionId
                                ? { ...country, is_active: isActive === 1 ? 0 : 1 }
                                : country
                        )
                    );
                }
            } else if (actionType === 'state') {
                const state = states.find(state => state.hash_id === actionId);
                const isActive = state.is_active;
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/admin/states/${isActive === 1 ? 'disable' : 'enable'}/${actionId}`,
                    apiHeaders
                );
                if (response.data.status === 'error') {
                    toast.error(response.data.message);
                } else {
                    toast.success(`State ${isActive === 1 ? 'disabled' : 'enabled'} successfully.`);
                    setStates(prevStates =>
                        prevStates.map(state =>
                            state.hash_id === actionId
                                ? { ...state, is_active: isActive === 1 ? 0 : 1 }
                                : state
                        )
                    );
                }
                fetchCountries();
            }
        } catch (error) {
            console.error('Error performing action:', error);
            toast.error('An error occurred while performing the action.');
        } finally {
            setLoading(false);
        }
        setShowConfirmModal(false);
    };

    const handleToggleCountry = (hash_id, e) => {
        e.stopPropagation();
        setActionType('country');
        setActionId(hash_id);
        setShowConfirmModal(true);
    };

    const handleCountryClick = async (hash_id) => {
        setLoading(true);
        setSelectedCountry(hash_id);
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            setLoading(false);
            return;
        }
        const apiHeaders = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'ngrok-skip-browser-warning': '69420',
            }
        };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/states/${hash_id}`, apiHeaders);
            setStates(response.data.data || []);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching states:', error);
            toast.error('An error occurred while fetching states.');
        } finally {
            setLoading(false);
        }
    };

    const handleToggleState = (hash_id) => {
        setActionType('state');
        setActionId(hash_id);
        setShowConfirmModal(true);
    };

    const filteredCountries = countries.filter(country =>
        country.name.toLowerCase().includes(countrySearch.toLowerCase())
    );
    const filteredStates = states.filter(state =>
        state.name.toLowerCase().includes(stateSearch.toLowerCase())
    );

    const indexOfLastCountry = currentPage * countriesPerPage;
    const indexOfFirstCountry = indexOfLastCountry - countriesPerPage;
    const currentCountries = filteredCountries.slice(indexOfFirstCountry, indexOfLastCountry);
    const totalPages = Math.ceil(filteredCountries.length / countriesPerPage);

    return (
        <div className='container m-0 p-3'>
            <h2>Country List</h2>
            <Form.Group controlId="countrySearch">
                <Form.Control
                    type="text"
                    placeholder="Search countries..."
                    value={countrySearch}
                    onChange={(e) => setCountrySearch(e.target.value)}
                    className='mb-5'
                />
            </Form.Group>
            <CountryTable
                countries={currentCountries}
                onCountryClick={handleCountryClick}
                onToggleCountry={handleToggleCountry}
                loading={loading}
            />
            <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
            />
            <StatesModal
                showModal={showModal}
                handleClose={() => setShowModal(false)}
                states={filteredStates}
                handleToggleState={handleToggleState}
                loading={loading}
                selectedCountry={countries.find(c => c.hash_id === selectedCountry)}
                stateSearch={stateSearch}
                setStateSearch={setStateSearch}
            />
            <ConfirmationModal
                showModal={showConfirmModal}
                handleClose={() => setShowConfirmModal(false)}
                handleConfirm={handleConfirmAction}
                actionType={actionType}
                item={actionType === 'country' ? countries.find(c => c.hash_id === actionId) : states.find(s => s.hash_id === actionId)}
            />
            <ToastContainer />
        </div>
    );
};

export default AdminCountryList;
