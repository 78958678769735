import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ViewModal = ({ show, onHide, config }) => {
    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>View Configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {config ? (
                    <div>
                        <p className='text-black'><strong>Key:</strong> {config.key}</p>
                        <p className='text-black'><strong>Value:</strong> {config.value}</p>
                    </div>
                ) : (
                    <p>No configuration selected.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewModal;
