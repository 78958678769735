import React, { useState } from "react";
import { RingLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faThLarge,
  faTh,
  faShippingFast,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import JifImg from "../../../assets/images/Auction.png";
import "../WatchList/MaiData/MainData.css";

const REACT_APP_BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;

const CurrentBids = ({ data = {}, isLoading }) => {
  const [sortOption, setSortOption] = useState("Sale Order");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [view, setView] = useState("list");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const handleSortOptionChange = (e) => {
    setSortOption(e.target.value);
  };
  const handleSortDirectionChange = (e) => {
    setSortDirection(e.target.value);
  };
  const handleViewChange = (viewType) => {
    setView(viewType);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const isDataArray = Array.isArray(data?.data);
  const dataArray = isDataArray ? data.data : [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = dataArray.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(dataArray.length / itemsPerPage);

  const renderItem = (item) => {
    const hasImage = item?.lot?.images && item?.lot?.images?.length > 0;
    const imageSrc = hasImage
      ? `${REACT_APP_BASE_IMAGE_URL}${item?.lot?.images[0]?.image_path}`
      : JifImg;
    const lotNumberTitle = `${item?.lot?.lot_number} | ${item?.lot?.title}`;
    const bidAmount = item.amount ? `$${item.amount}` : "Outbid";
    const winningAmount = item?.status === "Winning" ? `$${item?.amount}` : "";
    const shippingStatus = item?.lot?.auction?.shipping_option
      ?.is_shipping_available ? (
      <FontAwesomeIcon icon={faShippingFast} />
    ) : (
      <FontAwesomeIcon icon={faBan} />
    );
    return (
      <div className="data-item mobie-screen-data flex items-center justify-between gap-2 w-full">

        <div className="image-column w-1/6 currents-bids-images">
          <img src={imageSrc} alt="Lot Image" className="hover:scale-105" />
        </div>

        <div className="description-column px-3 w-1/6 current-bids-data-watchlist">
          <p>{lotNumberTitle}</p>
        </div>
        <div className="date-column flex items-center justify-center flex-col w-1/6">
          {/* <p className="m-0">
            {new Date(item?.created_at).toLocaleDateString()}
          </p> */}
          <p className="m-0 text-center">
            {(() => {
              const timeLeft =
                new Date(item?.lot?.auction?.end_date) - new Date();
              const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
              return daysLeft > 0 ? `${daysLeft} days left` : "Closed";
            })()}
          </p>
        </div>
        <div className="amount-column flex items-center justify-center w-1/6">
          <p>
            Amount:{" "}
            <span>
              {winningAmount} {bidAmount}
            </span>
          </p>
        </div>
        <div className="shipping-column flex items-center justify-center w-1/6 shipping-current-bids">
          <p> Shipping :  {shippingStatus}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="main-data">
      <div className="row">
        <div className="col-md-12">
          <div className="sort-container">
            <div className="sort-options flex items-center justify-between w-full">
              <div className="flex items-center justify-start gap-2">
                <label style={{ paddingRight: "50px" }}>
                  Sort: &nbsp;
                  <select
                    value={sortOption}
                    onChange={handleSortOptionChange}
                    className="sort-dropdown"
                  >
                    <option value="Sale Order">Sale Order</option>
                    <option value="Lot#">Lot</option>
                    <option value="Bid History">Bid History</option>
                    <option value="Time Remaining">Time Remaining</option>
                    <option value="High Bid">High Bid</option>
                    <option value="Max Bid">Max Bid</option>
                    <option value="Bid Status">Bid Status</option>
                  </select>
                </label>
              </div>
              <div className="flex items-center justify-center gap-2">
                <button
                  className={`view-button ${view === "list" ? "active" : ""}`}
                  onClick={() => handleViewChange("list")}
                >
                  <FontAwesomeIcon icon={faList} />
                </button>
                <button
                  className={`view-button ${view === "grid-2" ? "active" : ""}`}
                  onClick={() => handleViewChange("grid-2")}
                >
                  <FontAwesomeIcon icon={faThLarge} />
                </button>
                <button
                  className={`view-button ${view === "grid-12" ? "active" : ""
                    }`}
                  onClick={() => handleViewChange("grid-12")}
                >
                  <FontAwesomeIcon icon={faTh} />
                </button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="loader-container">
              <RingLoader color={"#36D7B7"} size={60} />
            </div>
          ) : (
            <>
              <div className={`row pt-3 ${view} gap-y-4`}>
                {currentData && currentData.length > 0 ? (
                  currentData.map((item, index) => (
                    <div
                      key={index}
                      className={`col-md-${view === "grid-2"
                        ? "3"
                        : view === "grid-12"
                          ? "12"
                          : "12"
                        } flex items-center justify-between flex-col`}
                    >
                      {view === "list" && (
                        <div className="data-box-list border">
                          {renderItem(item)}
                        </div>
                      )}
                      {view === "grid-2" && (
                        <div className="data-box-grid-2 ">
                          <div className="item-header flex items-start justify-start w-full">
                            <p className="font-semibold m-0">
                              {item?.lot?.lot_number} | &nbsp;
                            </p>
                            <p className="font-semibold m-0">
                              {item?.lot?.title}
                            </p>
                          </div>
                          <div className="watchbids-image w-full h-full flex items-center justify-center">
                            <img
                              src={
                                item?.lot?.images[0]?.image_path
                                  ? `${process.env.REACT_APP_BASE_IMAGE_URL}${item?.lot?.images[0]?.image_path}`
                                  : JifImg
                              }
                              alt="Lot Image"
                              className="w-full min-h-[149px] min-w-[249px] max-h-[150px] max-w-[250px] object-cover hover:scale-105 "
                            />
                          </div>
                          <div className="item-details flex items-center justify-center flex-col">
                            <p className="flex items-center justify-center">
                              {item.status === "Winning"
                                ? `Winning Amount: $${item?.amount}`
                                : `Outbid: $${item?.amount}`}
                            </p>
                            {/* <p>Bids : </p> */}
                            <p className="m-0 text-center">
                              {new Date(
                                item?.lot?.auction?.start_date
                              ).toLocaleDateString()}{" "}
                              -
                              {new Date(
                                item?.lot?.auction?.end_date
                              ).toLocaleDateString()}
                            </p>
                            <p className="m-0 text-center">
                              {(() => {
                                const timeLeft =
                                  new Date(item?.lot?.auction?.end_date) -
                                  new Date();
                                const daysLeft = Math.floor(
                                  timeLeft / (1000 * 60 * 60 * 24)
                                );
                                return daysLeft > 0
                                  ? `${daysLeft} days left`
                                  : "Closed";
                              })()}
                            </p>

                            <p className="flex items-center justify-center gap-1">
                              Shipping  {" "}
                              {item?.lot?.auction?.shipping_option
                                ?.is_shipping_available ? (
                                <FontAwesomeIcon icon={faShippingFast} />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faBan}
                                  className="flex items-center justify-center"
                                />
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                      {view === "grid-12" && (
                        <div className="data-box-grid-12 mb-3 border rounded-xl flex items-center justify-between w-full flex-col overflow-hidden">
                          <div className="flex items-center justify-between w-full bg-gray-200 py-2 px-3 rounded-t-xl single-view-accept">
                            <div className="item-header flex items-center justify-center ">
                              <p className="font-semibold m-0">
                                {item?.lot?.lot_number} | {item?.lot?.title}
                              </p>
                            </div>

                            <p className="pt-3 text-center underline font-semibold">
                              {item?.status === "Winning"
                                ? "Winning"
                                : "Not Acceptable"}
                            </p>
                          </div>
                          <div className="flex items-center justify-between gap-5 px-3 py-2 w-full mobile-single-view">
                            <div className="detail-column w-full h-full flex items-center justify-center w-1/5 ">
                              <img
                                src={
                                  item?.lot?.images[0]?.image_path
                                    ? `${process.env.REACT_APP_BASE_IMAGE_URL}${item?.lot?.images[0]?.image_path}`
                                    : JifImg
                                }
                                alt="Lot Image"
                                className="w-full min-h-[250px] min-w-[250px] max-h-[150px] max-w-[340px] object-cover hover:scale-105"
                              />
                            </div>
                            <div className="flex items-center justify-start gap-3 w-3/5 h-full mobile-single-description">
                              <div className="detail-column flex items-center justify-center flex-col w-3/5 mobile-singledesc-data">
                                <p className="ellipsis">
                                  {item?.lot?.description}
                                </p>
                              </div>
                              <div className="flex items-center justify-center flex-col w-2/5 h-full mobile-singledesc-data ">
                                <div className="detail-column flex items-center justify-center flex-col gap-0 ">
                                  <p className=" m-0">
                                    <p>
                                      {" "}
                                      <span className="font-semibold">
                                        Estimate :
                                      </span>{" "}
                                      {item?.lot?.estimate_from}-{" "}
                                      {item?.lot?.estimate_to}
                                    </p>
                                    <span className="font-semibold">Price : </span>{item?.amount}                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center justify-center flex-col w-1/5 px-3 h-full mobile-singledesc-border border-l border-gray-400 mobile-singledesc-data ">
                              <p className="m-0 gap-2 flex items-center justify-center flex-col">
                                <p className="m-0">
                                  {" "}
                                  {item?.lot?.auction?.name}
                                </p>
                                <p className=" m-0">
                                  {" "}
                                  {item?.lot?.auction?.city}
                                </p>
                                <p className="m-0 text-center">
                                  {new Date(
                                    item?.lot?.auction?.start_date
                                  ).toLocaleDateString()}{" "}
                                  -
                                  {new Date(
                                    item?.lot?.auction?.end_date
                                  ).toLocaleDateString()}
                                </p>
                                <p className="m-0 text-center">
                                  {(() => {
                                    const timeLeft =
                                      new Date(item?.lot?.auction?.end_date) -
                                      new Date();
                                    const daysLeft = Math.floor(
                                      timeLeft / (1000 * 60 * 60 * 24)
                                    );
                                    return daysLeft > 0
                                      ? `${daysLeft} days left`
                                      : "Closed";
                                  })()}
                                </p>
                              </p>

                              <p className="m-0">
                                Shipping:{" "}
                                {item?.lot?.auction?.shipping_option
                                  ?.is_shipping_available ? (
                                  <FontAwesomeIcon icon={faShippingFast} />
                                ) : (
                                  <FontAwesomeIcon icon={faBan} />
                                )}
                              </p>

                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="col-md-12 w-full flex items-center justify-center py-2">
                    <p className="m-0 font-2xl">No data found</p>
                  </div>
                )}
              </div>
              <div className="pagination-controls">
                <button
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i + 1}
                    className={`pagination-number ${currentPage === i + 1 ? "active" : ""
                      }`}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CurrentBids;
