import React from 'react';
import "./Home.css";
import Category from './Category';
import Picks from './Picks';
import Auctions from './Auctions';
import Search from './Search';

const Home = () => {
    return (
        <div className='home'>
            <Search />
            <Category />
            <Picks />
            <Auctions />
        </div>
    )
}

export default Home;
