import React, { useState } from 'react';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
    const [isAuctionsOpen, setIsAuctionsOpen] = useState(false);
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [hideClosed, setHideClosed] = useState(false);
    const [groupByAuction, setGroupByAuction] = useState(false);

    const handleToggleAuctions = () => {
        setIsAuctionsOpen(!isAuctionsOpen);
    };

    const handleToggleStatus = () => {
        setIsStatusOpen(!isStatusOpen);
    };

    const handleStatusChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    return (
        <div className="sidebars">
            <button className="refresh-btns">
                <FontAwesomeIcon icon={faSyncAlt} className="refresh-icon px-3" />
                Refresh
            </button>
            <div className="section">
                <div className="section-headers" onClick={handleToggleAuctions}>
                    <span>Auctions</span>
                    <FontAwesomeIcon
                        icon={isAuctionsOpen ? faChevronUp : faChevronDown}
                        className="toggle-icons"
                    />
                </div>
                {isAuctionsOpen && (
                    <div className="section-content">
                        <select className="auction-select">
                            <option>Select All</option>

                        </select>
                    </div>
                )}
            </div>
            <div className="section pt-3">
                <div className="section-headers" onClick={handleToggleStatus}>
                    <span>Status</span>
                    <FontAwesomeIcon
                        icon={isStatusOpen ? faChevronUp : faChevronDown}
                        className="toggle-icons"
                    />
                </div>
                {isStatusOpen && (
                    <div className="section-content">
                        <div className="radio-group">
                            <label>
                                <input
                                    type="radio"
                                    value="All"
                                    checked={selectedStatus === 'All'}
                                    onChange={handleStatusChange}
                                />
                                All
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Only Watching"
                                    checked={selectedStatus === 'Only Watching'}
                                    onChange={handleStatusChange}
                                />
                                Only Watching
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Winning"
                                    checked={selectedStatus === 'Winning'}
                                    onChange={handleStatusChange}
                                />
                                Winning
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Pending"
                                    checked={selectedStatus === 'Pending'}
                                    onChange={handleStatusChange}
                                />
                                Pending
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Outbid"
                                    checked={selectedStatus === 'Outbid'}
                                    onChange={handleStatusChange}
                                />
                                Outbid
                            </label>
                        </div>
                        <div className="checkbox-group">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={hideClosed}
                                    onChange={() => setHideClosed(!hideClosed)}
                                />
                                Hide Closed Bid
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={groupByAuction}
                                    onChange={() => setGroupByAuction(!groupByAuction)}
                                />
                                Group by Auction
                            </label>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
