// import React, { useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import Avatar from 'react-avatar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
// import './SuperAdminHeader.css';
// import { useAuth } from '../../../AuthContext';

// const SuperAdminHeader = () => {
//     const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//     const username = localStorage.getItem('username');
//     const navigate = useNavigate();
//     const { logout } = useAuth();

//     const handleNavigateToProfile = () => {
//         navigate('/');
//     };

//     const handleLogout = () => {
//         logout();
//         navigate('/');
//     };

//     const handleNavigateToSignIn = () => {
//         navigate('/authentication/sign-in');
//     };

//     const toggleSubmenu = () => {
//         setIsSubmenuOpen(!isSubmenuOpen);
//     };

//     const toggleSidebar = () => {
//         setIsSidebarOpen(!isSidebarOpen);
//     };

//     return (
//         <div>
//             <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
//                 {isSidebarOpen ? 'Close Sidebar' : 'Open Sidebar'}
//             </button>
//             <div className={`super-admin-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
//                 {isSidebarOpen && (
//                     <button className="close-sidebar-btn" onClick={toggleSidebar}>X</button>
//                 )}

//                 <div className='sidebar-content flex flex-col gap-3'>
//                     <div className='d-flex align-items-center justify-center'>
//                         <div className='ml-2'>
//                             {username ? (
//                                 <p className='log text-uppercase mb-0 text-2xl' onClick={handleNavigateToProfile}>
//                                     <b className='px-2'>{username}</b>
//                                 </p>
//                             ) : (
//                                 <p className='login-bidder text-uppercase pb-3' onClick={handleNavigateToSignIn}>
//                                     <b style={{ color: "black" }}>Login / New Bidder</b>
//                                 </p>
//                             )}
//                         </div>
//                     </div>

//                     <nav className='sidebar-nav'>
//                         <ul className='nav flex-column'>
//                             <li className='nav-item px-2'>
//                                 <NavLink
//                                     to="/super/admin/dashboard"
//                                     className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                 >
//                                     Home
//                                 </NavLink>
//                             </li>
//                             <li className='nav-item px-2'>
//                                 <NavLink
//                                     to="/super/admin/country-list"
//                                     className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                 >
//                                     Country List
//                                 </NavLink>
//                             </li>
//                             <li className='nav-item px-2'>
//                                 <div className='nav-link' onClick={toggleSubmenu}>
//                                     Email Settings
//                                     <FontAwesomeIcon
//                                         icon={isSubmenuOpen ? faChevronUp : faChevronDown}
//                                         className='submenu-arrow'
//                                     />
//                                 </div>
//                                 {isSubmenuOpen && (
//                                     <ul className='nav flex-column pl-3'>
//                                         <li className='nav-item'>
//                                             <NavLink
//                                                 to="/super/admin/email-setting"
//                                                 className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                             >
//                                                 Email Settings
//                                             </NavLink>
//                                         </li>
//                                         <li className='nav-item'>
//                                             <NavLink
//                                                 to="/super/admin/view-email-list"
//                                                 className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                             >
//                                                 Email List
//                                             </NavLink>
//                                         </li>
//                                     </ul>
//                                 )}
//                             </li>
//                             <li className='nav-item px-2'>
//                                 <NavLink
//                                     to="/super/admin/bidding-types"
//                                     className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                 >
//                                     Bidding List
//                                 </NavLink>
//                             </li>
//                             <li className='nav-item px-2'>
//                                 <NavLink
//                                     to="/super/admin/configuration"
//                                     className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                 >
//                                     Configuration
//                                 </NavLink>
//                             </li>
//                             <li className='nav-item px-2'>
//                                 <NavLink
//                                     to="/super/admin/categories"
//                                     className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                 >
//                                     Category
//                                 </NavLink>
//                             </li>
//                             <li className='nav-item px-2' onClick={handleLogout}>
//                                 <NavLink
//                                     to="/"
//                                     className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                 >
//                                     Logout
//                                 </NavLink>
//                             </li>
//                         </ul>
//                     </nav>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SuperAdminHeader;
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './SuperAdminHeader.css';
import { useAuth } from '../../../AuthContext';

const SuperAdminHeader = () => {
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const username = localStorage.getItem('username');
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleNavigateToProfile = () => {
        navigate('/');
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleNavigateToSignIn = () => {
        navigate('/authentication/sign-in');
    };

    const toggleSubmenu = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div>
            <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
            </button>
            <div className={`super-admin-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
                {isSidebarOpen && (
                    <button className="close-sidebar-btn" onClick={toggleSidebar}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                )}

                <div className='sidebar-content flex flex-col gap-3'>
                    <div className='d-flex align-items-center justify-center'>
                        <div className='ml-2'>
                            {username ? (
                                <p className='log text-uppercase mb-0 text-2xl' onClick={handleNavigateToProfile}>
                                    <b className='px-2'>{username}</b>
                                </p>
                            ) : (
                                <p className='login-bidder text-uppercase pb-3' onClick={handleNavigateToSignIn}>
                                    <b style={{ color: "black" }}>Login / New Bidder</b>
                                </p>
                            )}
                        </div>
                    </div>

                    <nav className='sidebar-nav'>
                        <ul className='nav flex-column'>
                            <li className='nav-item px-2'>
                                <NavLink
                                    to="/super/admin/dashboard"
                                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className='nav-item px-2'>
                                <NavLink
                                    to="/super/admin/country-list"
                                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                >
                                    Country List
                                </NavLink>
                            </li>
                            <li className='nav-item px-2'>
                                <div className='nav-link' onClick={toggleSubmenu}>
                                    Email Settings
                                    <FontAwesomeIcon
                                        icon={isSubmenuOpen ? faChevronUp : faChevronDown}
                                        className='submenu-arrow'
                                    />
                                </div>
                                {isSubmenuOpen && (
                                    <ul className='nav flex-column pl-3'>
                                        <li className='nav-item'>
                                            <NavLink
                                                to="/super/admin/email-setting"
                                                className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                            >
                                                Email Settings
                                            </NavLink>
                                        </li>
                                        <li className='nav-item'>
                                            <NavLink
                                                to="/super/admin/view-email-list"
                                                className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                            >
                                                Email List
                                            </NavLink>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li className='nav-item px-2'>
                                <NavLink
                                    to="/super/admin/bidding-types"
                                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                >
                                    Bidding List
                                </NavLink>
                            </li>
                            <li className='nav-item px-2'>
                                <NavLink
                                    to="/super/admin/configuration"
                                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                >
                                    Configuration
                                </NavLink>
                            </li>
                            <li className='nav-item px-2'>
                                <NavLink
                                    to="/super/admin/categories"
                                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                >
                                    Category
                                </NavLink>
                            </li>
                            <li className='nav-item px-2' onClick={handleLogout}>
                                <NavLink
                                    to="/"
                                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                >
                                    Logout
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default SuperAdminHeader;
