import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const MapEvents = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
                    headers: {
                        "ngrok-skip-browser-warning": "69420"
                    }
                });
                setCategories(response.data.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    return (
        <div className='map-events mb-5 mt-3'>
            <div className='container'>
                <div className='row event-lots align-items-center mt-3'>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <select className='form-control'>
                                <option>50 miles</option>
                                <option>100 miles</option>
                                <option>200 miles</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <input type='text' className='form-control event-zip-code' placeholder='Enter ZIP code' />
                        </div>
                    </div>
                    <div className='col-md-4 d-flex mb-3'>
                        <div className='input-group'>
                            <input type='text' className='form-control event-zip-code' placeholder='Search...' />
                        </div>
                        <div className='mx-2'>
                            <button className='btn btn-outline-secondary event-search' type='button'>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </div>
                    </div>
                    <div className='col-md-2 d-flex justify-content-end'>
                        <div className='toggle-icon' onClick={() => setIsOpen(!isOpen)}>
                            <FontAwesomeIcon icon={faBars} />
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <div className='row mt-3'>
                        <div className='col-md-3'>
                            <div className='form-group'>
                                <select className='form-control' value={selectedCategory} onChange={handleCategoryChange}>
                                    {categories.map(category => (
                                        <option key={category.hash_id} value={category.hash_id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='form-group'>
                                <select className='form-control'>
                                    <option>Select all...</option>
                                    <option>All 1</option>
                                    <option>All 2</option>
                                    <option>All 3</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='form-group'>
                                <select className='form-control'>
                                    <option>Select open...</option>
                                    <option>Open 1</option>
                                    <option>Open 2</option>
                                    <option>Open 3</option>
                                </select>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MapEvents;
