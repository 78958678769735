import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './MainDiv.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { RingLoader } from 'react-spinners';

const MainDiv = ({ searchTerm, selectedLocation }) => {
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [companySortIcon, setCompanySortIcon] = useState('fa-arrow-up');
    const [locationSortIcon, setLocationSortIcon] = useState('fa-arrows-up-down');
    const [companiesData, setCompaniesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCompanies = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/company`, {
                    headers: {
                        'ngrok-skip-browser-warning': '69420'
                    }
                });
                const { data } = response.data;
                setCompaniesData(data);
            } catch (error) {
                setError('Error fetching data');
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchCompanies();
    }, []);

    const filteredAndSortedCompanies = () => {
        if (!Array.isArray(companiesData)) return [];
        let filteredCompanies = [...companiesData];
        if (searchTerm) {
            filteredCompanies = filteredCompanies.filter(company =>
                company.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
                company.address.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        if (selectedLocation && selectedLocation !== 'All location') {
            filteredCompanies = filteredCompanies.filter(company =>
                company.country.hash_id === selectedLocation
            );
        }
        if (sortBy) {
            filteredCompanies.sort((a, b) => {
                if (sortBy === 'name') {
                    return sortOrder === 'asc' ? a.company.localeCompare(b.company) : b.company.localeCompare(a.company);
                } else if (sortBy === 'location') {
                    return sortOrder === 'asc' ? a.address.localeCompare(b.address) : b.address.localeCompare(a.address);
                }
                return 0;
            });
        }
        return filteredCompanies;
    };

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(currentPage * pageSize, filteredAndSortedCompanies().length);

    const handleSort = (column) => {
        if (column === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
        updateSortIcons(column);
    };

    const updateSortIcons = (column) => {
        if (column === 'name') {
            setCompanySortIcon(sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down');
            setLocationSortIcon('fa-arrows-up-down');
        } else if (column === 'location') {
            setLocationSortIcon(sortOrder === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down');
            setCompanySortIcon('fa-arrow-up-wide-short');
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    const handlePreviousClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < Math.ceil(filteredAndSortedCompanies().length / pageSize)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageSizeChange = (event) => {
        const size = parseInt(event.target.value, 10);
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleCompanyClick = (id) => {
        navigate(`/companysearch/${id}`);
    };

    if (loading) {
        return (
            <div className="spinner-container">
                <RingLoader color="#007bff" loading={loading} size={60} />
            </div>
        );
    }
    if (error) return <p>{error}</p>;

    const companiesToDisplay = filteredAndSortedCompanies().slice(startIndex, endIndex);
    const totalCompanies = filteredAndSortedCompanies().length;
    const totalPages = Math.ceil(totalCompanies / pageSize);

    return (
        <div className='main-div'>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='d-flex'>
                        <p className='mt-3 show'>Show:</p>
                        <div className='dropdown companies'>
                            <select className='show-dropdown' onChange={handlePageSizeChange} value={pageSize}>
                                <option value='1'>1 company</option>
                                <option value='5'>5 companies</option>
                                <option value='10'>10 companies</option>
                                <option value='25'>25 companies</option>
                                <option value='50'>50 companies</option>
                                <option value='100'>100 companies</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <p className='showing-page-item'>
                            Showing {startIndex + 1} to {endIndex} of {totalCompanies} companies
                        </p>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='pagination-container'>
                        {currentPage > 1 && (
                            <button
                                className='pagination-button previous'
                                onClick={handlePreviousClick}
                                style={{ borderRadius: '10px 0px 0px 10px', padding: "12px 18px" }}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                        )}
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index + 1}
                                className={`pagination-button ${currentPage === index + 1 ? 'selected' : ''}`}
                                onClick={() => handlePageClick(index + 1)}
                                style={{
                                    borderRadius: (currentPage === 1 && index === 0) ? '10px 0px 0px 10px' :
                                        (currentPage === totalPages && index === totalPages - 1) ? '0px 10px 10px 0px' : '0px'
                                }}
                            >
                                {index + 1}
                            </button>
                        ))}
                        {currentPage < totalPages && (
                            <button
                                className='pagination-button next'
                                onClick={handleNextClick}
                                style={{ borderRadius: '0px 10px 10px 0px', padding: "12px 18px" }}
                            >
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <table className='company-table'>
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('name')}>
                                    <i className={`fas ${sortBy === 'name' ? companySortIcon : 'fa-arrow-up'}`}></i>
                                    <span className='mx-2'>Company</span>
                                </th>
                                <th onClick={() => handleSort('location')}>
                                    <i className={`fas ${sortBy === 'location' ? locationSortIcon : 'fa-arrows-up-down'}`}></i>
                                    <span className='mx-2'>Location</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {companiesToDisplay.map(company => (
                                <tr key={company.id} className='table-row'>
                                    <td onClick={() => handleCompanyClick(company.id)} className="clickable">
                                        {company.company}
                                    </td>
                                    <td>{company.address}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className='pagination-container'>
                    {currentPage > 1 && (
                        <button
                            className='pagination-button previous'
                            onClick={handlePreviousClick}
                            style={{ borderRadius: '10px 0px 0px 10px', padding: "12px 18px" }}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                    )}
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index + 1}
                            className={`pagination-button ${currentPage === index + 1 ? 'selected' : ''}`}
                            onClick={() => handlePageClick(index + 1)}
                            style={{
                                borderRadius: (currentPage === 1 && index === 0) ? '10px 0px 0px 10px' :
                                    (currentPage === totalPages && index === totalPages - 1) ? '0px 10px 10px 0px' : '0px'
                            }}
                        >
                            {index + 1}
                        </button>
                    ))}
                    {currentPage < totalPages && (
                        <button
                            className='pagination-button next'
                            onClick={handleNextClick}
                            style={{ borderRadius: '0px 10px 10px 0px', padding: "12px 18px" }}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainDiv;
