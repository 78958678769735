import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import "./AuctionDetail.css";
import JifImg from '../../../assets/images/Woodburn1.png';
import Magnifier from 'react-magnifier';

const AuctionDetail = () => {
    const { hash_id } = useParams();
    const [auctionData, setAuctionData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAuctionData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/get/auctions/${hash_id}`);
                setAuctionData(response.data.auction);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAuctionData();
    }, [hash_id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!auctionData) {
        return <div>No auction data found</div>;
    }

    const lots = auctionData.lots || [];

    const firstSixLots = lots.slice(0, 6);
    const remainingLots = lots.slice(6);

    return (
        <div className='container pt-3'>
            <div className='row'>
                <div className='col-md-12'>

                    <div className='lots-row'>
                        {firstSixLots.map(lot => (
                            <div className='lot-item' key={lot.hash_id}>
                                <Link to={`/lots/${lot.hash_id}`} style={{ textDecoration: "none" }}>
                                    <div className='lot-title text-black'>{lot.lot_number} | {lot.title}</div>


                                    <Magnifier src={lot?.images[0] ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${lot.images[0].image_path}` : JifImg}
                                        alt={lot.title} />

                                    {/* <img
                                        src={lot.images[0] ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${lot.images[0].image_path}` : JifImg}
                                        alt={lot.title}
                                        className='lot-image'
                                    /> */}
                                    <div className='lot-details'>

                                        <p className='text-black'>High Bid: {lot.highest_bidder?.status === "Winning" ? lot.highest_bidder.amount : '0'}</p>
                                        {lot?.estimate_from && lot?.estimate_to && (
                                            <p className='text-black'>Estimate: {lot.estimate_from} - {lot.estimate_to}</p>
                                        )}
                                        <p className='text-black'>Bids: {lot.bids.length > 0 ? lot.bids.length : 0}</p>
                                        <button className='bid-button'>View Detail</button>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    {remainingLots.length > 0 && (
                        <div className='lots-box'>
                            {remainingLots.map(lot => (
                                <div className='lot-box-item' key={lot.hash_id}>
                                    <Link to={`/lots/${lot.hash_id}`}>
                                        <div className='lot-box-title'>
                                            <p>Lot Number: {lot.lot_number}</p>
                                            <p>{lot.title}</p>
                                        </div>
                                        <img
                                            src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${lot.images[0]}`}
                                            alt={lot.title}
                                            className='lot-box-image'
                                        />
                                        <div className='lot-box-details'>



                                            <p>High Bid: {lot.high_bid || 'N/A'}</p>

                                            <p> Winning Value: {lot.winning_value || 'N/A'}</p>
                                            <button className='bid-button'>Place Bid</button>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default AuctionDetail;
