import React from 'react';
import MapNear from "../../../assets/images/nearby-auctions.gif";
import Screenshot from "../../../assets/images/find-auctions.gif";
import Videos from "../../../assets/images/HiBid-SearchByState.mp4";
import "./Help.css"

const Help = () => {
    window.scroll(0, 0);
    return (
        <div className='container-fluid'>
            <div className='row pt-5'>
                <h3 className='px-5 text-start' style={{ color: '#2695ff' }}>Auctions HELP</h3>
                <p className="text-start pt-3 px-5" >Common questions are answered below and on our FAQ Page. If you have a concern about a specific item or transaction, please contact the seller. If you need additional help using Auctions, call 844-775-4774.
                </p>
                <h5 className='text-start px-5 pt-3'><b>Auction Flex Help</b> </h5>
                <p className="text-start  px-5" >If you’re an auction company or auctioneer requiring help with an auction, please visit the Auction Flex Live Help page to start a chat session.
                </p>
                <h5 className='text-start px-5 pt-1'><b>Start Selling</b> </h5>
                <p className="text-start  px-5" >Whether you’re an auctioneer seeking to upload lots and hold auctions through Auctions or an individual seller looking to consign items for sale, we make the entire process as easy as possible.
                </p>
                <div className="about-hibis-text px-5 pt-2 ">
                    <button className='about-hibis-text-btn' >Start Selling Today</button>
                </div>
                <h5 className='text-start px-5 pt-3'><b>Make Your Auction A Featured Auction</b>
                </h5>
                <p className="text-start  px-5" >
                    Featured Auctions are the first sales you’ll see at the top of the Auctions homepage and on Auctions's state, province, and country portal sites.
                </p>
                <p className="text-start  px-5 pt-4" >
                    Every auctioneer has the opportunity to bid on one of the three top spots by 1 p.m. ET each Monday. Featured Auctions also appear at the top of Auctions's weekly email newsletter, providing extra exposure for those sales to Auctions bidders everywhere.
                </p>
                <h5 className='text-start px-5 pt-3'><b>Find Auctions Near You</b></h5>
                <p className="text-start  px-5" >At the top of any page on auctionslive.com, click the Find Auctions menu and select All Auctions (Map). On the interactive map, you can zoom and out using the plus and minus sign icons, or click one of the dots to see auctions in a specific area.</p>
                <div className='screenshot-img'>
                    <img src={MapNear} alt='map-location' style={{ width: "100%" }} />
                </div>
                <p className="text-start  px-5 pt-2" >
                    Searching by state, province, or region? Click Auctions By State, choose your area, and select a category. Then, to search ONLY in your area, use the category menu that appears on the left side of the page. To search ALL of Auctions, use the menus at the top of the page. Watch this 40-second video to see how it works.
                </p>
                <div className='col-md-12 '>
                    <div className='video p-4 text-center'>
                        <div className='video'>
                            <iframe width="700" height="400"
                                src={Videos}
                                title="YouTube video player"
                                // frameborder="0"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                // allowfullscreen
                                allowFullScreen
                            >

                            </iframe>
                        </div>
                    </div>
                </div>
                <h5 className='text-start px-5 pt-3'><b>   Find Auctions Closing Soon</b>   </h5>
                <p className="text-start  px-5 " >
                    Under the Find Auctions menu, click Auctions Closing Soon to view sales in progress and about to close. You can further refine your view by choosing between the Auctions, Map, and Lots options at the top right of the screen.
                </p>
                <h5 className='text-start px-5 pt-3'><b>  Hot Auctions & Hot Lots</b>   </h5>
                <p className="text-start  px-5 " >
                    Auctions that have received the most views, bids, and watch list additions are listed as Hot Auctions. At the top of any Auctions page, click the Find Auctions menu and select Hot Auctions to view a current list. You can filter the sales by distance from a ZIP code, product category, auction type and status, and more.
                </p>
                <p className="text-start  px-5 pt-3" >Hot Lots are individual product listings that have generated the most interest in views, bids, and watches. Like Hot Auctions above, you can locate them under the Find Auctions menu and filter them by location, distance, category, and auction type.</p>
                <h5 className='text-start px-5 pt-3'><b> Biddable, Webcast & Online-Only Auctions</b>   </h5>
                <p className="text-start  px-5 " >You can also filter the types of auctions you want to see based on auction type. Click the Find Auctions menu at the top of the page and select one of these options: </p>
                <p className="text-start  px-5 " ><b>Biddable -</b>  <span> All sales with bidding or pre-bidding currently open.</span></p>
                <p className="text-start  px-5 " ><b>Webcast  -</b>  <span> Live sales with both online and in-person floor bidders for an expanded pool of buyers.</span></p>
                <p className="text-start  px-5 " ><b>Online-Only Auction -</b>  <span> Sales conducted entirely online, providing a safe, secure way for bidders to participate remotely.</span></p>
                <div className='screenshot-img'>
                    <img src={Screenshot} alt='screenshot' style={{ width: "100%" }} />
                </div>
                <h5 className='text-start px-5 pt-3'><b> Stay Up-To-Date</b>   </h5>
                <p className="text-start  px-5 pb-5" >HiBid’s once-a-week email newsletter keeps you in the know about the latest auctions without overwhelming your inbox. To sign up for it, simply click the Join Our Mailing List link or menu item at the top of the page. Enter and confirm your email address, then click Subscribe.</p>
            </div>
        </div>
    )
}

export default Help