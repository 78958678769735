// import React, { useState, useCallback } from 'react';
// import axios from 'axios';
// import { RingLoader } from 'react-spinners';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

// const AuctionStatusSelector = ({ selectedAuctionStatus, handleAuctionStatusChange }) => {
//     const [showAuctionStatus, setShowAuctionStatus] = useState(false);
//     const [auctionStatuses, setAuctionStatuses] = useState([]);
//     const [loadingAuctionStatuses, setLoadingAuctionStatuses] = useState(false);
//     const [dataLoaded, setDataLoaded] = useState(false);

//     const fetchAuctionStatuses = useCallback(async () => {
//         setLoadingAuctionStatuses(true);
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}/auctions/status-types`, {
//                 headers: {
//                     "ngrok-skip-browser-warning": "69420"
//                 }
//             });
//             setAuctionStatuses(response.data.data);
//             setDataLoaded(true);
//         } catch (error) {
//             console.error('Error fetching auction statuses:', error);
//         } finally {
//             setLoadingAuctionStatuses(false);
//         }
//     }, []);

//     const toggleAuctionStatus = () => {
//         if (!showAuctionStatus && !dataLoaded) {
//             fetchAuctionStatuses();
//         }
//         setShowAuctionStatus(prevState => !prevState);
//     };

//     return (
//         <>
//             <div className='sidebar-item d-flex lots-status font-bold' onClick={toggleAuctionStatus}>
//                 AUCTION STATUS
//                 <div className='mx-2'>
//                     <FontAwesomeIcon icon={showAuctionStatus ? faAngleUp : faAngleDown} className='toggle-icon quick-search' />
//                 </div>
//             </div>
//             {showAuctionStatus && (
//                 <div className='sidebar-sub-item' onClick={(e) => e.stopPropagation()}>
//                     {loadingAuctionStatuses ? (
//                         <div className='d-flex justify-content-center align-items-center'>
//                             <RingLoader size={60} color={'#123abc'} loading={loadingAuctionStatuses} />
//                         </div>
//                     ) : (
//                         <div className='form-group'>
//                             {auctionStatuses.map(status => (
//                                 <label key={status.hash_id} className='mb-3'>
//                                     <input
//                                         type='radio'
//                                         checked={selectedAuctionStatus === status.name}
//                                         onChange={() => handleAuctionStatusChange(status.name)}
//                                     /> <span className='px-3 font-semibold'>{status.name}</span>
//                                 </label>
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             )}
//         </>
//     );
// };

// export default AuctionStatusSelector;

import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { RingLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const AuctionStatusSelector = ({ selectedAuctionStatus, handleAuctionStatusChange }) => {
    const [showAuctionStatus, setShowAuctionStatus] = useState(false);
    const [auctionStatuses, setAuctionStatuses] = useState([]);
    const [loadingAuctionStatuses, setLoadingAuctionStatuses] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const fetchAuctionStatuses = useCallback(async () => {
        setLoadingAuctionStatuses(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/auctions/status-types`, {
                headers: {
                    "ngrok-skip-browser-warning": "69420"
                }
            });
            setAuctionStatuses(response.data.data);
            setDataLoaded(true);
        } catch (error) {
            console.error('Error fetching auction statuses:', error);
        } finally {
            setLoadingAuctionStatuses(false);
        }
    }, []);

    const toggleAuctionStatus = () => {
        // If closing the dropdown, reset the selected auction status
        if (showAuctionStatus) {
            handleAuctionStatusChange(null);  // or handleAuctionStatusChange('')
        } else if (!dataLoaded) {
            fetchAuctionStatuses();
        }
        setShowAuctionStatus(prevState => !prevState);
    };

    return (
        <>
            <div className='sidebar-item d-flex lots-status font-bold' onClick={toggleAuctionStatus}>
                AUCTION STATUS
                <div className='mx-2'>
                    <FontAwesomeIcon icon={showAuctionStatus ? faAngleUp : faAngleDown} className='toggle-icon quick-search' />
                </div>
            </div>
            {showAuctionStatus && (
                <div className='sidebar-sub-item' onClick={(e) => e.stopPropagation()}>
                    {loadingAuctionStatuses ? (
                        <div className='d-flex justify-content-center align-items-center'>
                            <RingLoader size={60} color={'#123abc'} loading={loadingAuctionStatuses} />
                        </div>
                    ) : (
                        <div className='form-group'>
                            {auctionStatuses.map(status => (
                                <label key={status.hash_id} className='mb-3'>
                                    <input
                                        type='radio'
                                        checked={selectedAuctionStatus === status.name}
                                        onChange={() => handleAuctionStatusChange(status.name)}
                                    /> <span className='px-3 font-semibold'>{status.name}</span>
                                </label>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default AuctionStatusSelector;
