import React, { useState } from 'react';
import { RingLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faThLarge, faTh, faStar, faShippingFast, faTimes, faBan } from '@fortawesome/free-solid-svg-icons';
import JifImg from '../../../assets/images/Auction.png';
import '../WatchList/MaiData/MainData.css';
import './WatchList.css';

const REACT_APP_BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;

const WatchListComponent = ({ data = {}, isLoading }) => {
  const [view, setView] = useState('list');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const [sortOption, setSortOption] = useState('Sale Order');
  const [sortDirection, setSortDirection] = useState('ASC');
  const handleSortOptionChange = (e) => {
    setSortOption(e.target.value);
  };
  const handleSortDirectionChange = (e) => {
    setSortDirection(e.target.value);
  };
  const handleViewChange = (viewType) => {
    setView(viewType);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const isDataArray = Array.isArray(data?.data);
  const dataArray = isDataArray ? data.data : [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = dataArray.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(dataArray.length / itemsPerPage);

  const formatCurrency = (amount) => {
    return `$${parseFloat(amount).toFixed(2)}`;
  };

  const truncateDescription = (description) => {
    return description.length > 80 ? `${description.slice(0, 80)}...` : description;
  };

  const renderItem = (item) => {
    const hasImage = item?.images && item?.images?.length > 0;
    const imageSrc = hasImage ? `${REACT_APP_BASE_IMAGE_URL}${item?.images[0]?.image_path}` : JifImg;
    const lotNumberTitle = `${item?.lot_number} | ${item?.title}`;
    const bidAmount = item?.amount ? formatCurrency(item?.amount) : 'Outbid';
    const shippingIcon = item?.auction?.shipping_option?.is_shipping_available ? faShippingFast : faBan;
    const estimate = `${item?.estimate_from} - ${item?.estimate_to}`;
    const startDate = `${item?.auction?.start_date.split(' ')[0]} - ${item?.auction?.end_date.split(' ')[0]}`;
    const winningAmount = item.highest_bidder?.amount ? parseFloat(item?.highest_bidder?.amount) : 0;
    return (
      <div className={`data-item  border rounded-xl flex items-center justify-between overflow-hidden w-full ${view === 'list' ? 'list-view' : ''}`}>
        <div className=" w-full gap-2 p-3 flex flex-row items-center justify-between watchlist-data">
          <div className=" flex items-center justify-center w-1/6">
            <img src={imageSrc} alt="Lot Image" className="w-full min-h-[149px] min-w-[249px] max-h-[150px] max-w-[250px] object-cover hover:scale-105" />
          </div>
          <div className="col-md-2 px-4 flex items-center justify-center w-1/6 watchlist-data-lot">
            <p >{lotNumberTitle}</p>
          </div>
          <div className="col-md-2 flex items-center justify-center w-1/6 watchlist-data-lot">
            <p> <span className="font-semibold">Bids : </span>{item?.bids?.length}</p>
          </div>
          <div className="col-md-2 flex items-center justify-center flex-col w-1/6 watchlist-data-lot">

            <p className="m-0 text-center">
              {startDate}
            </p>

            <p className="m-0 text-center">
              <span> <span className="font-semibold">Time Left : </span></span>
              <> {(() => {
                const timeLeft =
                  new Date(item?.lot?.auction?.end_date) -
                  new Date();
                const daysLeft = Math.floor(
                  timeLeft / (1000 * 60 * 60 * 24)
                );
                return daysLeft > 0
                  ? `${daysLeft} days left`
                  : "Closed";
              })()}

              </>

            </p>
          </div>
          <div className="col-md-2 flex items-center justify-center w-1/6 watchlist-data-lot">
            <p className='m-0'>High Bid: <span>{formatCurrency(winningAmount)}</span></p>
          </div>
          <div className="col-md-2 flex items-center justify-center w-1/6 watchlist-data-lot ">
            <span className="font-semibold"> Shipping : </span> <FontAwesomeIcon icon={shippingIcon} />
          </div>
        </div>
      </div>
    );
  };

  const renderGridItem = (item) => {
    const hasImage = item?.images && item?.images.length > 0;
    const imageSrc = hasImage ? `${REACT_APP_BASE_IMAGE_URL}${item?.images[0]?.image_path}` : JifImg;
    const lotNumberTitle = `${item?.lot_number} | ${item?.title}`;
    const estimate = `${item?.estimate_from || 0} - ${item?.estimate_to || 0}`;
    const shippingIcon = item?.auction?.shipping_option?.is_shipping_available ? faShippingFast : faBan;
    const winningAmount = item?.highest_bidder?.amount ? parseFloat(item?.highest_bidder?.amount) : 0;
    return (
      <>
        <div className='col-md-3 flex items-center justify-between flex-row gap-3'>
          <div className='data-box-grid-2 flex flex-col items-center justify-between'>
            <div className='item-header flex items-start justify-start w-full'>
              <p className="font-semibold m-0">
                {item?.lot_number} | &nbsp;
              </p>
              <p className="font-semibold m-0">
                {item?.title}
              </p>
            </div>
            <div className=' w-full h-full flex items-center justify-center'>
              <img src={imageSrc} alt="Lot Image" className='w-full min-h-[149px] min-w-[249px] max-h-[150px] max-w-[250px] object-cover hover:scale-105' />
            </div>
            <div className='item-details flex items-center justify-center flex-col'>
              <p> <span className="font-semibold">High Bid : </span>{formatCurrency(winningAmount)}</p>
              <p> <span className="font-semibold">Total Bids : </span>{item?.bids?.length}</p>
              <p> <span className="font-semibold">Estimate : </span>{estimate}</p>
              <p className='flex items-center justify-center gap-1'>
                <span className="font-semibold">Shipping : </span> <FontAwesomeIcon icon={shippingIcon} /> <br />
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderGrid12Item = (item) => {
    const hasImage = item?.images && item?.images?.length > 0;
    const imageSrc = hasImage ? `${REACT_APP_BASE_IMAGE_URL}${item?.images[0]?.image_path}` : JifImg;
    const lotNumberTitle = `${item?.lot_number} | ${item?.title}`;
    const winningAmount = item?.highest_bidder?.amount ? parseFloat(item?.highest_bidder?.amount) : 0;
    const incrementAmount = item?.auction?.bid_increments[0]?.increment_amount ? parseFloat(item?.auction?.bid_increments[0]?.increment_amount) : 0;
    const totalAmount = winningAmount + incrementAmount;
    const shippingIcon = item?.auction?.shipping_option?.is_shipping_available ? faShippingFast : faBan;
    const estimate = `${item?.estimate_from} - ${item?.estimate_to}`;
    const startDate = `${item?.auction?.start_date.split(' ')[0]} - ${item?.auction?.end_date.split(' ')[0]}`;
    const address = `${item?.auction.city} | ${item?.auction.location}`;
    const timeLeft = new Date(item?.auction?.end_time) - new Date();
    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const timeLeftDisplay = daysLeft > 0 ? `${daysLeft} days left` : 'Closed';

    return (
      <>
        <div className="data-box-grid-12 mb-3 border rounded-xl flex items-center justify-between w-full flex-col overflow-hidden">
          <div className="flex items-center justify-between w-full bg-gray-200 py-2 px-3 rounded-t-xl watch-list-single-view-data">
            <div className="item-header flex items-center justify-center ">
              <p className="font-semibold m-0">
                {lotNumberTitle}
              </p>
            </div>

            <p className="pt-3 text-center underline font-semibold">
              {item?.status === "Winning"
                ? "Winning"
                : "Not Acceptable"}
            </p>
          </div>
          <div className="flex items-center justify-between gap-5 px-3 py-2 w-full watch-list-single-view-images">
            <div className="detail-column w-full h-full flex items-center justify-center w-1/5">
              <img
                src={
                  item?.lot?.images[0]?.image_path
                    ? `${process.env.REACT_APP_BASE_IMAGE_URL}${item?.lot?.images[0]?.image_path}`
                    : JifImg
                }
                alt="Lot Image"
                className="w-full min-h-[250px] min-w-[250px] max-h-[150px] max-w-[340px] object-cover hover:scale-105"
              />
            </div>
            <div className="flex items-center justify-start gap-3 w-3/5 h-full  ">
              <div className="detail-column flex items-center justify-center flex-col w-3/5  watch-list-single-view-desc">
                <p className="ellipsis">
                  {truncateDescription(item?.description)}
                </p>
              </div>
              <div className="flex items-center justify-center flex-col w-2/5 h-full watch-list-single-view-desc">
                <div className="detail-column flex items-start justify-center flex-col gap-0">

                  <p> <span className="font-semibold">High Bid : </span>{formatCurrency(winningAmount)}</p>
                  <p> <span className="font-semibold">Total Bids : </span>{item?.bids?.length}</p>
                  <p>
                    {" "}
                    <span className="font-semibold">
                      Estimate :
                    </span>{" "}
                    {estimate}
                  </p>
                  <p>{timeLeftDisplay}</p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center flex-col gap-4 w-1/5 px-3 h-full  border-l border-gray-400 watch-list-single-view-desc watch-list-single-address ">
              <p className="m-0 gap-2 flex items-start justify-start flex-col">
                <p className="m-0">
                  {" "}
                  {address}
                </p>

                <p className="m-0 text-center">
                  {startDate}
                </p>
              </p>

              <p className="m-0">
                <span className="font-semibold">Shipping : </span>{" "}
                {item?.lot?.auction?.shipping_option
                  ?.is_shipping_available ? (
                  <FontAwesomeIcon icon={faShippingFast} />
                ) : (
                  <FontAwesomeIcon icon={faBan} />
                )}
              </p>

            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">

        <div className="top-picks-container watch-list">
          <div className="sort-container">
            <div className="sort-options flex items-center justify-between w-full">
              <div className="flex items-center justify-start gap-2">
                <label style={{ paddingRight: "50px" }}>
                  Sort: &nbsp;
                  <select
                    value={sortOption}
                    onChange={handleSortOptionChange}
                    className="sort-dropdown"
                  >
                    <option value="Sale Order">Sale Order</option>
                    <option value="Lot#">Lot</option>
                    <option value="Bid History">Bid History</option>
                    <option value="Time Remaining">Time Remaining</option>
                    <option value="High Bid">High Bid</option>
                    <option value="Max Bid">Max Bid</option>
                    <option value="Bid Status">Bid Status</option>
                  </select>
                </label>
              </div>
              <div className="flex items-center justify-center gap-2">
                <button
                  className={`view-button ${view === "list" ? "active" : ""}`}
                  onClick={() => handleViewChange("list")}
                >
                  <FontAwesomeIcon icon={faList} />
                </button>
                <button
                  className={`view-button ${view === "grid-2" ? "active" : ""}`}
                  onClick={() => handleViewChange("grid-2")}
                >
                  <FontAwesomeIcon icon={faThLarge} />
                </button>
                <button
                  className={`view-button ${view === "grid-12" ? "active" : ""
                    }`}
                  onClick={() => handleViewChange("grid-12")}
                >
                  <FontAwesomeIcon icon={faTh} />
                </button>
              </div>
            </div>
          </div>

          {isLoading ? (
            <div className="loading-spinner">
              <RingLoader color="#000" />
            </div>
          ) : (
            <div className="data-container">
              {view === 'list' && dataArray.length > 0 && (
                <div className="list-view w-full">
                  {currentData.map((item, index) => (
                    <React.Fragment key={index}>{renderItem(item)}</React.Fragment>
                  ))}
                </div>
              )}
              {view === 'grid-2' && dataArray.length > 0 && (
                <div className='row pt-3 grid-2 gap-y-4 '>
                  {currentData.map((item, index) => (
                    <React.Fragment key={index}>{renderGridItem(item)}</React.Fragment>
                  ))}
                </div>
              )}


              {view === 'grid-12' && dataArray.length > 0 && (
                <div className="grid-12-view">
                  {currentData.map((item, index) => (
                    <React.Fragment key={index}>{renderGrid12Item(item)}</React.Fragment>
                  ))}
                </div>
              )}
              <div className="pagination-controls">
                <button
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i + 1}
                    className={`pagination-number ${currentPage === i + 1 ? "active" : ""
                      }`}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WatchListComponent;
