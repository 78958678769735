import React, { useState } from 'react';
import LotsSideBar from './LotsSideBar';
import MainDeivLots from './MainDeivLots';

const Lots = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const [isSinglePageVisible, setIsSinglePageVisible] = useState(false);

    const [lotSidebarState, setLotSidebarState] = useState({
        showSearchOptions: false,
        selectedAuctionStatus: '',
        selectedLotStatus: '',
        selectedCountry: '',
        selectedCountryState: "",
        selectedCategory: '',
        shippingAvailable: "",
    });
    const updateState = (key, value) => {
        setLotSidebarState(prevState => ({ ...prevState, [key]: value }));

    };

    const handleSidebarToggle = () => {
        setIsSidebarVisible(prevState => !prevState);
    };

    const handleSinglePageToggle = () => {
        setIsSinglePageVisible(prevState => !prevState);
    };

    return (
        <div className='lots container-fluid'>
            <div className='d-flex mb-3'>
                <div className="form-check form-switch me-3">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckSidebar"
                        checked={isSidebarVisible}
                        onChange={handleSidebarToggle}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckSidebar">
                        Show Sidebar
                    </label>
                </div>
            </div>
            {!isSidebarVisible && (
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckSinglePage"
                        checked={isSinglePageVisible}
                        onChange={handleSinglePageToggle}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckSinglePage">
                        Show Single Page
                    </label>
                </div>
            )}
            <div className='row'>
                {isSidebarVisible && (
                    <div className='col-md-3'>
                        <LotsSideBar lotSidebarState={lotSidebarState} updateState={updateState} />
                    </div>
                )}
                <div className={`col-md-${isSidebarVisible ? '9' : '12'}`}>
                    <MainDeivLots
                        isSidebarVisible={isSidebarVisible}
                        isSinglePageVisible={isSinglePageVisible}
                        onSinglePageToggle={handleSinglePageToggle}
                        lotSidebarState={lotSidebarState}
                    />
                </div>
            </div>
        </div>
    );
};

export default Lots;

