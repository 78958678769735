import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SortIcon from "@mui/icons-material/Sort";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import HammerIcon from "@mui/icons-material/Gavel";
import { Box } from "@mui/material";
import axios from "axios";
import AddAuctionModal from "./AddAuctionModal";
import EditAuctionModal from "./EditAuctionModal";
import { Link } from "react-router-dom";
import './AddAuctionModal.css'

function DashboardAuctioner() {
  const [rows, setRows] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [editData, setEditData] = useState(null);
  const [newAuctionData, setNewAuctionData] = useState({
    name: "",
    archived: "",
    endDate: "",
  });
  const [openEdit, setOpenEdit] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const fetchAuctions = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get/auctions`,
        { headers }
      );
      setRows(response.data.data);
    } catch (error) {
      console.error("Error fetching auctions:", error);
    }
  };
  useEffect(() => {
    fetchAuctions();
  }, []);

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    const sortedRows = [...rows].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setRows(sortedRows);
  };

  const handleEdit = (hashId) => {
    const editedAuction = rows.find((row) => row.hash_id === hashId);
    setEditData(editedAuction);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleChangeEdit = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const handleSaveEdit = async () => {
    try {
      const { created_by, is_shipping_available, pickup_info, lots, bid_increments, ...requestData } = editData;
      const requestDataWithConvertedFields = {
        ...requestData,
        bidding_type_id: editData.bidding_type_id.hash_id,
        state: editData.state.hash_id,
        country: editData.country.hash_id,
        is_shipping_available: editData.is_shipping_available ? 1 : 0,
        pickup_info: is_shipping_available === true ? pickup_info : null,
        lots: lots || [],
        slabs: bid_increments || [],
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/update/auctions/${editData.hash_id}`,
        requestDataWithConvertedFields,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      const updatedRows = rows.map((row) =>
        row.hash_id === editData.hash_id ? response.data.updatedAuction : row
      );
      setRows(updatedRows);
      setOpenEdit(false);
      fetchAuctions();
    } catch (error) {
      console.error("Error updating auction:", error);
    }
  };

  const handleChangeAdd = (e) => {
    setNewAuctionData({ ...newAuctionData, [e.target.name]: e.target.value });
  };

  const handleSaveAdd = () => {
    if (!rows.find((row) => row.code === newAuctionData.code)) {
      setRows([...rows, newAuctionData]);
    }
    setOpenAdd(false);
    setNewAuctionData({
      name: "",
      archived: "",
      endDate: "",
    });
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  }

  const handleDelete = async (hashId) => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found in localStorage.");
      return;
    }
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "ngrok-skip-browser-warning": "69420",
    };
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/delete/auctions/${hashId}`,
        { headers }
      );
      const updatedRows = rows.filter((row) => row.hash_id !== hashId);
      setRows(updatedRows);
      fetchAuctions();
    } catch (error) {
      console.error("Error deleting auction:", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row px-3">
          <Box
            mt={2}
            mb={2}
            p={2}
            boxShadow={3}
            bgcolor="white"
            style={{ background: "#fff" }}
          >
            <Grid container spacing={2} alignItems="center " className="flex items-center justify-between">
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                }}
              >
                <HammerIcon style={{ marginRight: "8px" }} />
                Auctions
              </Grid>
              
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "20px",
                }}
              >
                <IconButton onClick={handleOpenAdd} className="border-0 rounded-lg">
                  <AddIcon style={{ marginRight: "8px" }} />
                  New Auction
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box
            mt={2}
            mb={2}
            p={2}
            boxShadow={3}
            bgcolor="white"
            style={{ background: "#fff", padding: " 28px" }}
          >
            <Grid container spacing={2} alignItems="center"  className="flex items-center justify-between gap-0 ">
              <Grid item className="p-0 h-full">
                <IconButton
                  style={{
                    background: "#3993ee",
                    borderRadius: "0",
                    color: "#fff",
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
              <Grid item xs className=" w-full min-h-full p-0  px-2">
                <InputBase placeholder="Search.." className="w-full h-full align-middle"/>
              </Grid>
              <Grid item className="p-0 h-full">
                <IconButton
                  style={{
                    background: "#3993ee",
                    borderRadius: "0",
                    color: "#fff",
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2} mb={2} p={2} boxShadow={3} bgcolor="white" >
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ display: "contents" }}>
                  <TableRow>
                    <TableCell>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          Name{" "}
                          <IconButton size="small" onClick={() => handleSort("name")}>
                            <SortIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          Start Date{" "}
                          <IconButton size="small" onClick={() => handleSort("start_date")}>
                            <SortIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          End Date{" "}
                          <IconButton size="small" onClick={() => handleSort("end_date")}>
                            <SortIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>



                    <TableCell>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          State{" "}
                          <IconButton size="small" onClick={() => handleSort("state")}>
                            <SortIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>


                    <TableCell>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          Country{" "}
                          <IconButton size="small" onClick={() => handleSort("country")}>
                            <SortIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>

                    <TableCell>
                      <Grid container alignItems="center">
                        <Grid item xs>
                          Status{" "}
                          <IconButton size="small" onClick={() => handleSort("status")}>
                            <SortIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row) => (
                    <TableRow key={row?.hash_id} >
                      <TableCell component={Link} to={`/auctions/${row?.hash_id}`} style={{ textDecoration: "none" }}>{row?.name}</TableCell>
                      <TableCell component={Link} to={`/auctions/${row?.hash_id}`} style={{ textDecoration: "none" }}>{row?.start_date}</TableCell>
                      <TableCell component={Link} to={`/auctions/${row?.hash_id}`} style={{ textDecoration: "none" }}>{row?.end_date}</TableCell>
                      <TableCell component={Link} to={`/auctions/${row?.hash_id}`} style={{ textDecoration: "none" }}>{row?.state?.name}</TableCell>
                      <TableCell component={Link} to={`/auctions/${row?.hash_id}`} style={{ textDecoration: "none" }}>{row?.country?.name}</TableCell>
                      <TableCell component={Link} to={`/auctions/${row?.hash_id}`} style={{ textDecoration: "none" }}>{row?.status}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEdit(row?.hash_id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(row?.hash_id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <AddAuctionModal
            open={openAdd}
            onClose={handleCloseAdd}
            handleChange={handleChangeAdd}
            handleSave={handleSaveAdd}
            newAuctionData={newAuctionData}
          />
          <EditAuctionModal
            open={openEdit}
            close={handleCloseEdit}
            editData={editData}
            handleChange={handleChangeEdit}
            handleSave={handleSaveEdit}
          />
        </div>
      </div>
    </>
  );
}

export default DashboardAuctioner;
