import React from 'react';
import "./JoinTheHibid.css";
import { Link } from 'react-router-dom';

const JoinTheHibid = () => {
    window.scroll(0,0);
    return (
        <div className='container-fluid'>
            <div className='row pt-5 p-3'>
                <p className='user-agreeement'><b>Please be advised that this User Agreement contains provisions that govern how claims you and we have against each other are resolved (see "Disclaimer of Warranties; Limitation of Liability" and "Legal Disputes" provisions below). It also contains an Agreement to Arbitrate which will, with limited exception, require you to submit claims you have against us or our agents to binding and final arbitration, unless you opt out of the Agreement to Arbitrate If you do not opt out:</b></p>
                <p className='user-agreeement'>
                    <b>   You will only be permitted to pursue claims against us or our agents on an individual basis, not as a plaintiff or class member in any class or representative action or proceeding, and you will only be permitted to seek relief including monetary, injunctive, and declaratory relief  on an individual basis.</b>
                </p>
                <h1 className='pt-2'>About Auctionslive.net  </h1>
                <p className=' user-agreeement'><b>Auctionslive.net is a internet platform that allows auction companies sell, and buy goods and services in various geographic locations using a variety of pricing formats. Auctionslive.net is not a party to contracts for sale between third-party sellers and buyers, nor is Auctionslive.net a traditional auctioneer.</b></p>
                <p className='facilitate'>We may help facilitate the resolution of disputes between buyers and sellers through various programs. Unless otherwise expressly provided, Auctionslive.net has no control over and does not guarantee: the existence, quality, safety, or legality of items advertised; the truth or accuracy of users' content or listings; the ability of Auctioneers to sell items; the ability of buyers to pay for items; or that a buyer or seller will actually complete a transaction or return an item.</p>
                <h2 className='pt-2'>Using Auctionslive.net  </h2>
                <p className='facilitate'>In connection with using or accessing our Services you agree to comply with this User Agreement, our policies, our terms, and all applicable laws, rules, and regulations, and you will not:</p>
                <p className='facilitate px-4'>
                    Breach or circumvent any laws, regulations, third-party rights or our systems, Services, policies, or determinations of your account status:
                </p>
                <p className='facilitate px-4'>	Our Services if you are not able to form legally binding contracts. If you are under 18 years old, or are a person with whom transactions are prohibited under economic or trade sanctions:</p>
                <p className='facilitate px-4'>
                    Fail to pay for items purchased by you, unless you have a valid reason as set out in an Auctionslive.net policy:
                </p>
                <p className='facilitate px-4'>
                    Manipulate the price of any item or interfere with any other user's listings:
                </p>
                <h5 className='facilitate px-4'>Engage in gambling:</h5>
                <p className='facilitate px-4'>
                    Provide us with content that is false, inaccurate, misleading, deceptive, defamatory, libelous, illegal, inappropriate, harmful, or violates and/or infringes the rights of others;
                </p>
                <p className='facilitate px-4'>  Infringe the copyright, trademark, patents.
                </p>
                <p className='facilitate px-4'>      Auctioneers must meet Auctionslive.net. Failure to meet these standards may result in Auctionslive.net charging sellers additional fees, and/or limiting, restricting, suspending, or downgrading your auction account.
                </p>
                <h1 className='pt-2'>  Policy Enforcement  </h1>
                <p className='facilitate'>
                    When a buyer or seller issue arises, we may consider the user's performance history and the specific circumstances in applying our policies. We may choose to be more lenient with policy enforcement in an effort to do the right thing for both buyers and sellers.
                </p>
                <p className='facilitate'><b> Fees and Taxes </b>   </p>
                <p className='facilitate'>   We charge the Auctioneers for the use of our Services.   </p>
                <p className='facilitate'> The fees we charge Auctioneers for using our Services to sell goods and services are listed on our Selling Fees  pages. We may change our selling fees from time to time by posting the changes on the Auctionslive.net site Thirty days (30) days in advance, but with no advance notice required for temporary promotions or any changes that result in the reduction of fees.
                </p>
                <p className='facilitate'>
                    If you are a seller, you are liable for fees arising out of all sales made using some or all of our Services, even if sales terms are finalized or payment is made outside of Auctionslive.net.
                </p>
                <p className='facilitate auctioneer-file'>
                    <b>
                        You as a Auctioneer must have a payment method on file when using our selling Services and pay all fees and applicable taxes associated with your use of our Services in 7 days after auction closes.
                    </b>
                </p>
                <p className='facilitate px-5 '>
                    You assume full responsibility for the item offered and the accuracy and content of the listing, including listing content created by Auctionslive.net or third parties such as translation, image editing, and generative artificial intelligence tools;
                </p>
                <p className='facilitate px-5 '> We strive to create a market Platform where buyers find what they are looking for. Therefore, the appearance or placement of listings in search and browse results will depend on a variety of factors, including, but not limited to:
                </p>
                <p className='facilitate px-5  facilitate-location'> Buyer's , search query, browsing site, and history;
                </p>
                <p className='facilitate px-5 facilitate-location'>   Item's location, listing format, price terms of service, end time, history, and relevance to the user query;
                </p>
                <p className='facilitate px-5 facilitate-location'>  Metatags and URL links that are included in a listing may be removed or altered;
                </p>
                <h1 className='pt-2'> Purchase Conditions </h1>
                <p className='facilitate'>
                    When buying an item using our Services, you agree to the Rules and Policies for Buyers and that:
                </p>
                <p className='facilitate px-5 '>
                    You are responsible for reading the full item listing before making a bid or offer, buying, or committing to buy;
                </p>
                <p className='facilitate px-5 '>
                    You enter into a legally binding contract to purchase an item when you buy the item, commit to buy the item, your offer for the item is accepted, you have the winning bid for the item, or your bid for the item is otherwise accepted, regardless of when payment is due or received unless the transaction terms state that you are required to pay for the item before a binding contract is formed;
                </p>
                <p className='facilitate px-5 '>
                    For real estate, a bid or offer initiates a non-binding transaction representing a buyer's serious expression of interest in buying the seller's item and does not create a formal contract between the buyer and the seller;
                </p>
                <p className='facilitate px-5 '>
                    We do not transfer legal ownership of items from the seller to you;
                </p>
                <p className='facilitate px-5 '>
                    Ownership between the buyer and the seller, unless the buyer and the seller agree otherwise.
                </p>
                <h1 className='pt-2'>  Additional Terms </h1>
                <p className='facilitate'>   Returns and cancellations for sellers
                </p>
                <p className='facilitate'> Sellers can create rules to automate replacements, returns, and refunds under certain circumstances.. Sellers may remove or customize their return preferences.</p>
                <p className='facilitate'>  When an item is returned or if a transaction is canceled after payment has been completed, Autionslive.net may issue a refund to the seller's for the amount of the refund if applicable with the</p>
                <h1 className='pt-2'> Payment Services </h1>
                <p className='facilitate'>
                    Payments for goods and services sold using our Services are facilitated by the designated Auctioneer and the Buyer.
                </p>
                <h3 className='pt-2'>
                    Disclaimer of Warranties; Limitation of Liability
                </h3>
                <p className='facilitate'>
                    We try to keep our Services safe, secure, and functioning properly, but we cannot guarantee the continuous operation of or access to our Services. Biding updates and other notification functionality in Auctionslive.net applications may not occur in real time. Such functionality is subject to delays beyond <Link to="/">
                        auctionslive.net
                    </Link> control.
                </p>
                <p className='text-capitalize facilitate'>
                    YOU AGREE THAT YOU ARE MAKING USE OF OUR SERVICES AT YOUR OWN RISK, AND THAT THEY ARE BEING PROVIDED TO YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS. ACCORDINGLY, TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE EXCLUDE ALL EXPRESS OR IMPLIED WARRANTIES, TERMS AND CONDITIONS INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                </p>
                <p className='text-capitalize facilitate'>
                    IN ADDITION, TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL AUCTIONSLIVE.NET AND AFFILIATES, AND OUR AND THEIR OFFICERS, DIRECTORS, AGENTS, AND EMPLOYEES) BE LIABLE TO YOU OR ANY THIRD PARTY UNDER ANY CLAIM AT LAW OR IN EQUITY FOR ANY CONSEQUENTIAL DAMAGES OR LOSSES (INCLUDING, BUT NOT LIMITED TO, LOSS OF MONEY, GOODWILL OR REPUTATION, PROFITS, OTHER INTANGIBLE LOSSES, OR ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES), AND ALL SUCH DAMAGES OR LOSSES ARE EXPRESSLY EXCLUDED BY THIS USER AGREEMENT WHETHER OR NOT THEY WERE FORESEEABLE OR EBAY WAS ADVISED OF SUCH DAMAGES OR LOSSES. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE (INCLUDING OUR PARENT, SUBSIDIARIES, AND AFFILIATES, AND OUR AND THEIR OFFICERS, DIRECTORS, AGENTS, AND EMPLOYEES) ARE NOT LIABLE, AND YOU AGREE NOT TO HOLD US RESPONSIBLE, FOR ANY DAMAGES OR LOSSES (INCLUDING, BUT NOT LIMITED TO, LOSS OF MONEY, GOODWILL OR REPUTATION, PROFITS, OTHER INTANGIBLE LOSSES, OR ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES) RESULTING DIRECTLY OR INDIRECTLY FROM:
                </p>
                <p className='facilitate px-5'>
                    the content you provide (directly or indirectly) using our Services; <br />
                    your use of or your inability to use our Services; <br />
                    delays or disruptions in our Services; <br />
                    viruses or other malicious software obtained by accessing or linking to our Services;  <br />
                    glitches, bugs, errors, or inaccuracies of any kind in our Services; <br />
                    damage to your hardware device from the use of any eBay Service; <br />
                    the content, actions, or inactions of third parties, including items listed using our Services or the destruction of allegedly fake items;<br />
                    a suspension or other action taken with respect to your account or breach of the Using AUCTIONSLIVE.NET section above;<br />
                    the duration or manner in which your listings appear in search results. <br />
                    your need to modify practices, content, or behavior, or your loss of or inability to do business, as a result of changes to this User Agreement or our policies.
                </p>
                <p className='facilitate'>
                    Some jurisdictions do not allow the disclaimer of warranties or exclusion of damages, so such disclaimers and exclusions may not apply to you.
                </p>
                <h1 className='pt-2'>Release  </h1>
                <p className='facilitate'>
                    If you have a dispute with one or more users, you release us (and our affiliates and subsidiaries, and our and their respective officers, directors, employees, and agents) from claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. <b>In entering into this release, you expressly waive any protections (whether statutory or otherwise) that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favor at the time of agreeing to this release.</b>
                </p>
                <h1 className='pt-2'>Release  </h1>
                <p className='facilitate'>
                    You will indemnify and hold us (including our affiliates and subsidiaries, as well as our and their respective officers, directors, employees, and agents) harmless from any claim or demand, including reasonable legal fees, made by any third party due to or arising out of your breach of this User Agreement, your improper use of our Services or your breach of any law or the rights of a third party.</p>
                <h1 className='pt-2'>Legal Disputes  </h1>
                <p className='facilitate text-capitalize'>
                    <b> PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR RIGHTS AND WILL HAVE A SUBSTANTIAL IMPACT ON HOW CLAIMS YOU AND AUCTIONSLIVE.NET HAVE AGAINST EACH OTHER ARE RESOLVED.</b>
                </p>
                <p className='facilitate'>
                    <b>In this Legal Disputes section, the term "related third parties" includes your and Auctionslive.net respective affiliates, subsidiaries, parent companies, predecessors, successors, assigns, as well as your, Auctionslive.net, and these entities' respective employees and agents.</b>
                </p>
                <p className='facilitate'>
                    <b>You and Auctionslive.net each agree that any and all claims or disputes at law or equity that has arisen, or may arise, between you and Auctioonslive.net(or any related third parties) that relate in any way to or arise out of this or previous versions of this User Agreement, your use of or access to the Services, the actions of Auctionslive.net or its agents, or any products or services sold, offered, or purchased through the Services, will be resolved in accordance with the provisions set forth in this Legal Disputes section.
                    </b></p>
                <h1 className='pt-2'>Applicable Law  </h1>
                <p className='facilitate'>You agree that, except to the extent inconsistent with or preempted by federal law, the laws of the State of Minnesota, without regard to principles of conflict of laws, will govern this User Agreement and any claim or dispute that has arisen or may arise between you and eBay, except as otherwise stated in this User Agreement.
                </p>
                <h1 className='pt-2'>Agreement to Arbitrate  </h1>
                <p className='facilitate'>
                    <b>
                        You and Auctionslive.net each agree that any and all disputes or claims that have arisen, or may arise, between you and Auctionslive.net (or any related third parties) that relate in any way to or arise out of this or previous versions of the User Agreement, your use of or access to our Services, the actions of Auctionslive.net or its agents, or any products or services sold, offered, or purchased through our Services shall be resolved exclusively through final and binding arbitration, rather than in court, subject to any exemptions listed in this section.
                    </b>
                </p>
                <p className='facilitate'>
                    <b>
                        The Federal Arbitration Act ("FAA") and, to the extent not inconsistent with the FAA, the law of the State of Minnesota without regard to principles of conflict of laws govern the interpretation and enforcement of this Agreement to Arbitrate.
                    </b>
                </p>
                <p className='facilitate'>
                    <b>  1.	Exemption - Small Claims Court Claims</b> Notwithstanding the parties' agreement to resolve all disputes through arbitration, either party may seek relief in a small claims court for disputes or claims within the scope of that court's jurisdiction and on an individual (non-class) basis only. If a party initiates an arbitration asserting a claim that falls within the jurisdiction of a small claims court, the other party may, in its discretion, require that the arbitration demand be withdrawn and that the claim be filed in small claims court. Any dispute about whether a claim falls within any given small claims court's jurisdiction will be resolved by that court, not by an arbitrator. In the event of any such jurisdictional dispute, the arbitration proceeding will remain closed unless and until the small claims court issues a decision that the claim should proceed in arbitration.
                </p>
            </div>
        </div>
    )
}

export default JoinTheHibid; 