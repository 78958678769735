import { faAngleDown, faAngleUp, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { RingLoader } from 'react-spinners';


const CategorySelector = ({ handleCategoryChange }) => {
    const [showCategoryList, setShowCategoryList] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [mainCategoryExpand, setMainCategoryExpand] = useState(null);
    const [expandedSubCategory, setExpandedSubCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const fetchCategories = async () => {
        setLoadingCategories(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
                headers: {
                    "ngrok-skip-browser-warning": "69420"
                }
            });
            setCategoryList(response.data.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setLoadingCategories(false);
        }
    };

    useEffect(() => {
        if (showCategoryList) {
            fetchCategories();
        }
    }, [showCategoryList]);

    const handleMainCategoryExpand = (hash_id) => {
        const newCategory = hash_id !== mainCategoryExpand ? hash_id : null;
        setMainCategoryExpand(newCategory);
        setExpandedSubCategory([]);
        setSelectedCategory(newCategory);
        handleCategoryChange(newCategory);
    };

    const handleSubcategoryExpand = (hash_id) => {
        const index = expandedSubCategory.indexOf(hash_id);
        if (index === -1) {
            setExpandedSubCategory([...expandedSubCategory, hash_id]);
        } else {
            setExpandedSubCategory(expandedSubCategory.filter(id => id !== hash_id));
        }
        setSelectedCategory(hash_id);
        handleCategoryChange(hash_id);
    };

    const handleCategoryListToggle = () => {
        if (showCategoryList) {
            setMainCategoryExpand(null);
            setExpandedSubCategory([]);
            setSelectedCategory(null);
            handleCategoryChange(null); 
        }
        setShowCategoryList(!showCategoryList);
    };

    const renderCategoryItems = () => {
        return (
         
            categoryList?.length > 0 && categoryList.map((category) => (
                <ul key={category.hash_id} className='p-0 m-0 py-2' >
                    <div className='sidebar-item  d-flex sub-category items-center justify-start category-border' style={{justifyContent:"flex-start !important"}}  onClick={() => handleMainCategoryExpand(category.hash_id)}>
                        <div>
                            <FontAwesomeIcon icon={mainCategoryExpand === category.hash_id ? faAngleDown : faAngleRight} className='toggle-icon quick-search' />
                        </div>
                        <p className='px-3 my-0'>{category.name}</p>
                    </div>
                    {mainCategoryExpand === category.hash_id && (
                        <div className='form-group '>
                            {category.descendants.map(renderSubCategoryItems)}
                        </div>
                    )}
                </ul>
            ))
        );
    };

    const renderSubCategoryItems = (subCategory) => {
        return (
            <ul key={subCategory.hash_id} className='p-0'>
                <div className='sidebar-item d-flex sub-category category-border items-center justify-start pl-3'  onClick={() => handleSubcategoryExpand(subCategory.hash_id)}>
                    <div className=''>
                        <FontAwesomeIcon icon={expandedSubCategory.includes(subCategory.hash_id) ? faAngleDown : faAngleRight} className='toggle-icon quick-search ' />
                    </div>
                    <label className='font-normal'>{subCategory.name}</label>
                </div>
                {expandedSubCategory.includes(subCategory.hash_id) && (
                    <div className='form-group lots-form-group'>
                        {subCategory.descendants.map(renderSubCategoryItems)}
                    </div>
                )}
            </ul>
        );
    };
    return (
        <>
            <div className='sidebar-item d-flex auction-states-drop font-bold sidebar-item-category' onClick={handleCategoryListToggle}>
                CATEGORY
                <div className='mx-2'>
                    <FontAwesomeIcon icon={showCategoryList ? faAngleUp : faAngleDown} className='toggle-icon quick-search' />
                </div>
            </div>
            {showCategoryList && (
                <div className='sidebar-sub-item'>
                    {loadingCategories ? (
                        <div className='d-flex justify-content-center align-items-center'>
                            <RingLoader size={60} color={'#123abc'} loading={loadingCategories} />
                        </div>
                    ) : (
                        <div className='form-group ' >
                            {renderCategoryItems()}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default CategorySelector;
