import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Avatar from 'react-avatar';
import './Header.css';
import { useAuth } from '../../AuthContext';

const MainHeader = () => {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const username = localStorage.getItem('username');
    const handleNavigateToProfile = () => {
        navigate('/profile');
    };
    const handleLogout = () => {
        logout();
        navigate('/');
    };
    const handleNavigateToSignIn = () => {
        navigate('/authentication/sign-in');
    };
    return (
        <div className='main-header-one flex items-center justify-center flex-col py-[8px] border-b-[1px] border-gray-500'> 
            <div className='container container-header-one flex items-center justify-center'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='text-center d-flex new-bidder-login gap-2'>
                            {username ? (<>
                            </>
                            ) : (
                                <p className='login-bidder login-bidder-headerone text-uppercase ' onClick={handleNavigateToSignIn}>
                                    <b style={{ color: " black" }}>Login / New Bidder</b>
                                </p>
                            )}
                            <Dropdown className='header-item mx-3 pb-0'>
                                <Dropdown.Toggle as="p" className='dropdown-toggle mb-0 uppercase font-[700] text-[#555555] text-[14px]' id='findAuctionsDropdown'>
                                    <b>Find Auctions</b>
                                </Dropdown.Toggle>
                                <Dropdown.Menu aria-labelledby='findAuctionsDropdown' >
                                    <Dropdown.Item href='#'>Auction 1</Dropdown.Item>
                                    <Dropdown.Item href='#'>Auction 2</Dropdown.Item>
                                    <Dropdown.Item href='#'>Auction 3</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className='flex items-center justify-center mainHeader-nav'>
                            <p className='header-item header-list p-0 m-0  mx-3 uppercase text-[#c9c9c9] font-[600] text-[14px]'>Blog</p>
                            <p className='header-item header-list p-0 m-0 mx-3 uppercase text-[#c9c9c9] font-[600] text-[14px]'>Join Our mailing list</p>
                            </div>
                            {username && (
                                <Dropdown className='avatar-dropdown pb-0 m-0 mx-3 text-[#555555] text-[14px]'>
                                    <Dropdown.Toggle as="div" className='avatar-toggle'>
                                        <Avatar
                                            name={username}
                                            size="30"
                                            round
                                            className='avatar'
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                        <Dropdown.Item onClick={handleNavigateToProfile}>View Profile</Dropdown.Item>
                                        <Dropdown.Item onClick={handleNavigateToProfile}> <b>{username}</b></Dropdown.Item>
                                        <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MainHeader;
