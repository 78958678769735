import React from 'react';
import { Table, Button, Spinner, Form } from 'react-bootstrap';

const StateTable = ({ states, onToggleState, loading, stateSearch, setStateSearch }) => (
    <>
        {loading ? (
            <div className="text-center my-5">
                <Spinner animation="border" variant="primary" />
                <p>Loading...</p>
            </div>
        ) : (
            <>
                <Form.Group controlId="stateSearch">
                    <Form.Control
                        type="text"
                        placeholder="Search states..."
                        value={stateSearch}
                        onChange={(e) => setStateSearch(e.target.value)}
                        className='mb-3'
                    />
                </Form.Group>
                <Table striped bordered hover responsive className="state-table">
                    <thead>
                        <tr>
                            <th>State Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {states.length > 0 ? (
                            states.map(state => (
                                <tr key={state.hash_id}>
                                    <td>{state.name}</td>
                                    <td>
                                        <Button
                                            variant={state.is_active === 1 ? 'success' : 'danger'}
                                            onClick={() => onToggleState(state.hash_id)}
                                        >
                                            {state.is_active === 1 ? 'Active' : 'Inactive'}
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" className="text-center">No states found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </>
        )}
    </>
);
export default StateTable;
