import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, TextField, Button, Box, Grid, Checkbox, FormControlLabel, MenuItem, InputLabel, Select } from '@mui/material';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const EditAuctionModal = ({ open, close, editData, handleChange, handleSave, }) => {
    const [lots, setLots] = useState([]);
    const [bid_increments, setBid_increments] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [loadingStates, setLoadingStates] = useState(false)
    const [fieldValue, setFieldValue] = useState([]);
    console.log(editData, "editDataeditData")

    useEffect(() => {
        if (editData?.lots) {
            setLots(editData.lots);
        }
    }, [editData]);
    const handleStateChange = (event) => {
        setFieldValue('state', event.target.value);
    };
    useEffect(() => {
        if (editData?.bid_increments) {
            setBid_increments(editData.bid_increments);
        }
    }, [editData]);
    const handleSlabChange = (index, field, value) => {
        const updatedBid_increments = [...bid_increments];
        updatedBid_increments[index] = { ...updatedBid_increments[index], [field]: value };
        setBid_increments(updatedBid_increments);
    };
    const handleLotChange = (index, field, value) => {
        const updatedLots = [...lots];
        updatedLots[index] = { ...updatedLots[index], [field]: value };
        setLots(updatedLots);
    };
    const addLot = () => {
        const newLot = { title: '', description: '', images: [], category_id: '' };
        setLots([...lots, newLot]);
    };
    const addSlab = () => {
        const newBid_increments = { min_amount: '', max_amount: '', increment_amount: '' };
        setBid_increments([...bid_increments, newBid_increments]);
    };
    const deleteSlab = (index) => {
        const updatedBid_increments = bid_increments.filter((_, i) => i !== index);
        setBid_increments(updatedBid_increments);
    };
    const deleteLot = (index) => {
        const updatedLots = lots.filter((_, i) => i !== index);
        setLots(updatedLots);
    };
    const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        handleChange({
            target: { name: 'is_shipping_available', value: checked ? 1 : 0 }
        });
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`, {
                    headers: {
                        "ngrok-skip-browser-warning": "69420"
                    }
                });
                const { data } = response.data;
                setCountries(data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    const fetchStates = async (countryId) => {
        setLoadingStates(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/states/${countryId}`, {
                headers: {
                    "ngrok-skip-browser-warning": "69420"
                }
            });
            const { data } = response.data;
            setStates(data);
        } catch (error) {
            console.error('Error fetching states:', error);
        } finally {
            setLoadingStates(false);
        }
    };

    const handleCountryChange = (event) => {
        const countryId = event.target.value;
        fetchStates(countryId);
    };

    return (
        <Modal open={open} onClose={close}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                    maxWidth: 800,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    overflowY: "auto",
                    maxHeight: "90vh",
                }}
            >
                <div className='text-end'>
                    <FontAwesomeIcon onClick={close} icon={faXmark} style={{ fontSize: "30px", color: "red" }} />
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={editData?.name}
                            onChange={handleChange}
                            margin="normal"
                            sx={{ mb: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Auctioneer"
                            name="auctioneer"
                            value={editData?.auctioneer}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Start Date"
                            name="start_date"
                            type="datetime-local"
                            value={editData?.start_date}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="End Date"
                            name="end_date"
                            type="datetime-local"
                            value={editData?.end_date}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Auction Date Time Info"
                            name="auction_date_time_info"
                            value={editData?.auction_date_time_info}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Preview Start Datetime"
                            name="preview_start_datetime"
                            type="datetime-local"
                            value={editData?.preview_start_datetime}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Checkout Date Time"
                            name="checkout_date_time"
                            type="datetime-local"
                            value={editData?.checkout_date_time}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Location"
                            name="location"
                            value={editData?.location}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Buyer Premium"
                            name="buyer_premium"
                            value={editData?.buyer_premium}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Description"
                            name="description"
                            value={editData?.description}
                            onChange={handleChange}
                            margin="normal"
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Deposit Amount"
                            name="deposit_amount"
                            value={editData?.deposit_amount}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Checkout Start Datetime"
                            name="checkout_start_datetime"
                            type="datetime-local"
                            value={editData?.checkout_start_datetime}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Checkout End Datetime"
                            name="checkout_end_datetime"
                            type="datetime-local"
                            value={editData?.checkout_end_datetime}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            label="Country"
                            name="country"
                            value={editData?.country?.name}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    {/* <Select
                        labelId="country-label"
                        id="country"
                        onChange={handleCountryChange}
                    >
                        {countries.map(country => (
                            <MenuItem key={country.hash_id} value={country.hash_id}>
                                {country.name}
                            </MenuItem>
                        ))}
                    </Select> */}
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            label="State"
                            name="state"
                            value={editData?.state?.name}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    {/* <Select
                        labelId="state-label"
                        id="state"
                        value={editData?.state?.name || states.name}
                        onChange={handleStateChange}
                    >
                        {states.map(state => (
                            <MenuItem key={state.hash_id} value={state.hash_id}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </Select> */}
                    <Grid item xs={12}>
                        <TextField
                            margin="normal"
                            label="city"
                            name="city"
                            value={editData?.city}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Zip Code"
                            name="zip_code"
                            value={editData?.zip_code}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Terms and Conditions"
                            name="terms_and_conditions"
                            value={editData?.terms_and_conditions}
                            onChange={handleChange}
                            margin="normal"
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Bidding Notice"
                            name="bidding_notice"
                            value={editData?.bidding_notice}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Status"
                            name="status"
                            value={editData?.status}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Lots</h3>
                        {lots.map((lot, index) => (
                            <Box key={index} sx={{ border: '1px solid #ddd', borderRadius: '4px', padding: '16px', mb: '16px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Title"
                                            value={lot.title || ''}
                                            onChange={(e) => handleLotChange(index, 'title', e.target.value)}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Description"
                                            value={lot.description || ''}
                                            onChange={(e) => handleLotChange(index, 'description', e.target.value)}
                                            margin="normal"
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="estimate_from"
                                            value={lot.estimate_from || ''}
                                            onChange={(e) => handleLotChange(index, 'estimate_from', e.target.value)}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            margin="normal"
                                            label="category_id"
                                            name="category_id"
                                            value={editData?.name}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="estimate_to"
                                            value={lot.estimate_to || ''}
                                            onChange={(e) => handleLotChange(index, 'estimate_to', e.target.value)}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <div className='px-3'>
                                        <Button variant="outlined" color="error" className='delete-auction-lot' onClick={() => deleteLot(index)} sx={{ mt: 1 }}>
                                            Delete Lot
                                        </Button>
                                    </div>
                                </Grid>
                            </Box>
                        ))}
                        <Button variant="outlined" onClick={addLot}>Add Lot</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Slabs</h3>
                        {bid_increments.map((slab, index) => (
                            <Box key={index} sx={{ border: '1px solid #ddd', borderRadius: '4px', padding: '16px', mb: '16px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="min_amount"
                                            value={slab.min_amount || ''}
                                            onChange={(e) => handleSlabChange(index, 'min_amount', e.target.value)}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="max_amount"
                                            value={slab.max_amount || ''}
                                            onChange={(e) => handleSlabChange(index, 'max_amount', e.target.value)}
                                            margin="normal"
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="increment_amount"
                                            value={slab.increment_amount || ''}
                                            onChange={(e) => handleSlabChange(index, 'increment_amount', e.target.value)}
                                            margin="normal"
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                    <div className='px-3'>
                                        <Button variant="outlined" color="error" className='delete-auction-lot' onClick={() => deleteSlab(index)} sx={{ mt: 1 }}>
                                            Delete Slab
                                        </Button>
                                    </div>
                                </Grid>
                            </Box>
                        ))}
                        <Button variant="outlined" onClick={addSlab}>Add Slab</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Bidding Type ID"
                            name="bidding_type_id"
                            value={editData?.bidding_type_id.name}
                            onChange={handleChange}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="card_verified_required"
                                    checked={editData?.card_verified_required}
                                    onChange={(e) =>
                                        handleChange({ target: { name: e.target.name, value: e.target.checked } })
                                    }
                                />
                            }
                            label="Card Verified Required"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="featured"
                                    checked={editData?.featured}
                                    onChange={(e) =>
                                        handleChange({ target: { name: e.target.name, value: e.target.checked } })
                                    }
                                />
                            }
                            label="Featured"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="is_shipping_available"
                                    checked={editData?.is_shipping_available === 1}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label="Is Shipping Available"
                        />
                    </Grid>
                    {editData?.is_shipping_available === 1 && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Pickup Info"
                                name="pickup_info"
                                value={editData?.pickup_info || ''}
                                onChange={handleChange}
                                margin="normal"
                                multiline
                                rows={4}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleSave} style={{ color: "#fff" }}>
                            Save
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={close} style={{ marginLeft: "10px", color: "black" }}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};
EditAuctionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    editData: PropTypes.shape({
        name: PropTypes.string,
        auctioneer: PropTypes.string,
        start_date: PropTypes.string,
        end_date: PropTypes.string,
        auction_date_time_info: PropTypes.string,
        preview_start_datetime: PropTypes.string,
        checkout_date_time: PropTypes.string,
        location: PropTypes.string,
        buyer_premium: PropTypes.string,
        description: PropTypes.string,
        card_verified_required: PropTypes.bool,
        deposit_amount: PropTypes.string,
        checkout_start_datetime: PropTypes.string,
        checkout_end_datetime: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        zip_code: PropTypes.string,
        terms_and_conditions: PropTypes.string,
        featured: PropTypes.bool,
        bidding_notice: PropTypes.string,
        status: PropTypes.string,
        is_shipping_available: PropTypes.number,
        pickup_info: PropTypes.string,
        slabs: PropTypes.arrayOf(
            PropTypes.shape({
                min_amount: PropTypes.string,
                max_amount: PropTypes.string,
                increment_amount: PropTypes.array,
            })
        ),
        bidding_type_id: PropTypes.string,
        lots: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                images: PropTypes.array,
                category_id: PropTypes.string,
                estimate_from: PropTypes.string,
                estimate_to: PropTypes.string,
            })
        )
    }).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
};
export default EditAuctionModal;