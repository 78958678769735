import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

const FormTextField = ({
    id,
    label,
    placeholder,
    variant,
    value,
    onChange,
    onBlur,
    error,
    helperText,
    multiline,
    rows,
    InputLabelProps,
    sx,
}) => {
    return (
        <TextField
            id={id}
            label={label}
            placeholder={placeholder}
            variant={variant}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            helperText={helperText}
            multiline={multiline}
            rows={rows}
            InputLabelProps={InputLabelProps}
            sx={sx}
        />
    );
};

FormTextField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    helperText: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    InputLabelProps: PropTypes.object,
    sx: PropTypes.object,
};

export default FormTextField;
