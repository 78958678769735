import React, { useState } from 'react';
import Slider1 from "../../../assets/images/slider1.png";
import Category13 from "../../../assets/images/Book7.png";
import Category14 from "../../../assets/images/Book6.png";
import "./Slider.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const slideData = [
    {
        image: Slider1,
        text: 'July 3rd - 7th buffalo broker coin auction',
        heading: 'Gold Standard Auctions',
        desc: "3270 Lots - Ends 7/7/2024"
    },
    {
        image: Category13,
        text: 'July 5th - 7th buffalo broker coin auction',
        heading: 'Standard Auctions',
        desc: "3270 Lots - Ends 7/7/2024"
    },
    {
        image: Category14,
        text: 'July 5th - 7th buffalo broker coin auction',
        heading: 'Auctions',
        desc: "3270 Lots - Ends 7/7/2024"
    }
];

const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const previousSlide = () => {
        const newIndex = currentSlide === 0 ? slideData.length - 1 : currentSlide - 1;
        setCurrentSlide(newIndex);
    };

    const nextSlide = () => {
        const newIndex = currentSlide === slideData.length - 1 ? 0 : currentSlide + 1;
        setCurrentSlide(newIndex);
    };

    return (
        <div className="slider-container-slider">
            <div className="slider-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {slideData.map((slide, index) => (
                    <div key={index} className="slider-card slider-card-mobile">
                        <div className='slider-image-container slider-image-container-mobile'>
                            <img src={slide.image} alt={slide.text} className="slider-image" />
                        </div>
                        <div className='slider-text-container slider-text-container-mobile'>
                            <h3 className='slider-text slider-text-mobile'>{slide.text}</h3>
                            <p className='slider-heading'>{slide.heading}</p>
                            <p className='slider-desc'>{slide.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="slider-controls-slider">
                <button className="slider-control prev " onClick={previousSlide} ><i className="fa-solid fa-arrow-left" style={{color:"#2695FF"}}></i></button>
                <button className="slider-control next " onClick={nextSlide} ><i className="fa-solid fa-arrow-right" style={{color:"#2695FF"}}></i></button>
            </div>
        </div>
    );
};

export default Slider;





