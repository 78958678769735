import React, { useEffect, useState } from 'react';
import MapEvents from '../Map/MapEvents';
import "./Category.css"
import AuctionsEvent from '../Auctions/AuctionsEvent';
import Lots from '../Lots/Lots';
import { useLocation } from 'react-router-dom';

const Category = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('auctions');
    const [headerText, setHeaderText] = useState('LIVE AND ONLINE AUCTIONS ON Auctionslive.COM');
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('hash_id')) {
            setActiveTab('lots');
        }
    }, [location]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        if (tab === 'map') {
            setHeaderText('LIVE AND ONLINE AUCTIONS NEAR ME');
        } else if (tab === 'lots') {
            setHeaderText('LIVE AND ONLINE AUCTIONS ON Auctionslive.COM');
        } else if (tab === 'auctions') {
            setHeaderText('LIVE AND ONLINE AUCTIONS ON Auctionslive.COM');
        }
    };

    return (
        <div className='category'>
            <div className='state-auction'>
                <div className='container-fluid'>
                    <div className='row auction-home'>
                        <p className='text-start pt-2 px-2'>
                            HOME
                            <span> &gt; </span>
                            <span>Lots</span>
                        </p>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className='row mt-3'>
                        <div className='col-md-6'>
                            <div className=''>
                                <h5 className='state-online-auction'>
                                    <b>{headerText}</b>
                                </h5>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='d-flex btn-toogle'>
                                <div className='d-flex' style={{
                                    background: " #f1e7e2",
                                    padding: "9px"
                                }}>
                                    <div className='auctions-btnss'>
                                        <button
                                            className={`toggle-btn ${activeTab === 'auctions' ? 'active' : ''}`}
                                            onClick={() => handleTabChange('auctions')}
                                        >
                                            Auctions
                                        </button>
                                    </div>
                                    <div className='map-btns'>
                                        <button
                                            className={`toggle-btn ${activeTab === 'map' ? 'active' : ''}`}
                                            onClick={() => handleTabChange('map')}
                                        >
                                            Map
                                        </button>
                                    </div>
                                    <div className='lots-btns'>
                                        <button
                                            className={`toggle-btn ${activeTab === 'lots' ? 'active' : ''}`}
                                            onClick={() => handleTabChange('lots')}
                                        >
                                            Lots
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {activeTab === 'auctions' && (
                    <div className='container-fluid'>
                        <div className='row'>
                            <AuctionsEvent />
                        </div>
                    </div>
                )}
                {activeTab === 'map' && (
                    <div className='container-fluid'>
                        <div className='row mt-3'>
                            <MapEvents />
                        </div>
                    </div>
                )}
                {activeTab === 'lots' && (
                    <div className='container-fluid'>
                        <div className='row'>
                            <Lots />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Category;
