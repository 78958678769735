import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

const SubmitCancelButton = ({ handleSubmit, handleCancel }) => {
    return (
        <>
            <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                color="primary"
                sx={{ mt: 2, mr: 2 }}
                style={{ color: "#fff" }}
            >
                Submit
            </Button>
            <Button
                variant="contained"
                onClick={handleCancel}
                sx={{ mt: 2 }}
                style={{ color: "#fff" }}
            >
                Cancel
            </Button>
        </>
    );
};

SubmitCancelButton.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
};

export default SubmitCancelButton;
