import React from 'react';
import './Footer.css';
import ImgFooter from "../../assets/images/footer-img.png";
import Calling from "../../assets/images/call.png";
import Message from "../../assets/images/message.png";
import Send from "../../assets/images/send.png";
import Clock from "../../assets/images/clock.png";
import Facebook from "../../assets/images/facebook.png";
import Twitter from "../../assets/images/twitter.png";
import Instagram from "../../assets/images/instagram.png";
import Linkedin from "../../assets/images/linkedin.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEnvelope, faLocationArrow, faPhone } from '@fortawesome/free-solid-svg-icons';
const Footer = () => {
    return (
        <div className='container-fluid footer'>
            {/* <div className='row'>
                <div className='col-md-3 hire '>
                    <div className='auctioned listed-data value'>
                        <p className='number lots'> 189,806,282</p>
                        <p className='auctioned-lots'>Lots Auctioned</p>
                    </div>
                </div>
                <div className='col-md-3 hire'>
                    <div className='auctioned listed value'>
                        <p className=' number lots '>$30,101,326,835</p>
                        <p className='auctioned-lots'>Total Hamer Value</p>
                    </div>
                </div>
                <div className='col-md-3 hire'>
                    <div className='auctioned listed value'>
                        <p className='number lots '>1,091,608</p>
                        <p className='auctioned-lots'>Lots Currently Listed</p>
                    </div>
                </div>
                <div className='col-md-3 hire'>
                    <div className='textttt value'>
                        <p className='last-number  number  lots'>3,424</p>
                        <p className='auctioned-lots'>Number of Auction Listed</p>
                    </div>
                </div>
            </div> */}
            <div className='row footer-services'>
                <div className='col-md-4'>
                    <div className=''>
                        <div className='footer-img footer-img-mobile text-center pt-5 flex items-center justify-center'>
                            <img src={ImgFooter} alt='footer-img' width={100}  />
                        </div>
                        <div className='reasons'>
                            {/* <p>There are many reasons to hire a professional to paint your home or  </p> */}
                        </div>
                    </div>
                </div>
                {/* <div className='col-md-3'>
                    <div className='services'>
                        <p>About Auctions</p>
                        <ul className='links'>
                            <li className='useful-list'><Link to="/info/about-us" className="link">About Us</Link></li>
                            <li className='useful-list'><Link to="/info/contact-us" className="link">Contact Us</Link></li>
                            <li className='useful-list'><Link to="/info/careers" className="link">Privacy & Policy</Link></li>
                            <li className='useful-list'><Link to="/info/help" className="link">Terms & Conditions</Link></li>
                            <li className='useful-list'><Link to="/info/faq" className="link">Faq</Link></li>
                        </ul>
                    </div>
                </div> */}
                <div className='col-md-4 footer-links-services'>
                    <div className='services '>
                        <p>Our Services</p>
                        <ul className='links'>
                            <li className='useful-list'><Link to="/info/about-us" className="link">About Us</Link></li>
                            <li className='useful-list'><Link to="/info/contact-us" className="link">Contact Us</Link></li>
                            <li className='useful-list'><Link to="/info/careers" className="link">Privacy & Policy</Link></li>
                            <li className='useful-list'><Link to="/info/help" className="link">Terms & Conditions</Link></li>
                            <li className='useful-list'><Link to="#" className="link">Blogs</Link></li>
                            <li className='useful-list'><Link to="/info/faq" className="link ">FAQ's</Link></li>
                        </ul>
                    </div>
                </div>



                <div className='col-md-4 footer-contact-mobile px-3'>
                    <div className='d-flex contact-btns-footer-mobile'>
                        <div className='calling-img'>
                        <FontAwesomeIcon icon={faPhone} className='footer-icons call-icon' />
                        </div>
                        <div className=' text-start contact-box-text-mobile'>
                            <span style={{ color: "#fff" }}>+1-234-456-12352</span>
                        </div>
                    </div>

                    <div className='d-flex pt-3 contact-btns-footer-mobile'>
                        <div className='message-img footer-icon-box'>
                            {/* <img src={Message} alt='message' /> */}
                            <FontAwesomeIcon icon={faEnvelope} className='footer-icons ' />
                        </div>
                        <div className='px-2 footer-email text-start contact-box-text-mobile'>
                            <span >Email: info@ilobid.com</span>
                        </div>
                    </div>


                    <div className='d-flex pt-3 contact-btns-footer-mobile'>
                        <div className='send-img footer-icon-box'>
                            {/* <img src={Send} alt='send' /> */}
                            <FontAwesomeIcon icon={faLocationArrow} className='footer-icons' />
                        </div>
                        <div className='px-2 printing footer-icon-box text-start contact-box-text-mobile'>
                            <span >Lorem Ipsum is simply dummy text of the printing and typesetting</span>
                        </div>
                    </div>

                    <div className='d-flex pt-3 contact-btns-footer-mobile'>
                        <div className='clock-img footer-icon-box'>
                        <FontAwesomeIcon icon={faClock} className='footer-icons'/>
                        </div>
                        <div className='px-2 timing footer-icon-box text-start contact-box-text-mobile'>
                            <span >Monday - Saturday: 8:00 AM - 6:00 PM <br />Appointment only on Sunday</span>
                        </div>
                    </div>


                </div>

                <hr className='hr-line-footer' />

                <div className='row'>
                    <div className='col-md-6'>
                        <p className='text-left reserved' s>iLobin © 2024 | All Rights Reserved</p>
                    </div>
                    <div className='col-md-6'>
                        <div className='footer-icon'>
                            <ul className='social-icons'>
                                <li><img src={Linkedin} alt='LinkedIn' width={30} height={30} /></li>
                                <li><img src={Twitter} alt='Twitter' width={30} height={30} /></li>
                                <li><img src={Facebook} alt='Facebook' width={30} height={30} /></li>
                                <li><img src={Instagram} alt='Instagram' width={30} height={30} /></li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div >
        </div >
    );
}

export default Footer;
