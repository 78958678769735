import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../WatchLists/WatchList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPrint } from '@fortawesome/free-solid-svg-icons';
import Sidebar from './Sidebar/Sidebar';
import CurrentBids from '../WatchLists/CurrentBids';
import WatchListComponent from '../WatchLists/WatchListComponent';
import TopPicks from '../WatchLists/TopPicks';
import MyAuctions from '../WatchLists/MyAuctions';
import { toast } from 'react-toastify';

const WatchList = () => {
    const [selectedButton, setSelectedButton] = useState('Current Bids');
    const [currentBidsData, setCurrentBidsData] = useState(null);
    const [watchListData, setWatchListData] = useState(null);
    const [topPicksData, setTopPicksData] = useState(null);
    const [myAuctionsData, setMyAuctionsData] = useState(null);
    const [pastBidsData, setPastBidsData] = useState(null);
    const [pastWatchListData, setPastWatchListData] = useState(null);

    const buttons = [
        'Current Bids',
        'Watch List',
        'Top Picks',
        'My Auctions',
        'Past Bids',
        'Past Watch List'
    ];

    const getHeaders = () => {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            toast.error('Access token not found.');
            return null;
        }
        return {
            Authorization: `Bearer ${accessToken}`,
            'ngrok-skip-browser-warning': '69420',
        };
    };

    const fetchData = async (url, setState) => {
        const headers = getHeaders();
        if (!headers) return;

        try {
            const response = await axios.get(url, { headers });
            setState(response?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to fetch data.');
        }
    };

    useEffect(() => {
        let apiUrl = '';
        let setData = null;
        switch (selectedButton) {
            case 'Current Bids':
                apiUrl = `${process.env.REACT_APP_API_URL}/bidding/current-bids`;
                setData = setCurrentBidsData;
                break;
            case 'Past Bids':
                apiUrl = `${process.env.REACT_APP_API_URL}/bidding/current-bids?past-bids`;
                setData = setPastBidsData;
                break;
            case 'Watch List':
                apiUrl = `${process.env.REACT_APP_API_URL}/watchlist`;
                setData = setWatchListData;
                break;
            case 'Past Watch List':
                apiUrl = `${process.env.REACT_APP_API_URL}/watchlist?type=past-watch-list`;
                setData = setPastWatchListData;
                break;
            case 'My Auctions':
                apiUrl = `${process.env.REACT_APP_API_URL}/auctions/myauctions`;
                setData = setMyAuctionsData;
                break;
            case 'Top Picks':
                apiUrl = `${process.env.REACT_APP_API_URL}/auctions/status/top-pickups`;
                setData = setTopPicksData;
                break;
            default:
                break;
        }

        if (apiUrl && setData) {
            fetchData(apiUrl, setData);
        }
    }, [selectedButton]);

    const renderComponent = () => {
        switch (selectedButton) {
            case 'Current Bids':
                return <CurrentBids data={currentBidsData} />;
            case 'Watch List':
                return <WatchListComponent data={watchListData} />;
            case 'Top Picks':
                return <TopPicks data={topPicksData} />;
            case 'My Auctions':
                return <MyAuctions data={myAuctionsData} />;
            case 'Past Bids':
                return <CurrentBids data={pastBidsData} />;
            case 'Past Watch List':
                return <WatchListComponent data={pastWatchListData} />;
            default:
                return null;
        }
    };
    return (
        <div>
            <div className="watchlist-container">
                <div className="header">
                    Home &nbsp;
                    <FontAwesomeIcon icon={faArrowRight} className="home-arrow" /> &nbsp;
                    <span className="selected-button">{selectedButton}</span>
                </div>
                <div className="button-container pt-4">
                    {buttons.map((button) => (
                        <button
                            key={button}
                            className={`button ${selectedButton === button ? 'active' : ''}`}
                            onClick={() => setSelectedButton(button)}
                        >
                            {button}
                        </button>
                    ))}
                </div>
                <div className="info-box">
                    <div className="info-item">
                        <p>Winning "High Bid" Total: $0.00</p>
                    </div>
                    <div className="info-item">
                        <p>Winning "Your Max Bid" Total: $0.00</p>
                    </div>
                    <div className="button-group mobile-button-group-watchlist">
                        <button className="colored-button green">
                            0
                            <span className="tooltip">Winning Bids</span>
                        </button>
                        <button className="colored-button brown">
                            0
                            <span className="tooltip">Sealed and Pending Bids</span>
                        </button>
                        <button className="colored-button red">
                            0
                            <span className="tooltip">Losing and Declined Bids</span>
                        </button>
                        <button className="colored-button blue">
                            0
                            <span className="tooltip">Watched Lots</span>
                        </button>
                        <button className="colored-button print">
                            <FontAwesomeIcon icon={faPrint} />
                            <span className="tooltip">Print</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className='container-fluid p-0'>
                <div className='row p-0 m-0'>
                    {/* <div className='col-md-3'>
                        <Sidebar />
                    </div> */}
                    <div className='col-md-12'>
                        {renderComponent()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WatchList;
