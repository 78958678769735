import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./LotsDetails.css";
import JifImg from "../../../assets/images/Woodburn1.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Magnifier from "react-magnifier";
import LotModal from "../Category/Lots/LotModal";
import moment from "moment";
import { RingLoader } from "react-spinners";

const LotsDetails = () => {
  const [lotData, setLotData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSection, setOpenSection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const { hash_id } = useParams();

  useEffect(() => {
    const fetchLotData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lots/${hash_id}` 
        );
        setLotData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchLotData();
  }, [hash_id]);

  if (loading) return <div className="w-full flex items-center justify-center py-3">    <RingLoader color="#36d7b7" size={100} /></div>;
  if (error) return <div>Error: {error.message}</div>;

  const defaultImage = JifImg;

  const sliderSettings1 = {
    asNavFor: nav2,
    ref: (slider) => setNav1(slider),
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const sliderSettings2 = {
    asNavFor: nav1,
    ref: (slider) => setNav2(slider),
    slidesToShow:
      lotData?.lots?.images.length > 4 ? 4 : lotData?.lots?.images.length,
    swipeToSlide: true,
    focusOnSelect: true,
    centerMode: true,
    infinite: true,
  };

  const {
    auction,
    images = [],
    bids = [],
    bidding_notice,
    highest_bidder,
    shipping_available,
    address,
  } = lotData?.lots || {};

  const handleToggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const winningBidAmount = Array.isArray(bids)
    ? [...bids].reverse().find((bid) => bid.status === "Winning")?.amount ||
      "No winning bid"
    : "No winning bid";

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const endDate = moment(auction?.end_date);
  const isClosed = endDate.isBefore(moment());
  const timeLeft = endDate.diff(moment(), 'days');
  return (
    <div className="lots-details pt-3">
      <div className="container">
        <div className="row my-4">
          <div className="col-md-6">
            <div className="slider-container w-full ">
              {images.length > 0 ? (
                <div className="w-full object-cover">
                  <div className="lotsDetailsSlider h-[300px]">
                    <Slider {...sliderSettings1}>
                      {images.map((image, index) => (
                        <div key={index} className="slider-image-wrapper flex items-center justify-center">
                          <Magnifier
                            src={
                              `${process.env.REACT_APP_BASE_IMAGE_URL}${image.image_path}` ||
                              defaultImage
                            }
                            alt={`Lot Image ${index}`}
                            className="flex items-center justify-center"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  {/* Thumbnail Slider */}
                  <div className="lotsDetailsSliderThumbnail h-[120px]">
                    <Slider {...sliderSettings2} className="thumbnail-slider">
                      {images.map((image, index) => (
                        <img
                          key={index}
                          className="thumbnail-img "
                          src={
                            `${process.env.REACT_APP_BASE_IMAGE_URL}${image.image_path}` ||
                            defaultImage
                          }
                          alt={`Thumbnail ${index}`}
                        />
                      ))}
                    </Slider>
                  </div>
                </div>
              ) : (
                <img
                  src={defaultImage}
                  alt="Default Lot"
                  className="img-fluid"
                />
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="flex items-start justify-start gap-3 flex-col py-3">
              <div className="flex items-start gap-5 justify-start w-full">
              <div>
              <p>
                <span className="font-bold">Total Bids :</span>{" "}
                <span className="font-semibold">
                  {bids.length > 0 ? bids.length : 0}
                </span>
              </p>
              <p>
                <span className="font-bold">Time Remaining :</span>{" "}
                <span className="font-semibold">
                  {(() => {
                    const timeLeft = new Date(auction?.end_date) - new Date();
                    const daysLeft = Math.floor(
                      timeLeft / (1000 * 60 * 60 * 24)
                    );
                    return daysLeft > 0 ? `${daysLeft} days left` : "Closed";
                  })()}
                </span>
              </p>

              <p>
                <span className="font-bold">Shipping Available :</span>{" "}
                <span className="font-semibold">
                  {shipping_available ? "Yes" : "No"}
                </span>
              </p>
              <p>
                <span className="font-bold">Address :</span>{" "}
                <span className="font-semibold">{auction?.location}</span>
              </p>
              </div>
              <div>
              <p>
                <span className="font-bold">AUCTIONEER INFORMATION :</span>
              </p>
              <p>
                <span className="font-semibold">Name :</span> {auction?.name}
              </p>
              <p>
                <span className="font-semibold">Auctioneer :</span>{" "}
                {auction?.auctioneer}
              </p>
              </div>
              </div>
              {isClosed ? (
                <button className="btn btn-secondary w-full" disabled>
                  Closed
                </button>
              ) : (
                <button className="btn btn-primary w-full" onClick={handleOpenModal}>
                  Bid <span>{winningBidAmount}</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-md-12">
            <div className="collapse-section">
              <button
                className="collapse-button"
                onClick={() => handleToggleSection("information")}
              >
                <span>Information</span>
                <span>
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </button>

              {openSection === "information" && (
                <div className="px-4 py-1">
                  <p>Lot Number: {lotData?.lots?.lot_number}</p>
                  <p>Group - Lead</p>
                  <p>Description: {lotData?.lots?.description}</p>
                </div>
              )}
            </div>
            <div className="collapse-section">
              <button
                className="collapse-button"
                onClick={() => handleToggleSection("auction-info")}
              >
                <span>Auction Information</span>
                <span>
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </button>
              {openSection === "auction-info" && (
                <div className="px-4 py-1">
                  <p>Name: {auction?.name}</p>
                  <p>Auctioneer: {auction?.auctioneer}</p>
                  <p>Type: {auction?.bidding_type_id?.name}</p>
                  <p>
                    Date(s): {auction?.start_date} to {auction?.end_date}
                  </p>
                  <p>Location: {auction?.location}</p>
                  <p>Buyer Premium: {auction?.buyer_premium}%</p>
                  <p>Description: {auction?.description}</p>
                </div>
              )}
            </div>
            <div className="collapse-section">
              <button
                className="collapse-button"
                onClick={() => handleToggleSection("terms-conditions")}
              >
                <span>Terms and Conditions</span>
                <span>
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </button>
              {openSection === "terms-conditions" && (
                <div className="px-4 py-1">
                  <p>{auction?.terms_and_conditions}</p>
                </div>
              )}
            </div>
            <div className="collapse-section">
              <button
                className="collapse-button"
                onClick={() => handleToggleSection("bid-increments")}
              >
                <span>Bid Increments</span>
                <span>
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </button>
              {openSection === "bid-increments" && (
                <div className="px-4 py-1">
                  {auction?.bid_increments.map((increment, index) => (
                    <div key={index}>
                      <p>Min Amount: {increment.min_amount}</p>
                      <p>Max Amount: {increment.max_amount}</p>
                      <p>Increment Amount: {increment.increment_amount}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="collapse-section">
              <button
                className="collapse-button"
                onClick={() => handleToggleSection("payment-info")}
              >
                <span>Payment Information</span>
                <span>
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </button>
              {openSection === "payment-info" && (
                <div className="px-4 py-1">
                  <p>Currency: {auction?.currency || "USD"}</p>
                  <p>Buyer Premium: {auction?.buyer_premium}%</p>
                </div>
              )}
            </div>
            <div className="collapse-section">
              <button
                className="collapse-button"
                onClick={() => handleToggleSection("shipping")}
              >
                <span>Shipping / Pick Up</span>
                <span>
                  <i class="fa-solid fa-angle-down"></i>
                </span>
              </button>
              {openSection === "shipping" && (
                <div className="px-4 py-1">
                  <p>
                    Shipping Details:{" "}
                    {auction?.shipping_option?.pickup_info || "No information"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <LotModal
          isOpen={isModalOpen}
          lot={lotData?.lots}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default LotsDetails;
