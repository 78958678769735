import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SetUpEmail.css';

const SetUpEmail = () => {
    const formik = useFormik({
        initialValues: {
            name: '',
            subject: '',
            body: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            subject: Yup.string().required('Subject is required'),
            body: Yup.string().required('Body is required'),
        }),
        onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
            const accessToken = localStorage.getItem("access_token");
            if (!accessToken) {
                setErrors({ submit: "Access token not found." });
                return;
            }
            const headers = {
                Authorization: `Bearer ${accessToken}`,
                "ngrok-skip-browser-warning": "69420",
            };
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/admin/emails/create`,
                    values,
                    { headers }
                );
                toast.success(response.data.message || "Email template created successfully.");
                resetForm();
            } catch (error) {
                toast.error(error.response ? error.response.data.message : error.message);
            } finally {
                setSubmitting(false);
            }
        },
    });

    const handleQuillChange = (value) => {
        formik.setFieldValue('body', value);
    };

    return (
        <div className='container pt-4'>
            <ToastContainer />
            <h2 className='mb-4'>Set Up Email</h2>
            <form onSubmit={formik.handleSubmit} className='email-form'>
                <div className='form-group mb-3'>
                    <label htmlFor='name' className='form-label-admin'>Name :</label>
                    <input
                        type='text'
                        id='name'
                        name='name'
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`}
                        placeholder='Enter your name'
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className='invalid-feedback'>{formik.errors.name}</div>
                    ) : null}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor='subject' className='form-label'>Subject</label>
                    <input
                        type='text'
                        id='subject'
                        name='subject'
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.touched.subject && formik.errors.subject ? 'is-invalid' : ''}`}
                        placeholder='Enter subject'
                    />
                    {formik.touched.subject && formik.errors.subject ? (
                        <div className='invalid-feedback'>{formik.errors.subject}</div>
                    ) : null}
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor='body' className='form-label'>Body</label>
                    <ReactQuill
                        id='body'
                        value={formik.values.body}
                        onChange={handleQuillChange}
                        className={`quill-editor ${formik.touched.body && formik.errors.body ? 'is-invalid' : ''}`}
                        placeholder='Enter the body of the email'
                    />
                    {formik.touched.body && formik.errors.body ? (
                        <div className='invalid-feedback body-error'>{formik.errors.body}</div>
                    ) : null}
                </div>
                {formik.errors.submit && (
                    <div className='alert alert-danger' role='alert'>
                        {formik.errors.submit}
                    </div>
                )}
                <div className='pt-4'>
                    <button type='submit' className='btn end-email-admin btn-primary' disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? 'Sending...' : 'Send Email'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SetUpEmail;
