import React from 'react'
import "./Header.css"
import MainHeader from './MainHeader'
import Header2 from './Header2'
import Header3 from './Header3'

const Header = () => {
    return ( 
        <div className='header'>
            <MainHeader />
             <Header2 />
            <Header3 />
        </div>
    )
}

export default Header