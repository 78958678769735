import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './UserDetails.css';

const UserDetails = () => {
    const { hash_id } = useParams();
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const accessToken = localStorage.getItem('access_token');
                if (!accessToken) {
                    console.error('Access token not found in localStorage.');
                    navigate('/authentication/sign-in');
                    return;
                }
                const headers = {
                    Authorization: `Bearer ${accessToken}`,
                    'ngrok-skip-browser-warning': '69420',
                };
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users/${hash_id}`, { headers });
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching user data:', error);
                navigate('/super/admin/dashboard');
            }
        };
        fetchUser();
    }, [hash_id, navigate]);

    if (!user) return <div className="loading">Loading...</div>;

    return (
        <div className='container m-0 p-0'>
            <div className="user-details-container">
                <div className="user-details-header relative flex items-center justify-between">
                    <button className="btn btn-primary " onClick={() => navigate('/super/admin/dashboard')}>Back</button>
                    <h1 className='pe-3 font-bold'>User Details</h1>
                </div>
                <div className="user-details-content">
                    <div className="user-details-section">
                        <div className="user-details-card">
                        <h2 className="w-full "><span className='underline' >Basic Information</span> :-</h2>
                            <p className=' p-0 m-0'><strong>Role: </strong> 
                            {user.roles && user.roles.length > 0 ? (
                                <span className="">
                                    {user.roles.map(role => (
                                        <span key={role.id} className="role-item">{role.name}</span>
                                    ))}
                                </span>
                            ) : (
                                <span>No roles assigned.</span>
                            )}</p>
                            <p><strong>First Name:</strong> {user.first_name}</p>
                            <p><strong>Last Name:</strong> {user.last_name}</p>
                            <p><strong>Username:</strong> {user.username}</p>
                            <p><strong>Email:</strong> {user.email}</p>
                        </div>
                    </div>
                    <div className="user-details-section">
                        <div className="user-details-card">
                        <h2 className="w-full "><span className='underline'>Address</span> :-</h2>
                            <p><strong>Address:</strong> {user.detail.address || ''}</p>
                            <p><strong>City:</strong> {user.detail.city || ''}</p>
                            <p><strong>State:</strong> {user.state?.name || ''}</p>
                            <p><strong>Country:</strong> {user.country?.name || ''}</p>
                            <p><strong>Postal Code:</strong> {user.detail.postal_code || ''}</p>
                        </div>
                    </div>
                    <div className="user-details-section">
                        <div className="user-details-card">
                        <h2 className="w-full "><span className='underline'>Contact Information</span>:-</h2>
                            <p><strong>Phone 1:</strong> {user.detail.phone1 || ''} | {user.detail.phone2 || ''}</p>
                           
                            <p><strong>Fax:</strong> {user.detail.fax || ''}</p>
                        </div>
                    </div>
                    <div className="user-details-section">
                        <div className="user-details-card">
                        <h2 className="w-full "><span className='underline'>Settings</span> :-</h2>
                            <p><strong>Hide Username:</strong> {user.settings.hide_username ? 'Yes' : 'No'}</p>
                            <p><strong>Allow Magnifying Glass:</strong> {user.settings.allow_magnifying_glass ? 'Yes' : 'No'}</p>
                            <p><strong>Send Email Notifications:</strong> {user.settings.send_email_notifications ? 'Yes' : 'No'}</p>
                            <p><strong>Newsletter:</strong> {user.settings.newsletter ? 'Yes' : 'No'}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    );
};

export default UserDetails;
