// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button, Table, Pagination } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './Dashboard.css';
// import { toast } from 'react-toastify';
// import UserEditModal from '../EditUserDetail/UserEditModal';

// const Dashboard = () => {
//     const [users, setUsers] = useState([]);
//     const [showEditModal, setShowEditModal] = useState(false);
//     const [currentUser, setCurrentUser] = useState(null);
//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 5;

//     useEffect(() => {
//         fetchUsers();
//     }, []);

//     const fetchUsers = async () => {
//         const accessToken = localStorage.getItem('access_token');
//         if (!accessToken) {
//             console.error('Access token not found in localStorage.');
//             return;
//         }
//         const headers = {
//             Authorization: `Bearer ${accessToken}`,
//             'ngrok-skip-browser-warning': '69420',
//         };
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, { headers });
//             if (Array.isArray(response.data)) {
//                 setUsers(response.data);
//             } else {
//                 console.error('Expected array, but got:', response.data);
//                 setUsers([]);
//             }
//         } catch (error) {
//             console.error('Error fetching user data:', error);
//             setUsers([]);
//         }
//     };
//     const handleDeleteClick = async (userId) => {
//         const accessToken = localStorage.getItem('access_token');
//         if (!accessToken) {
//             console.error('Access token not found in localStorage.');
//             toast.error('Access token not found.');
//             return;
//         }
//         const headers = {
//             Authorization: `Bearer ${accessToken}`,
//             'ngrok-skip-browser-warning': '69420',
//         };
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users/delete/${userId}?action=delete`, { headers });
//             if (response.data.status === 'error') {
//                 toast.error(response.data.message || 'User deletion failed.');
//             } else {
//                 toast.success('User deleted successfully.');
//                 fetchUsers();
//             }
//         } catch (error) {
//             console.error('Error deleting user:', error);
//             toast.error('An unexpected error occurred while deleting the user.');
//         }
//     };
//     const handleEditClick = (user) => {
//         setCurrentUser(user);
//         setShowEditModal(true);
//     };
//     const handleUpdate = () => {
//         fetchUsers();
//     };
//     const handlePageChange = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };
//     const totalPages = Math.ceil(users.length / itemsPerPage);
//     const displayedUsers = users.slice((currentPage - 1)  itemsPerPage, currentPage  itemsPerPage);

//     return (
//         <div className="container m-0 mt-4">
//             {users.length === 0 ? (
//                 <div className="no-data-found">
//                     <h2>No Data Found</h2>
//                     <p>No User Found</p>
//                 </div>
//             ) : (
//                 <>
//                     <Table striped bordered hover className="mt-5 mb-5">
//                         <thead>
//                             <tr>
//                                 <th>Username</th>
//                                 <th>Email</th>
//                                 <th>Roles</th>
//                                 <th>Status</th>
//                                 <th>State</th>
//                                 <th>Country</th>
//                                 <th>City</th>
//                                 <th>Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {displayedUsers.map(user => (
//                                 <tr key={user.hash_id}>
//                                     <td>
//                                         <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">
//                                             {user.username}
//                                         </Link>
//                                     </td>
//                                     <td>
//                                         <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user.email}</Link>
//                                     </td>
//                                     <td>
//                                         <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user.roles && user.roles.length > 0 ? user.roles[0].name : ''}</Link>
//                                     </td>
//                                     <td>
//                                         <Link
//                                             to={`/admin/user-detail/${user.hash_id}`}
//                                             className={`text-decoration-none ${user.is_active === 0 ? 'text-danger' : 'text-success '}`}
//                                         >
//                                             {user.is_active === 0 ? 'Pending' : 'Active'}
//                                         </Link>
//                                     </td>
//                                     <td>
//                                         <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user?.state?.name}</Link>
//                                     </td>
//                                     <td>
//                                         <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user?.country?.name}</Link>
//                                     </td>
//                                     <td>
//                                         <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user?.detail?.city}</Link>
//                                     </td>
//                                     <td>
//                                         <Button
//                                             variant="warning"
//                                             onClick={() => handleEditClick(user)}
//                                         >
//                                             Edit
//                                         </Button>
//                                         <Button
//                                             variant="danger"
//                                             className="ms-2"
//                                             onClick={() => handleDeleteClick(user.hash_id)}
//                                         >
//                                             Delete
//                                         </Button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </Table>
//                     <Pagination>
//                         <Pagination.Prev
//                             onClick={() => handlePageChange(currentPage - 1)}
//                             disabled={currentPage === 1}
//                         />
//                         {Array.from({ length: totalPages }, (_, i) => (
//                             <Pagination.Item
//                                 key={i + 1}
//                                 active={i + 1 === currentPage}
//                                 onClick={() => handlePageChange(i + 1)}
//                             >
//                                 {i + 1}
//                             </Pagination.Item>
//                         ))}
//                         <Pagination.Next
//                             onClick={() => handlePageChange(currentPage + 1)}
//                             disabled={currentPage === totalPages}
//                         />
//                     </Pagination>
//                     <UserEditModal
//                         show={showEditModal}
//                         onHide={() => setShowEditModal(false)}
//                         user={currentUser}
//                         onUpdate={handleUpdate}
//                     />
//                 </>
//             )}
//         </div>
//     );
// };

// export default Dashboard;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';
import { toast } from 'react-toastify';
import UserEditModal from '../EditUserDetail/UserEditModal';

const Dashboard = () => {
    const [users, setUsers] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true); // Added loading state
    const itemsPerPage = 20;

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        setLoading(true); // Start loading
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            return;
        }
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'ngrok-skip-browser-warning': '69420',
        };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, { headers });
            if (Array.isArray(response.data)) {
                setUsers(response.data);
            } else {
                console.error('Expected array, but got:', response.data);
                setUsers([]);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUsers([]);
        } finally {
            setLoading(false); // End loading
        }
    };

    const handleDeleteClick = async (userId) => {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            toast.error('Access token not found.');
            return;
        }
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'ngrok-skip-browser-warning': '69420',
        };
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/users/delete/${userId}?action=delete`, { headers });
            if (response.data.status === 'error') {
                toast.error(response.data.message || 'User deletion failed.');
            } else {
                toast.success('User deleted successfully.');
                fetchUsers();
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('An unexpected error occurred while deleting the user.');
        }
    };

    const handleEditClick = (user) => {
        setCurrentUser(user);
        setShowEditModal(true);
    };

    const handleUpdate = () => {
        fetchUsers();
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(users.length / itemsPerPage);
    const displayedUsers = users.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className="container m-0 mt-4">
            {loading ? (
                <div className="loader-container flex items-center justify-center my-[100px]">
                    <RingLoader color="#36d7b7" size={100} />
                </div>
            ) : users.length === 0 ? (
                <div className="no-data-found">
                    <h2>No Data Found</h2>
                    <p>No User Found</p>
                </div>
            ) : (
                <>
                <h2>Country List</h2>
                    <Table  className="mt-5 mb-5">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Roles</th>
                                <th>Status</th>
                                <th>State</th>
                                <th>Country</th>
                                <th>City</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedUsers.map(user => (
                                <tr key={user.hash_id}>
                                    <td>
                                        <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">
                                            {user.username}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user.email}</Link>
                                    </td>
                                    <td>
                                        <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user.roles && user.roles.length > 0 ? user.roles[0].name : ''}</Link>
                                    </td>
                                    <td>
                                        <Link
                                            to={`/admin/user-detail/${user.hash_id}`}
                                            className={`text-decoration-none ${user.is_active === 0 ? 'text-danger' : 'text-success '}`}
                                        >
                                            {user.is_active === 0 ? 'Pending' : 'Active'}
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user?.state?.name}</Link>
                                    </td>
                                    <td>
                                        <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user?.country?.name}</Link>
                                    </td>
                                    <td>
                                        <Link to={`/admin/user-detail/${user.hash_id}`} className="text-decoration-none text-dark">{user?.detail?.city}</Link>
                                    </td>
                                    <td>
                                        <Button
                                            variant="warning"
                                            onClick={() => handleEditClick(user)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            variant="danger"
                                            className="ms-2"
                                            onClick={() => handleDeleteClick(user.hash_id)}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination>
                        <Pagination.Prev
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        />
                        {Array.from({ length: totalPages }, (_, i) => (
                            <Pagination.Item
                                key={i + 1}
                                active={i + 1 === currentPage}
                                onClick={() => handlePageChange(i + 1)}
                            >
                                {i + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        />
                    </Pagination>
                    <UserEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        user={currentUser}
                        onUpdate={handleUpdate}
                    />
                </>
            )}
        </div>
    );
};

export default Dashboard;
