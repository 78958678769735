import React from 'react';
import Slider from './Slider';


const Search = () => {
    return (
        <div className='search bg-transparent'>
            <div className='container-fluid'>
                <div className='row '>
                    <p className='online-auction text-center mt-5 font-black'>
                        Live & Online Auctions. Search, Join & Bid Now</p>
                </div>
                <div className='row mt-2'>
                    <Slider />
                </div>
            </div>
        </div>
    )
}

export default Search;