import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RingLoader } from 'react-spinners';
import Book8 from "../../../assets/images/Book8.png";

const Picks = () => {
    const [auctions, setAuctions] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchAuctions = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/auctions/status/top-pickups`,
                { headers: { "ngrok-skip-browser-warning": "69420" } }
            );
            setAuctions(response.data.data);

        } catch (error) {
            console.error("Error fetching auctions:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAuctions();
    }, []);

    const displayedAuctions = auctions.slice(0, 8);

    return (
        <div className='home'>
            <div className='container'>
                <div className='row'>
                    <div className='auctions mt-3 mb-3'>
                        <p className='title'>iLobid.</p>
                        <p className='subtitle'> Live & Online Auctions.</p>
                    </div>
                </div>
                <div className='row align-items-center mt-3'>
                    <div className='col-md-6 col-sm-6'>
                        <h4 className='hot-auctions mt-2'>Top Picks For You</h4>
                    </div>
                    <div className='col-md-6 col-sm-6 text-md-right d-flex justify-content-md-end mt- mt-md-0'>
                        <button className='view-all-btn'>View all</button>
                    </div>
                </div>
                <div className='row mt-5'>
                    {loading ? (
                        <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
                            <RingLoader color="#3498db" loading={loading} size={150} />
                        </div>
                    ) : (
                        displayedAuctions.length > 0 ? displayedAuctions.map((auction, index) => (
                            <div className='col-md-3 mb-4' key={index}>
                                <div className="card woodburn-card">
                                    <div className="card-body woodburn1">
                                        <div className='woodburn1-img w-full h-[150px]'>
                                            <img className='object-cover'
                                                src={
                                                    auction.images && auction.images.length > 0
                                                        ? `${process.env.REACT_APP_BASE_IMAGE_URL}${auction.images[0].image_path}`
                                                        : Book8
                                                }
                                                alt={auction.title || 'Auction Image'}
                                            />
                                        </div>
                                        <p className="card-text text-center mt-3 woodburn-text">
                                            {auction.title || 'Auction Title Not Available'}
                                        </p>
                                        <h5 className='text-center woodburn-text-heading'>Ends {auction.auction.end_date}</h5>
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <p>No auctions available.</p>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default Picks;
