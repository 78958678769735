
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
// import { RingLoader } from "react-spinners";

// const LocationSelector = (props) => {
//   const { handleCountryChange, handleCountryStateChange } = props;

//   const [countryList, setCountryList] = useState([]);
//   const [countryStateList, setCountryStateList] = useState([]);

//   const [showCountryList, setshowCountryList] = useState(false);
//   const [showCountryStates, setShowCountryStates] = useState(false);
//   const [loadingStates, setLoadingStates] = useState(false);
//   const [loadingLocations, setLoadingLocations] = useState(false);

//   const fetchLocations = async () => {
//     setLoadingLocations(true);
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/countries`,
//         {
//           headers: {
//             "ngrok-skip-browser-warning": "69420",
//           },
//         }
//       );
//       setCountryList(response.data.data);
//     } catch (error) {
//       console.error("Error fetching locations:", error);
//     } finally {
//       setLoadingLocations(false);
//     }
//   };

//   useEffect(() => {
//     if (showCountryList) {
//       fetchLocations();
//     }
//   }, [showCountryList]);

//   const handleCountrySelected = async ({ target: { value } }) => {
//     handleCountryChange(value);
//     setShowCountryStates(true);
//     setLoadingStates(true);
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/states/${value}`,
//         {
//           headers: {
//             "ngrok-skip-browser-warning": "69420",
//           }
//         }
//       );
//       setCountryStateList(response.data.data);
//     } catch (error) {
//       console.error("Error fetching states:", error);
//     } finally {
//       setLoadingStates(false);
//     }
//   };

//   const toggleCountryList = () => {
//     if (showCountryList) {
//       setshowCountryList(false);
//       setShowCountryStates(false); 
//     } else {
//       setshowCountryList(true);
//     }
//   };

//   return (
//     <>
//       <div
//         className="sidebar-item d-flex auction-states-drop"
//         onClick={toggleCountryList}
//       >
//         {loadingLocations ? (
//           <div className="sidebar-sub-item text-center">
//             <div className="d-flex justify-content-center align-items-center">
//               <RingLoader size={60} color={"#123abc"} loading={loadingLocations} />
//             </div>
//           </div>
//         ) : (
//           <>
//             LOCATION
//             <div className="mx-2">
//               <FontAwesomeIcon
//                 icon={showCountryList ? faAngleUp : faAngleDown}
//                 className="toggle-icon quick-search"
//               />
//             </div>
//           </>
//         )}
//       </div>
//       {showCountryList && !loadingLocations && (
//         <div className="sidebar-sub-item">
//           <div className="form-group">
//             <select className="form-control" onChange={handleCountrySelected}>
//               <option value="">Select Location (country)</option>
//               {countryList.map((country) => (
//                 <option key={country.hash_id} value={country.hash_id}>
//                   {country.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//       )}
//       {loadingStates && (
//         <div className="sidebar-sub-item">
//           <div className="d-flex justify-content-center align-items-center">
//             <RingLoader size={60} color={"#123abc"} loading={loadingStates} />
//           </div>
//         </div>
//       )}
//       {showCountryStates && !loadingStates && (
//         <div className="sidebar-sub-item">
//           <div className="form-group">
//             <label>Select State</label>
//             <select
//               className="form-control"
//               onChange={(e) => handleCountryStateChange(e.target.value)}
//             >
//               <option value="">Select State</option>
//               {countryStateList.map((countryState) => (
//                 <option key={countryState.hash_id} value={countryState.hash_id}>
//                   {countryState.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default LocationSelector;



import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { RingLoader } from "react-spinners";

const LocationSelector = (props) => {
  const { handleCountryChange, handleCountryStateChange } = props;

  const [countryList, setCountryList] = useState([]);
  const [countryStateList, setCountryStateList] = useState([]);

  const [showCountryList, setshowCountryList] = useState(false);
  const [showCountryStates, setShowCountryStates] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);

  const fetchLocations = async () => {
    setLoadingLocations(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/countries`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      setCountryList(response.data.data);
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setLoadingLocations(false);
    }
  };

  useEffect(() => {
    if (showCountryList) {
      fetchLocations();
    }
  }, [showCountryList]);

  const handleCountrySelected = async ({ target: { value } }) => {
    handleCountryChange(value);
    setShowCountryStates(true);
    setLoadingStates(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/states/${value}`,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          }
        }
      );
      setCountryStateList(response.data.data);
    } catch (error) {
      console.error("Error fetching states:", error);
    } finally {
      setLoadingStates(false);
    }
  };

  const toggleCountryList = () => {
    if (showCountryList) {
      setshowCountryList(false);
      setShowCountryStates(false); 
    } else {
      setshowCountryList(true);
    }
  };

  return (
    <>
      <div
        className="sidebar-item d-flex auction-states-drop"
        onClick={toggleCountryList}
      >
        {loadingLocations ? (
          <div className="sidebar-sub-item text-center">
            <div className="d-flex justify-content-center align-items-center">
              <RingLoader size={60} color={"#123abc"} loading={loadingLocations} />
            </div>
          </div>
        ) : (
          <>
            <p className="font-bold m-0">LOCATION </p>
            <div className="mx-2">
              <FontAwesomeIcon
                icon={showCountryList ? faAngleUp : faAngleDown}
                className="toggle-icon quick-search"
              />
            </div>
          </>
        )}
      </div>
      {showCountryList && !loadingLocations && (
        <div className="sidebar-sub-item">
          <div className="form-group">
            <select className="form-control" onChange={handleCountrySelected}>
              <option value="">Select Location (country)</option>
              {countryList.map((country) => (
                <option key={country.hash_id} value={country.hash_id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {loadingStates && (
        <div className="sidebar-sub-item">
          <div className="d-flex justify-content-center align-items-center">
            <RingLoader size={60} color={"#123abc"} loading={loadingStates} />
          </div>
        </div>
      )}
      {showCountryStates && !loadingStates && (
        <div className="sidebar-sub-item">
          <div className="form-group">
            <label>Select State</label>
            <select
              className="form-control"
              onChange={(e) => handleCountryStateChange(e.target.value)}
            >
              <option value="">Select State</option>
              {countryStateList.map((countryState) => (
                <option key={countryState.hash_id} value={countryState.hash_id}>
                  {countryState.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default LocationSelector;
